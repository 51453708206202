/* eslint no-undef: 0 */
import arTranslation from "./translations/ar";
import enTranslation from "./translations/en";
import cnTranslation from "./translations/cn";
import routeService from "../services/routeService";

const translationConfig = {
  defaultLanguage: routeService.getLanguageFromRoute() || "en",
  formsConfig: { ...arTranslation, ...enTranslation, ...cnTranslation },
  i18nConfig: {
    en: { translation: enTranslation.en },
    ar: { translation: arTranslation.ar },
    cn: { translation: cnTranslation.cn },
  },
  availableLanguages: ["ar", "en", "cn"],
};

export default translationConfig;
