import { translationConfig } from '../../../i18n';

export const filtersState = {
  date: '',
  plant: '',
  line: '',
  shift: '',
  fromDate:'',
  formNameCsv:'',
  toDate:'',
  submitter:'',
  formName:'',
  approvalStatus:'',
  formShift:'',
  apprLine:'',
  searchForm:'',
  searchHome:'',
  frmDateFilter:'',
  toDateFilter:'',
};

export const languageState = {
  language: translationConfig.defaultLanguage,
  isLanguageChanged: false,
};
export const formInformationsState = {
  
  previousReading:[],
  error: null,
  dataSetList: [],
  isLoading: false,
  skuList:[],
  flavorList:[],
  sWItemDetailsList:[],
  sWTransactionsList:[],
  rateConfigList:[],
  standardTitleList:[],
  trainingDetailList:[],
  gpidDetailList: [],
  corntypeList: []
};

export const userFormsState = {
  forms: [],
  categories: [],
  approverForms: [],
  approverFormNames: [],
  superApprover:false,
  departmentApprover:false,
  isLoading: false,
  error: null,
  readOnlyForms:[],
};
export const previousReadingState = {
  previousReading:[],
};
export const offlineQueueState = {
  dequeuing: false,
};
export const approverState = {
  approverView: false,
};
