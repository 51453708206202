export const SET_FILTERS_VALUE = 'SET_FILTERS_VALUE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const REQUEST_USER_FORMS = 'REQUEST_USER_FORMS';
export const SET_USER_FORMS = 'SET_USER_FORMS';
export const USER_FORMS_ERROR = 'USER_FORMS_ERROR';

export const PREVIOUS_READING_START = 'PREVIOUS_READING_START';

export const REQUEST_FORM_INFORMATIONS = 'REQUEST_FORM_INFORMATIONS';
export const SET_FORM_INFORMATIONS = 'SET_FORM_INFORMATIONS';
export const FORM_INFORMATION_ERROR = 'FORM_INFORMATION_ERROR';

export const SUBMISSIONS_DEQUEUING_START = 'SUBMISSIONS_DEQUEUING_START';
export const SUBMISSIONS_DEQUEUING_FINISH = 'SUBMISSIONS_DEQUEUING_FINISH';

export const IS_APPROVER_FLOW = 'IS_APPROVER_FLOW';
export const IS_USER_FLOW = 'IS_USER_FLOW';
