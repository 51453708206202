import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { i18next } from '../../i18n';
import { PageTexts } from '../../config';

import { CSVLink } from 'react-csv';
export default class extends Component {
  static propTypes = {
    isPrimaryButtonDisabled: PropTypes.bool,
    currentPage: PropTypes.any,
    isDeletion: PropTypes.bool,
    isSaveAndClose: PropTypes.any,
    isCSVExport: PropTypes.bool,
    csvColumnValue:PropTypes.any,
    csvHeaderValue:PropTypes.any,
    
    csvFileName:PropTypes.string,
    isDeletionButtonPath: PropTypes.string,
    secondaryButtonPath: PropTypes.string,
    secondaryButtonText: PropTypes.string,
    nodata: PropTypes.bool,
    isSectionEmpty: PropTypes.bool,
    isPrimaryButtonLoading: PropTypes.bool,
    primaryButtonAction:PropTypes.func,
    isReject: PropTypes.bool,
    isRejectButtonPath:PropTypes.string,
    ternaryButtonText: PropTypes.string,
    ternaryButtonAction:PropTypes.func,
    primaryButtonText: PropTypes.string
  };
  constructor(props) {
    super(props);

    this.state = {
      isPrimaryButtonDisabled: this.props.isPrimaryButtonDisabled,
    };
    this.formType = '';
    this.importCVDisplay = false;
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isPrimaryButtonDisabled !== this.state.isPrimaryButtonDisabled
    ) {
      this.setState({
        isPrimaryButtonDisabled: nextProps.isPrimaryButtonDisabled,
      });
    }
    return true;
  }
  
  componentDidMount() {   
	if(this.props.fromData !== undefined && this.props.fromNames !== undefined ) 
	{
		if(this.props.fromData.form.id === this.props.fromNames.HrForms.id) {
			this.formType ='hr57'; 
			this.importCVDisplay = this.props.fromData && this.props.fromData.userForms && this.props.fromData.userForms.departmentApprover ? this.props.fromData.userForms.departmentApprover : false;
		}
		else if(this.props.fromData.form.id ===this.props.fromNames.GpidSpecificForms.id) {
			this.formType ='core8'; 
		}
		else if(this.props.fromData.form.id ===this.props.fromNames.FoodSafety.id){
			this.formType ='hg77'; 
		}
		else if(this.props.fromData.form.id ===this.props.fromNames.InternalAuditReport.id) {
			this.formType ='hg78';  
		}  
	} 
  }
  importCore8=(e,type)=> {  
	this.props.importCSVData(e,type); 
	setTimeout(() => { 
		if(document.getElementById('contained-button-file')) {
			document.getElementById('contained-button-file').value='';
		} 
	},5000);
  }
  
  
  
  render() { 
    /****Fixed applied for Task 322445*******/ 
    /*** This code will check the Current page numbe to disable back button for first Tab */
    let hideBackBtn = false;
    // if ((this.props.currentPage == "0" || this.props.currentPage === 0 ))
    /* converted input 0 to string & compared the value for console warnings */
    if (this.props.currentPage && parseInt(this.props.currentPage) === 0) {
      hideBackBtn = true;
    }
    /**** End Task 322445*******/

    return (
        <div className="bottom-control-buttons">
            {this.props.isDeletion && (
                <Link
                  className="btn btn-danger btn-delete"
                  to={ this.props.isDeletionButtonPath }
                >
                    {i18next.t(PageTexts.DELETE_BUTTON_TEXT)}
                </Link>
            )}
            {this.props.isReject && (	
            <Link	
                  className="btn btn-danger btn-delete"	
                  to={ this.props.isRejectButtonPath }	
                >	
                {i18next.t(PageTexts.REJECT_BUTTON_TEXT)}	
            </Link>	
            )}
            {!hideBackBtn ? (
                <Link className="btn pep-btn" to={ this.props.secondaryButtonPath }>
                    {i18next.t(this.props.secondaryButtonText)}
                </Link>
            ) : null}

            {this.props.isCSVExport ? 
                <CSVLink  data={ this.props.csvColumnValue } 
                 headers={ this.props.csvHeaderValue } 
               filename={ this.props.csvFileName }  className="btn pep-btn-h"
               >
                    {i18next.t(PageTexts.CSV_EXPORT_BUTTON_TEXT)}              
                </CSVLink> : null}

            { this.props.isSaveAndClose ? <button
              className="btn pep-btn-h"
              disabled={
                !!this.state.isPrimaryButtonDisabled ||
                this.props.isSectionEmpty ||
                this.props.nodata ||
                this.props.isPrimaryButtonLoading
              }
              onClick={ this.props.ternaryButtonAction }
            >
                {this.props.isPrimaryButtonLoading && <div className="pep-c-iload"></div>}

                {i18next.t(this.props.ternaryButtonText)}
            </button>:null}
			
			 

        {!this.importCVDisplay && this.props.fromData !== undefined && this.props.fromNames !== undefined &&
			( (this.props.fromData.form.id ===this.props.fromNames.HrForms.id) ||
			  (this.props.fromData.form.id ===this.props.fromNames.GpidSpecificForms.id) || 
			 (this.props.fromData.form.id ===this.props.fromNames.FoodSafety.id) || 
			 (this.props.fromData.form.id ===this.props.fromNames.InternalAuditReport.id) ) ? (
			<>
			<div>    
				<input
				accept=".csv"
				id="contained-button-file"
				type="file"
				style={ { display: 'none' } }
				onChange={ (e) => this.importCore8(e,this.formType) }
				 
				/>
				<label htmlFor="contained-button-file">
					<div className='importCSVBtn'> Import CSV  </div>
				</label> 

			</div> </>) : ('')} 
			
			      
            <button
              className="btn pep-btn-h"
              disabled={
                !!this.state.isPrimaryButtonDisabled ||
                this.props.isSectionEmpty ||
                this.props.nodata ||
                this.props.isPrimaryButtonLoading
              }
              onClick={ this.props.primaryButtonAction }
            >
                {this.props.isPrimaryButtonLoading && <div className="pep-c-iload"></div>}

                {i18next.t(this.props.primaryButtonText)}
            </button>
        </div>
    );
  }
}
