/* eslint no-undef: 0 */
import React from "react";
import { connect } from "react-redux";
import Confirm from "../../../../../containers/Confirm";
import {
  deleteSubmission,
  resetSubmissions,
  selectError,
  Errors,
  saveSubmission,
} from "react-formio";
import { reportCalculations } from "../../helper";
import PropTypes from "prop-types";
import { i18next } from "../../../../../i18n";
import { push, goBack } from "connected-react-router";
import routeService from "../../../../../services/routeService";
import { UserMessages, Forms } from "../../../../../config";
import store from "../../../../../store";

const Delete = (props) => (
  <div>
    <Errors errors={props.errors} />
    <Confirm {...props} />
  </div>
);
Delete.propTypes = {
  errors: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    message: i18next.t(UserMessages.DELETE_MESSAGE_CONFIRM, {
      id: state.submission.submission._id,
    }),
    errors: [selectError("submission", state), selectError("form", state)],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  const currentPage = routeService.getCurrentPageNumberFromLocation(location);
  const language = routeService.getLanguageFromRoute();
  const form = store.getState().form;
  const submissions = store.getState().submissions.submissions;
  const submission = store.getState().submission.submission;
  return {
    onYes: () => {
      dispatch(
        deleteSubmission(
          "submission",
          ownProps.match.params.submissionId,
          ownProps.match.params.formId,
          (err) => {
            let reportSubmission = [];
            if (submission !== undefined) {
              reportSubmission = {
                state: "submitted",
                data: {
                  date: submission.data.date,
                  shift: submission.data.shift,
                  line: submission.data.line,
                  plant: submission.data.plant,
                  formId: ownProps.match.params.formId,
                  submissionObject: JSON.stringify(submission.data),
                  submissionMode: "Deletion",
                  page:
                    submission &&
                    submission.metadata &&
                    submission.metadata.viewPage
                      ? submission.metadata.viewPage
                      : 0,
                  isOnline: navigator.onLine.toString(),
                  submissionStatus: "Success",
                  deviceBrowser: navigator.userAgent ? navigator.userAgent : "",
                  submissionId: ownProps.match.params.submissionId
                    ? ownProps.match.params.submissionId
                    : 0,
                  gpid: submission.data.gpid ? submission.data.gpid : 0,
                  errorMessage: "",
                },
              };
            }
            if (err && submission !== undefined) {
              reportSubmission.data.errorMessage = JSON.stringify(err);
              reportSubmission.state = "Not submitted";
              reportSubmission.data.submissionStatus = "Failed";
              dispatch(
                saveSubmission("submission", reportSubmission, Forms.Report.id)
              );
            }
            if (!err) {
              dispatch(resetSubmissions("submissions"));
              let shiftSubmission;
              if (
                form.form &&
                form.form.components[currentPage] &&
                form.form.components[currentPage].properties &&
                form.form.components[currentPage].properties.kpiReport
              ) {
                const tabName =
                  form.form.components[1] && form.form.components[1].title
                    ? form.form.components[1].title
                    : "";

                shiftSubmission = reportCalculations(
                  form.form.components[currentPage],
                  currentPage,
                  submissions,
                  submission,
                  "delete",
                  tabName
                );
              }

              /**Auto update shift level record for KPI reporting, in case of shift level conflict resolution code starts */
              if (
                form.form &&
                form.form.components[currentPage] &&
                form.form.components[currentPage].properties &&
                form.form.components[currentPage].properties.display &&
                form.form.components[currentPage].properties.display ===
                  "commonForShift"
              ) {
                if (form.form.components && form.form.components.length !== 0) {
                  form.form.components.forEach(function (component) {
                    if (
                      component.properties &&
                      component.properties.kpiReport
                    ) {
                      const tabName =
                        form.form.components[1] && form.form.components[1].title
                          ? form.form.components[1].title
                          : "";

                      shiftSubmission = reportCalculations(
                        form.form.components[
                          parseInt(component.properties.kpiReport)
                        ],
                        parseInt(component.properties.kpiReport),
                        submissions,
                        submission,
                        "delete",
                        tabName
                      );
                    }
                  });
                }
              }
              /**Auto update shift level record for KPI reporting, in case of shift level conflict resolution code ends */

              if (shiftSubmission !== undefined) {
                if (shiftSubmission.lastRecord) {
                  dispatch(
                    deleteSubmission(
                      "submission",
                      shiftSubmission._id,
                      ownProps.match.params.formId,
                      (err) => {
                        if (!err) {
                          dispatch(resetSubmissions("submission"));
                        }
                      }
                    )
                  );
                } else {
                  dispatch(
                    saveSubmission(
                      "submission",
                      shiftSubmission,
                      ownProps.match.params.formId,
                      (err) => {
                        if (!err) {
                          dispatch(resetSubmissions("submission"));
                        }
                      }
                    )
                  );
                }
              }

              dispatch(
                push(
                  routeService.getPagePath.submissionPage(
                    language,
                    ownProps.match.params.formId,
                    currentPage
                  )
                )
              );
            }
          }
        )
      );
    },
    onNo: () => {
      dispatch(goBack());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
