/* eslint no-useless-escape: 0 */
import { PageTexts, UserMessages } from "../../config";

export default {
  cn: {
    Submit: "提交",
    [PageTexts.DELETE_BUTTON_TEXT]: "删除",
    [PageTexts.SUBMIT_FOR_APPROVAL]: "提交并结束班次",
    [PageTexts.SUBMIT_AND_END_SHIFT]: "提交并结束班次",
    [UserMessages.NO_RECORDS]: "该班次无记录存在",
    [UserMessages.NO_RECORDS_DAY]: "改日无记录存在",
    [UserMessages.RECORDS_NOT_ADDED]:
      "该班次无添加记录。请输入记录并结束班次。",
    [UserMessages.SHIFT_ENDED_TEXT]: "该班次已结束",
    [UserMessages.SHIFT_APPROVED]: "该班次已结束并已批准。",
    [UserMessages.SHIFT_PENDING_APPROVAL]: "该班次已结束并在等待批准。",
    [UserMessages.REJECT_MESSAGE_CONFIRM]: "你确定要拒绝{{id}}的提交?",
    [UserMessages.REJECT_FORM_CONFIRM]: "你确定要拒绝{{id}}的表单?",
    [UserMessages.SHIFT_INSTRUCTIONS_TEXT]:
      "请签名并点击“提交并结束班次”去结束该班次",
    [UserMessages.SHIFT_APPROVE_INSTRUCTIONS_TEXT]:
      "请签名并点击“批准”去批准该班次。请签名并点击“拒绝”去拒绝该班次",
    [UserMessages.SHIFT_DRAFT_RECORD_ALERT]:
      "提交的草稿已存在。请删除或保存后再激活签名",
    [UserMessages.DELETE_MESSAGE_CONFIRM]: "你确定要删除{{id}}的提交?",
    [UserMessages.DRAFT_WILL_NOT_BE_SAVED]: "该草稿将不会被保存。已选 ",
    [UserMessages.RECORD_ALREADY_EXISTS]: "已存在",
    [UserMessages.RECORD_SELECTED]: "已选 ",
    [UserMessages.DRAFT_SUBMISSION_PRESENT]: "草稿提交。保存后再签名及结束班次",
    [UserMessages.DATA_SAVED]: "数据保存成功",
    [UserMessages.SELECT_DETAILS]: "请选择工厂、产线、日期和班次",

    Email: "电子邮件",
    Password: "密码",
    Login: "登录",

    [PageTexts.SAVE]: "保存",
  },
};
