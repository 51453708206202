import { combineReducers } from "redux";
import { auth, form, forms, submission, submissions } from "react-formio";
import {
  filtersReducer as filters,
  languageReducer as languageParams,
  userFormsReducer as userForms,
  setPreviousReadingReducer as previousDayReading,
  formInformationsReducer as formInformations,
  offlineQueueReducer as offlineQueue,
  approverReducer as approverFlowCheck,
} from "./views/Form/Reducers";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    auth: auth(),
    form: form({ name: "form" }),
    headerFilterForm: form({ name: "headerFilterForm" }),
    forms: forms({ name: "forms", query: { type: "form" } }),
    submission: submission({ name: "submission" }),
    submissions: submissions({ name: "submissions" }),
    signatures: submissions({ name: "signatures" }),
    signature: submission({ name: "signature" }),
    user: submissions({ name: "user" }),
    userAccess: submissions({ name: "userAccess" }),
    userRoles: submissions({ name: "userRoles" }),
    formSubmissions: submissions({ name: "formSubmissions" }),
    //offlineSubmissions: submissions({ name: 'offlineSubmissions' }),
    filters,
    languageParams,
    userForms,
    formInformations,
    previousDayReading,
    router: connectRouter(history),
    offlineQueue,
    approverFlowCheck,
  });

export default createRootReducer;
