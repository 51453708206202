/* eslint no-useless-escape: 0 */
import { PageTexts, UserMessages } from '../../config';

export default {
  en: {
   
    Login: 'Login',
   
    'New {{title}}': 'New {{title}}',
    'Edit {{title}} Submission': 'Edit {{title}} Submission',
    'Are you sure you wish to delete the submission {{id}}?':
      'Are you sure you wish to delete the submission {{id}}?',
      'Are you sure you wish to reject the form {{id}}?':	
      'Are you sure you wish to reject the form {{id}}?',
    'Sign up for {{title}}': 'Sign up for {{title}}',
   
    Submit: 'Submit',
   
    [ PageTexts.DELETE_BUTTON_TEXT ]: 'Delete',
    [ PageTexts.REJECT_BUTTON_TEXT ]: 'Reject',
    
    [ PageTexts.CSV_EXPORT_BUTTON_TEXT ]: 'CSV Export',
    [ PageTexts.SUBMIT_FOR_APPROVAL ]: 'Submit For Approval',
    [ PageTexts.BACK ]: 'Back',
    [ PageTexts.SAVE ]: 'Save',
    [ PageTexts.BACK_TO_APPROVER ]: 'Approver Preview',
    [ PageTexts.APPROVE_FORM ]: 'Approve Form',
    [ PageTexts.APPROVE_SHIFT ]: 'Approve',
    [ PageTexts.SUBMIT_AND_END_SHIFT ]: 'Submit And End Shift',
    [ PageTexts.COMMON_FOR_SHIFT ]: 'commonForShift',
    [ PageTexts.IMPORTANT_INSTRUCTIONS_INTRO ]: 'Important instructions',
    [ UserMessages.NO_RECORDS ]: 'No record exists for the shift.',
    [ UserMessages.NO_RECORDS_DAY ]: 'No record exists for the day.',
    [ UserMessages.RECORDS_NOT_ADDED ]:
      'No records added for the shift. Please enter records and end shift',
    [ UserMessages.SHIFT_ENDED_TEXT ]: 'The shift is ended.',
    [ UserMessages.SHIFT_APPROVED ]: 'The shift is approved and closed.',
    [ UserMessages.SHIFT_PENDING_APPROVAL ]: 'The shift is ended and pending approval.',
    [ UserMessages.REJECT_MESSAGE_CONFIRM ]:	
      'Are you sure you wish to reject the submission {{id}}?',
      [ UserMessages.REJECT_FORM_CONFIRM ]:	
      'Are you sure you wish to reject the form {{id}}?',
    [ UserMessages.SHIFT_INSTRUCTIONS_TEXT ]:
      'To end the shift, please sign and press "Submit and End shift" button.',
      [ UserMessages.SHIFT_APPROVE_INSTRUCTIONS_TEXT ]:
      'To approve, please sign and press "Approve" button.To "Reject" the form, please press "Reject" button.',
    [ UserMessages.SHIFT_DRAFT_RECORD_ALERT ]:
      'Draft submissions exists.Please delete or save them to enable signature.',
      [ UserMessages.SHIFT_DUPLICATE_RECORD_ALERT ]:
      'Duplicate shift submissions exists.Please delete them to enable signature.',
      
    [ UserMessages.DELETE_MESSAGE_CONFIRM ]:
      'Are you sure you wish to delete the submission {{id}}?',
    [ UserMessages.DRAFT_WILL_NOT_BE_SAVED ]:
      'The draft will not be saved. Record for selected ',
    [ UserMessages.RECORD_ALREADY_EXISTS ]: 'already exists.',
    [ UserMessages.RECORD_SELECTED ]: 'Record for selected',
    [ UserMessages.DRAFT_SUBMISSION_PRESENT ]:
      'Draft submission. Please save it before signing and ending shift',
    [ UserMessages.DATA_SAVED ]: 'Data saved sucessfully',
    [ UserMessages.SELECT_DETAILS ]: 'Please select Plant, Line, Date and Shift',

     Sear:'',

    'Email':'Email',
    'Password':'Password',

  },
};
