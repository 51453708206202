import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { i18next } from "../i18n";
import { push } from "connected-react-router";
import routeService from "../services/routeService";
import { useLocation } from "react-router";
import { useRouteLanguage } from "../hooks/useRouteLanguage";
import { setLanguage } from "../views/Form/Actions";

const LanguageProvider = () => {
  const location = useLocation();
  const routeLanguage = useRouteLanguage();
  const isHome = location.pathname.includes("/form");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isHome) {
      const languageInRoute = routeLanguage || "en";
      updateLanguage(languageInRoute);
    }
  }, [isHome, routeLanguage]);

  const updateLanguage = (language) => {
    dispatch(push(routeService.getPagePath.formsList(language)));
    dispatch(setLanguage(language, true));
  };

  const handleChange = (event) => {
    i18next.changeLanguage(event.target.value);
    updateLanguage(event.target.value);
  };

  return (
    <div className="">
      <select
        className="pep-select"
        value={routeLanguage}
        onChange={handleChange}
      >
        <option value="en">English</option>
        <option value="ar">Arabic</option>
        <option value="cn">Chinese</option>
      </select>
    </div>
  );
};

export default LanguageProvider;
