/* eslint array-callback-return: 0 */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { eachComponent } from 'formiojs/utils/formUtils';
import { Forms,UserMessages } from '../config';
import { i18next } from '../i18n';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
/* Create Tabular view component for reports/ summary for forms like CS-59 PK-13 etc
These components data will not be saved in the DB */

class SummaryGrid extends React.Component {
    getTotal=(rowValue1,rowValue2)=>{
      
        return (
            (rowValue1!==undefined && !isNaN(rowValue1) ? Number.parseFloat(rowValue1):0)+
            (rowValue2!==undefined && !isNaN(rowValue2) ? Number.parseFloat(rowValue2):0)               
             
         );    

    }
   getvalidNumber=(rowValue1)=>{
    return rowValue1 !== undefined && !isNaN(rowValue1) ? Number.parseFloat(rowValue1):0;

   }
    getDifference=(rowValue1,rowValue2)=>{
        return (
            (rowValue1 !== undefined && !isNaN(rowValue1) ? Number.parseFloat(rowValue1):0) -
            (rowValue2 !== undefined && !isNaN(rowValue2) ? Number.parseFloat(rowValue2):0)       
        );  

    }
    getColumns = (form) => {

        const colHeading=[];
        eachComponent(form.components, (component) => {
           if (component.properties && component.properties.colHeading && component.properties.colSeq) {
            colHeading.push({
               headerName:i18next.t(component.label),field:component.key,seq:component.properties.colSeq,width:300 
   
            })
                 }            
          });
          
          return orderBy(colHeading,'seq','asc');
         
    }

    getRows = (submissions,summaryColumn,form) => {
        
        const availableSubmission = submissions;
        const rowsvalue = [];
        let grandTotal=0;
        
        availableSubmission.filter(item => {
            
            const data1=item.data[ summaryColumn[ 0 ].field ];
            const data2=item.data[ summaryColumn[ 1 ].field ];
            const data3=item.data[ summaryColumn[ 2 ].field ];
            if(form._id===Forms.CS59.id){
               
                rowsvalue.push({ shift: i18next.t(data1), potatoType: i18next.t(data2),totalAmountDispensedTons: data3 })
            }
            else if(form._id===Forms.CS59Ind.id){
               
                rowsvalue.push({ shift: i18next.t(data1), potatoType: i18next.t(data2),totalAmountDispensedTons: data3 })
            }
            else if(form._id===Forms.Followuponachievingplan.id){
                const data4=item.data[ summaryColumn[ 3 ].field ];
                const data5=item.data[ summaryColumn[ 4 ].field ];
                const data6=(data4/data3)*100;
                rowsvalue.push({ productType: i18next.t(data1), pKFlavorType: i18next.t(data2),plannedCases: data3,actualCases: data4, remainingCases: data5,performance: parseFloat(data6).toFixed(2) })
            }
            else if(form._id===Forms.PC7.id){
               
              rowsvalue.push({ shift: i18next.t(data1), potatoType: i18next.t(data2), leastSolid: data3 })
            }
            else if(form._id===Forms.PC12.id){
                rowsvalue.push({ shift: i18next.t(data1), pC12FlavorType: i18next.t(data2), production: data3 })
            }
            else if(form._id===Forms.PC12Ind.id){
                rowsvalue.push({ shift: i18next.t(data1), pC12FlavorType: i18next.t(data2), production: data3 })
            }
            else if(form._id===Forms.PK29.id){
                const data4=item.data[ summaryColumn[ 3 ].field ];
                const data5=item.data[ summaryColumn[ 4 ].field ];

                const data6=item.data[ summaryColumn[ 5 ].field ];
                const data7=item.data[ summaryColumn[ 6 ].field ];

                rowsvalue.push({ equipmentNo: i18next.t(data1), team: i18next.t(data2), bankNumber: data3,actualBags:data4,waste:data5, efficiency:data6,finishedGoodWaste:data7 })
            }
            else if(form._id===Forms.PK33.id){
                const data4=item.data[ summaryColumn[ 3 ].field ];
                const data5=item.data[ summaryColumn[ 4 ].field ];
                rowsvalue.push({ equipmentNo: i18next.t(data1), bankNumber: i18next.t(data2), tgWeight: data3, averageWeight:data4, gramGiveAway:data5 })
            }
        });
           //Logic specific for Followuponachievingplan form
     
        const preDefinedRows = orderBy(rowsvalue,summaryColumn[ 0 ].field,'asc');
        if(form._id===Forms.Followuponachievingplan.id){
            for (let i=0;i<preDefinedRows.length;i++){
                for (let j=i+1;j<preDefinedRows.length;j++){
                     if(preDefinedRows[ i ].productType===preDefinedRows[ j ].productType &&
                        preDefinedRows[ i ].pKFlavorType===preDefinedRows[ j ].pKFlavorType){                                                           
                           preDefinedRows[ i ].actualCases=this.getTotal(preDefinedRows[ i ].actualCases,preDefinedRows[ j ].actualCases);
                           preDefinedRows[ i ].remainingCases=this.getDifference(preDefinedRows[ i ].actualCases,preDefinedRows[ i ].plannedCases);
                           preDefinedRows.splice(j,1);
                           j=i;
                        }
               }
           }
           preDefinedRows.filter(value=>{
            value.performance=((value.actualCases/value.plannedCases)*100).toFixed(2);
           });
        }
        //Logic specific for CS-59 form & PC-07
        if(form._id===Forms.CS59.id || form._id===Forms.PC7.id ){           
            for (let i=0;i<preDefinedRows.length;i++){
                let counter =1;
                let solidPersentage=0;
                for (let j=i+1;j<preDefinedRows.length;j++){
                     if(preDefinedRows[ i ].shift===preDefinedRows[ j ].shift &&
                        preDefinedRows[ i ].potatoType===preDefinedRows[ j ].potatoType){   
                            if(form._id===Forms.CS59.id){
                                preDefinedRows[ i ].totalAmountDispensedTons=this.getTotal(preDefinedRows[ i ].totalAmountDispensedTons,preDefinedRows[ j ].totalAmountDispensedTons);                        
                                preDefinedRows.splice(j,1);
                            } else if(form._id===Forms.PC7.id) {
                              // to calculate avg solid % for PC-07
                              if( preDefinedRows[ i ].potatoType===preDefinedRows[ j ].potatoType){   
                                counter++;
                                if(counter == 2){
                                    solidPersentage += (preDefinedRows[ i ].leastSolid +preDefinedRows[ j ].leastSolid);
                                } else {
                                    solidPersentage += preDefinedRows[ j ].leastSolid;
                                }                               
                                preDefinedRows.splice(j,1);                                 
                              } 

                            }           
                          
                           j=i;
                           preDefinedRows[ i ].leastSolid = (solidPersentage/counter).toFixed(2);
                        }
                        
               }
           }
           if(form._id===Forms.CS59.id){
                preDefinedRows.some(tot=>{
                    grandTotal+=tot.totalAmountDispensedTons!==undefined && !isNaN(tot.totalAmountDispensedTons) ? Number.parseFloat(tot.totalAmountDispensedTons):0 ;
                });
                preDefinedRows.push({ shift:'' , potatoType:'Total' ,totalAmountDispensedTons: grandTotal });
           }
           
        }

         //Logic specific for PC-12 form
        if(form._id===Forms.PC12.id){
            for (let i=0;i<preDefinedRows.length;i++){
                for (let j=i+1;j<preDefinedRows.length;j++){
                    if(preDefinedRows[ i ].shift===preDefinedRows[ j ].shift &&
                        preDefinedRows[ i ].pC12FlavorType===preDefinedRows[ j ].pC12FlavorType){   
                          
                           preDefinedRows[ i ].production=this.getTotal(preDefinedRows[ i ].production,preDefinedRows[ j ].production);                        
                           preDefinedRows.splice(j,1);
                           j=i;
                        }
                }
            }
        }

        //Logic specific for PC-12 Ind form
        if(form._id===Forms.PC12Ind.id){
            for (let i=0;i<preDefinedRows.length;i++){
                for (let j=i+1;j<preDefinedRows.length;j++){
                    if(preDefinedRows[ i ].shift===preDefinedRows[ j ].shift &&
                        preDefinedRows[ i ].pC12FlavorType===preDefinedRows[ j ].pC12FlavorType){   
                          
                           preDefinedRows[ i ].production=this.getTotal(preDefinedRows[ i ].production,preDefinedRows[ j ].production);                        
                           preDefinedRows.splice(j,1);
                           j=i;
                        }
                }
            }
        }

        //Efficiency & downtime total for PK-29
        if(form._id===Forms.PK29.id){
         
            for (let i=0;i<preDefinedRows.length;i++){
                let effTotal=0;
                let count=1;
                for (let j=i+1;j<preDefinedRows.length;j++){
                  
                    if(preDefinedRows[ i ].equipmentNo===preDefinedRows[ j ].equipmentNo&&
                        preDefinedRows[ i ].bankNumber===preDefinedRows[ j ].bankNumber){ 
                                                   
                            effTotal+= effTotal===0?this.getvalidNumber(preDefinedRows[ i ].efficiency)
                            +this.getvalidNumber(preDefinedRows[ j ].efficiency):
                            this.getvalidNumber(preDefinedRows[ j ].efficiency);
                            
                             count++;
                            preDefinedRows[ i ].efficiency=(effTotal/count).toFixed(2);
                       
                            preDefinedRows[ i ].actualBags=this.getTotal(preDefinedRows[ i ].actualBags,preDefinedRows[ j ].actualBags).toFixed(2);                        
                            preDefinedRows[ i ].waste=this.getTotal(preDefinedRows[ i ].waste,preDefinedRows[ j ].waste).toFixed(2);                        
                            preDefinedRows[ i ].finishedGoodWaste= ((preDefinedRows[ i ].waste/preDefinedRows[ i ].actualBags)*100).toFixed(2);

                            preDefinedRows.splice(j,1);
                            j=i;           
                        }
                }
            }
        }

        //Target Weight & Gram Give Away total for PK-33
        if(form._id===Forms.PK33.id){
         
                for (let i=0;i<preDefinedRows.length;i++){
                        let targetWeightTotal=0;
                        let avgWeightTotal=0;
                        let gramGiveAwayTotal=0;

                        let count=1;
                        for (let j=i+1;j<preDefinedRows.length;j++){
                          
                            if(preDefinedRows[ i ].equipmentNo===preDefinedRows[ j ].equipmentNo&&
                                preDefinedRows[ i ].bankNumber===preDefinedRows[ j ].bankNumber){ 
                                                           
                                    targetWeightTotal+= targetWeightTotal===0?this.getvalidNumber(preDefinedRows[ i ].tgWeight)
                                    +this.getvalidNumber(preDefinedRows[ j ].tgWeight):
                                    this.getvalidNumber(preDefinedRows[ j ].tgWeight);
                                    avgWeightTotal+= avgWeightTotal===0?this.getvalidNumber(preDefinedRows[ i ].averageWeight)
                                    +this.getvalidNumber(preDefinedRows[ j ].averageWeight):
                                    this.getvalidNumber(preDefinedRows[ j ].averageWeight);
                                    gramGiveAwayTotal+= gramGiveAwayTotal===0?this.getvalidNumber(preDefinedRows[ i ].gramGiveAway)
                                    +this.getvalidNumber(preDefinedRows[ j ].gramGiveAway):
                                    this.getvalidNumber(preDefinedRows[ j ].gramGiveAway);
                                    
                                    count++;

                                    preDefinedRows[ i ].tgWeight=(targetWeightTotal/count).toFixed(2);
                                    preDefinedRows[ i ].averageWeight=(avgWeightTotal/count).toFixed(2);
                                    preDefinedRows[ i ].gramGiveAway=(gramGiveAwayTotal/count).toFixed(2);
                                    
                                    preDefinedRows.splice(j,1);
                                    j=i;           
                                }
                            }
                }
        }

        return preDefinedRows;
    }
    
      onGridReady = params => {
        params.api.sizeColumnsToFit();
      };
    render = () => {
      
        const {
            form,
            submissions
        } = this.props;
        const columnDefs=this.getColumns(form);
        const rowData=submissions&&submissions.length>0? this.getRows(submissions,columnDefs,form):'';
        const domLayout= 'autoHeight';
        const defaultColDef= { resizable: true };
       
       return (

           <div className={ 'ag-theme-alpine' }> 
               {rowData?<AgGridReact
                    columnDefs={ columnDefs }
                    rowData={ rowData }
                    domLayout={ domLayout }
                    defaultColDef={ defaultColDef }
                    onGridReady={ this.onGridReady }
                    suppressPropertyNamesCheck={ true }
                />:
               <div className="pep-norecords">{i18next.t(UserMessages.NO_RECORDS_DAY)}</div>}
           </div>
        )
    }
    
}
SummaryGrid.propTypes = {
    form: PropTypes.object,
    submissions:PropTypes.object
    };
export default SummaryGrid
