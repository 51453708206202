import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { FormIOSessionProvider } from "./FormIOSessionProvider";
import { appConfig } from "../appconfig";
import { PostLogout } from "../components/logout-success/PostLogout";

const oktaAuth = new OktaAuth({
  issuer: appConfig.REACT_APP_OKTA_OAUTH2_ISSUER,
  clientId: appConfig.REACT_APP_OKTA_OAUTH2_CLIENT_ID,
  redirectUri: window.location.origin + "/api/auth/callback/okta",
  //   scopes: ["openid", "email", "profile", "offline_access"],
});

export function MainProvider() {
  const history = useHistory();

  const customAuthHandler = (_oktaAuth) => {
    oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route path="/api/auth/callback/okta" component={LoginCallback} />
        <Route path="/logout" component={PostLogout} />
        <SecureRoute path="/" component={FormIOSessionProvider} />
      </Switch>
    </Security>
  );
}
