import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectRoot } from "react-formio";
import { setLanguage } from "./views/Form/Actions";
import { i18next } from "./i18n";
import { Header } from "./containers/Header/Header";
import Form from "./views/Form";
import routeService from "./services/routeService";

const App = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => selectRoot("auth", state));
  const languageParams = useSelector((state) =>
    selectRoot("languageParams", state)
  );
  const { language: currentLanguage, isLanguageChanged, user } = languageParams;

  useEffect(() => {
    const languageInHash =
      routeService.getLanguageFromRoute(window.location.hash) || "en";

    const userSettingsLanguage = user?.data?.language || "en";

    if (
      languageInHash !== currentLanguage &&
      i18next.languages.includes(languageInHash)
    ) {
      if (userSettingsLanguage === languageInHash || !userSettingsLanguage) {
        dispatch(setLanguage(languageInHash, false));
      } else {
        dispatch(setLanguage(language, true));
      }

      i18next.changeLanguage(languageInHash);
    }
  }, [currentLanguage, setLanguage, user]);

  return (
    <div className={`${currentLanguage === "ar" ? "rtl" : ""}`}>
      <Header />
      <div className="container" id="main">
        <Switch>
          {auth.authenticated ? (
            <Route
              path={routeService.getPagePath.formsList(":lang")}
              component={Form}
            />
          ) : (
            <Route path="/api/auth/callback/okta" />
          )}
          {auth.isActive ? null : auth.authenticated ? (
            <Redirect
              from="/"
              to={routeService.getPagePath.formsList(
                isLanguageChanged
                  ? currentLanguage
                  : user?.data?.language || currentLanguage
              )}
            />
          ) : (
            <Redirect
              from="/"
              to={routeService.getPagePath.auth(currentLanguage)}
            />
          )}
        </Switch>
      </div>
    </div>
  );
};

export default App;
