import React, { useState, useRef } from 'react';
import Scanner from './Scanner';
import Result from './Result';

const QuaggaBarCode = () => {
    const [ scanning, setScanning ] = useState(false);
    const [ results, setResults ] = useState([]);
    const scannerRef = useRef(null);

    return (
        <div>
            <div className="bottom-control-buttons"><button className="btn pep-btn" onClick={ () => setScanning(!scanning) }>{scanning ? 'Stop Scanning' : 'Start Scanning'}</button></div> 
            <ul className="results">
                {results.map((result) => (result.codeResult && <Result key={ result.codeResult.code } result={ result } />))}
            </ul>
            <div className="scannerRef" ref={ scannerRef }>
                <canvas className="drawingBuffer"/>
                {scanning ? <Scanner scannerRef={ scannerRef } onDetected={ (result) => setResults([ ...results, result ]) } /> : null}
            </div>
        </div>
    );
};

export default QuaggaBarCode;
