/* eslint no-undef: 0 */
import qs from "qs";

import { Forms } from "../config";
export default class routeService {
  static getLanguageFromRoute() {
    //Parameter hash is not using, directly taking route path name
    const urlPath = window.location.pathname;
    const locationParts = urlPath.split("/");
    //Get language part form the url (example: /en/form)
    return locationParts.length > 1 ? locationParts[1] : null;
  }

  static getCurrentPageNumberFromLocation(location) {
    return qs.parse(location.search, { ignoreQueryPrefix: true }).page !==
      undefined
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).page
      : 0;
  }
  static isApproverFromLocation(location) {
    return location.pathname.split("form/")[1] !== undefined
      ? location.pathname.split("form/")[1].includes(Forms.Signature.id)
      : false;
  }
  static isCSVFromLocation(location) {
    return location.pathname.split("form/")[1] !== undefined
      ? location.pathname.split("form/")[1].includes(Forms.CSV.id)
      : false;
  }

  static getPagePath = {
    auth(language) {
      return `/${language}/auth`;
    },
    formsList(language) {
      return `/${language}/form`;
    },
    submission(language, formId) {
      return `/${language}/form/${formId}/submission`;
    },
    submissionNew(language, formId) {
      return `/${language}/form/${formId}/submission?new`;
    },
    approver(language, formId) {
      return `/${language}/form/${formId}/submission?approverFlow`;
    },
    submissionPage(language, formId, pageNumber) {
      return `/${language}/form/${formId}/submission?page=${pageNumber}`;
    },
    submissionDetails(language, formId, submissionId) {
      return `/${language}/form/${formId}/submission/${submissionId}`;
    },
    submissionDetailsPage(language, formId, submissionId, currentPage) {
      return `/${language}/form/${formId}/submission/${submissionId}?page=${currentPage}`;
    },
    formPagePrePopulate(language, formId, pageNumber) {
      return `/${language}/form/${formId}?prePopulate?page=${pageNumber}`;
    },
    formPage(language, formId, pageNumber) {
      return `/${language}/form/${formId}?page=${pageNumber}`;
    },
    form(language, formId) {
      return `/${language}/form/${formId}`;
    },
    edit(language, formId) {
      return `/${language}/form/${formId}/edit`;
    },
    delete(language, formId) {
      return `/${language}/form/${formId}/delete`;
    },
    reject(language, formId) {
      return `/${language}/form/${formId}/reject`;
    },
    editSubmissionCommon(language, formId, submissionId) {
      return `/${language}/form/${formId}/submission/${submissionId}/edit`;
    },

    editSubmission(language, formId, submissionId, currentPage) {
      return `/${language}/form/${formId}/submission/${submissionId}/edit?page=${currentPage}`;
    },
    deleteSubmissionCommon(language, formId, submissionId) {
      return `/${language}/form/${formId}/submission/${submissionId}/delete`;
    },
    rejectSubmissionCommon(language, formId, submissionId) {
      return `/${language}/form/${formId}/submission/${submissionId}/reject`;
    },
    deleteSubmission(
      language,
      formId,
      submissionId,
      currentPage,
      path = "delete"
    ) {
      return `/${language}/form/${formId}/submission/${submissionId}/${path}?page=${currentPage}`;
    },

    signature(language, formId) {
      return `/${language}/form/${formId}/signature`;
    },
  };
}
