/* eslint no-useless-escape: 0 */
import { PageTexts,UserMessages } from '../../config';
export default {
  ar: {
   
    Login: 'تسجيل الدخول  ',
   
    [ PageTexts.SUBMIT_FOR_APPROVAL ]: 'تقدم للحصول على موافقة  ',
   
    '{{title}}': '  {{title}}',
    '{{title}} Submission': 'Eتعديل {{title}}  ',
    'Are you sure you wish to delete the submission {{id}}?':
      'Aهل أنت متأكد أنك تريد حذف التقديم {{id}}  ?',
    'Sign up for {{title}}': 'اشتراك في {{title}}  ',
   
    [ PageTexts.SAVE ]: 'حفظ  ',
    [ PageTexts.DELETE_BUTTON_TEXT ]: 'مسح',
    Submit: 'إرسال  ',
    
    //Error & Warning messages
     [ UserMessages.SHIFT_DRAFT_RECORD_ALERT ]:'تم حفظ البيانات مؤقتا .برجاء الضغط علي حذف او حفظ للامضاء',
     [ UserMessages.NO_RECORDS ]:'لا يوجد تسجيل للورديه',
     [ PageTexts.SUBMIT_AND_END_SHIFT ]:'ارسال و انهاء ورديه',
     [ UserMessages.SHIFT_INSTRUCTIONS_TEXT ]:'لانهاء الوريه برجاء الامضاء و ضغط  زر "ارسال و انهاء ورديه',
     [ UserMessages.RECORDS_NOT_ADDED ]:'لا يوجد بيانات  للورديه .برجاء ادخال ابيانات الورديه',
     'Email':'ايميل',
     'Password':'كلمة السر',
     
  },
};
