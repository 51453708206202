/* eslint no-useless-computed-key: 0 */
/* eslint no-undef: 0 */
import { getSubmissions, indexForms, setUser } from "react-formio";
import { getFormName } from "../../../utils";
import { Resources, UserRole } from "../../../config";
import * as actionTypes from "./ActionTypes";

/**Add GPID from SAML login  Starts*/
export const getUserByEmail =
  (
    email,
    user,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "user",
        1,
        { limit: 999999, ["data.email"]: email },
        Resources.Gpid.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          /**Random user-User not available in the db flow Starts*/
          let userGpid = 10000001;
          /**Random user-User not available in the db flow ends*/
          if (result.length !== 0) {
            userGpid = result[0] && result[0].data && result[0].data.gpid;
          }

          user.data.gpid = userGpid;
          dispatch(setUser(user));
          return callback(null, userGpid);
        }
      )
    );
  };
/**Add GPID from SAML login  Ends*/
export const getRateConfigList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.RateConfig.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getSKUList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.SKU.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getFlavorList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.Flavor.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getsWTransactionsList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.SWTransactions.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getsWItemDetailsList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.SWItemDetails.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getDataSetList =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.DataSet.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getShift =
  (
    gpid,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.Shift.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getLine =
  (
    gpid,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.Line.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getTranslations =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.Language.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getAccessByGpid =
  (
    gpid,
    email,
    user,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    if (
      user &&
      user.data &&
      (user.data.plantId === "" || user.data.plantId === undefined)
    ) {
      dispatch(
        getSubmissions(
          "user",
          1,
          { limit: 999999, ["data.gpid"]: gpid },
          Resources.Gpid.id,
          (err, result) => {
            if (err || !result) {
              return callback(err);
            }
            if (result.length !== 0) {
              user.data.plantId = result[0].data.plantId;
              user.data.productionLineId = result[0].data.productionLineId;
              user.data.language = result[0].data.language;
              user.data.dropDownName = result[0].data.dropDownName;
              if (
                ((user.data && user.data.gpid === undefined) ||
                  user.gpid === undefined) &&
                user.data.nameID !== undefined
              ) {
                /**Random user-User not available in the db flow Starts*/

                user.data.gpid = gpid;
                if (user.roles.length === 0) {
                  /** Set user role to authenticated if empty  */
                  user.roles.push(UserRole.Authenticated.id);
                }
              }
              dispatch(setUser(user));
            }
            dispatch(
              getSubmissions(
                "userAccess",
                1,
                { limit: 999999, ["data.gpid"]: gpid },
                Resources.UserAccess.id,
                (err, result) => {
                  if (err || !result) {
                    return callback(err);
                  }

                  return callback(null, result);
                }
              )
            );
          }
        )
      );
    } else {
      dispatch(
        getSubmissions(
          "userAccess",
          1,
          { limit: 999999, ["data.gpid"]: gpid },
          Resources.UserAccess.id,
          (err, result) => {
            if (err || !result) {
              return callback(err);
            }
            if (
              ((user.data && user.data.gpid === undefined) ||
                user.gpid === undefined) &&
              user.data.nameID !== undefined
            ) {
              /**Random user-User not available in the db flow Starts*/

              user.data.gpid = gpid;
              if (user.roles.length === 0) {
                /** Set user role to authenticated if empty  */
                user.roles.push(UserRole.Authenticated.id);
              }
              /**Random user-User not available in the db flow ends*/
              if (result.length === 0) {
                user.data.gpid = 10000001;
              }
              dispatch(setUser(user));
            }

            return callback(null, result);
          }
        )
      );
    }
  };

export const getRolesByIds =
  (
    ids = [],
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "userRoles",
        1,
        { limit: 999999 },
        Resources.Roles.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }

          const validRoles = result.filter(({ data }) =>
            ids.includes(data.roleId)
          );
          return callback(null, validRoles);
        }
      )
    );
  };

export const getPreviousSubmissions =
  (
    form,
    page,
    query,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions("formSubmissions", page, query, form, (err, result) => {
        if (err || !result) {
          return callback(err);
        }

        return callback(null, result);
      })
    );
  };

export const getFormSubmissionsByIds =
  (
    ids = [],
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.Forms.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }

          const validForms = result.filter(({ data }) =>
            ids.includes(data.formId)
          );
          return callback(null, validForms);
        }
      )
    );
  };

export const getStandardTitle =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.AuditStandard.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getTrainingDetails =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.TrainingDetails.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getGpidDetails =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.GpidDetails.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };

export const getCornDetails =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch(
      getSubmissions(
        "formSubmissions",
        1,
        { limit: 999999 },
        Resources.CornDetails.id,
        (err, result) => {
          if (err || !result) {
            return callback(err);
          }
          return callback(null, result);
        }
      )
    );
  };
export const getUserForms =
  (
    gpid,
    email,
    user,
    filters,
    page,
    query = {},
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_USER_FORMS, isLoading: true });

    dispatch(
      getAccessByGpid(gpid, email, user, (err, access) => {
        if (err) {
          dispatch({ type: actionTypes.USER_FORMS_ERROR, error: err });
          return callback(err);
        }
        const roleIds = [];
        access.forEach(({ data }) => roleIds.push(...data.roleId));
        const resultsIds = roleIds.map((i) => Number(i));
        dispatch(
          getRolesByIds(resultsIds, (err, rolesList) => {
            if (err) {
              dispatch({ type: actionTypes.USER_FORMS_ERROR, error: err });
              return callback(err);
            }

            //Make plantId='' and lineId='' if we dont want filtering based on selected plant and line
            const plantId =
              filters &&
              filters.plant &&
              filters.plant !== undefined &&
              filters.plant !== ""
                ? filters.plant
                : user.data.plantId !== undefined && user.data.plantId !== ""
                ? user.data.plantId
                : "";

            const lineId =
              filters &&
              filters.line &&
              filters.line !== undefined &&
              filters.line !== ""
                ? filters.line
                : user.data.productionLineId !== undefined &&
                  user.data.productionLineId !== ""
                ? user.data.productionLineId
                : "";

            const formIds = [];
            const roles = rolesList.filter(
              (role) =>
                // role.data.plant===undefined ||
                role.data.plant !== undefined &&
                role.data.plant.includes(plantId)
            );
            roles.forEach(({ data }) => formIds.push(...data.formId));
            const approverForms = [];
            const approverFormNames = [];
            const approverIds = [];
            const readOnlyIds = [];
            const readOnlyForms = [];
            let superApprover = false;
            let departmentApprover = false;
            roles.forEach(({ data }) => {
              if (data.approver === "yes") {
                approverIds.push(...data.formId);
              }
              if (data.readOnlyAccess === "yes") {
                readOnlyIds.push(...data.formId);
              }
              if (data.superApprover) {
                superApprover = true;
              }
              if (data.departmentApprover) {
                departmentApprover = true;
              }
            });

            if (approverIds.length !== 0) {
              formIds.push(50);
            }

            dispatch(
              getFormSubmissionsByIds(formIds, (err, userForms) => {
                if (err) {
                  dispatch({ type: actionTypes.USER_FORMS_ERROR, error: err });
                  return callback(err);
                }

                const formNames = userForms.map((form) =>
                  getFormName(form.data)
                );
                let resources = [];
                dispatch(
                  indexForms(
                    "forms",
                    1,
                    {
                      limit: 999999,
                      type: "resource",
                      name__in: formNames.join(","),
                    },
                    (err, resourceList) => {
                      if (resourceList) {
                        resources = resourceList;
                      }
                    }
                  )
                );
                query["name__in"] = formNames.join(",");

                dispatch(
                  indexForms("forms", page, query, (err, forms) => {
                    const categoriesSet = new Set();

                    if (forms) {
                      forms.push(...resources);

                      forms.forEach((form) => {
                        const formMatch = userForms.find(
                          ({ data }) => form.name === getFormName(data)
                        );
                        if (
                          formMatch &&
                          approverIds.includes(formMatch.data.formId) &&
                          formMatch.data.plant !== undefined &&
                          formMatch.data.plant.includes(plantId) &&
                          form.name !== "admin-14csvexport"
                        ) {
                          approverForms.push(form._id);
                          const key =
                            formMatch.data.formReference +
                            "::" +
                            formMatch.data.formNameDescription;

                          approverFormNames.push(key + "@@@" + form.title);
                        }
                        if (
                          formMatch &&
                          formMatch.data.plant !== undefined &&
                          formMatch.data.plant.includes(plantId) &&
                          formMatch.data.line !== undefined &&
                          formMatch.data.line.includes(lineId)
                        ) {
                          if (readOnlyIds.includes(formMatch.data.formId)) {
                            readOnlyForms.push(form._id);
                          }
                          form.title = `${formMatch.data.formReference} ${formMatch.data.formNameDescription}`;
                          form.formReference = formMatch.data.formReference;
                          form.formNameDescription =
                            formMatch.data.formNameDescription;
                          form.dropDownName = formMatch.data.dropDownName;
                          categoriesSet.add(formMatch.data.dropDownName);
                          form.issueNo = formMatch.data.formIssueNo;
                          form.issueDate = formMatch.data.formIssueDate;
                          form.formId = formMatch.data.formId;
                          form.sequence =
                            formMatch.data.sequence !== "" &&
                            formMatch.data.sequence !== undefined
                              ? formMatch.data.sequence
                              : 999;
                        }
                      });
                    }

                    if (forms && forms.length !== 0) {
                      forms.sort(function (a, b) {
                        return a.sequence - b.sequence;
                      });
                    }

                    const categories = [];
                    categoriesSet.forEach((category) =>
                      categories.push(category)
                    );

                    dispatch({
                      type: actionTypes.SET_USER_FORMS,
                      forms,
                      categories,
                      approverForms,
                      approverFormNames,
                      superApprover,
                      departmentApprover,
                      readOnlyForms,
                    });
                    return callback(null, forms);
                  })
                );
              })
            );
          })
        );
      })
    );
  };

export const getPreviousDaySubmissions =
  (
    formId,
    previousQuery = {},
    page,
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    let previousReading = [];

    dispatch(
      getPreviousSubmissions(
        formId,
        page,
        previousQuery,
        (err, previousData) => {
          if (err) {
            return callback(err);
          }

          if (previousData && previousData.length > 0) {
            previousData.sort(function (a, b) {
              return a.data.date.localeCompare(b.data.date);
            });
            previousReading =
              previousData.length > 0
                ? previousData[previousData.length - 1]
                : null;
          }

          dispatch({
            type: actionTypes.PREVIOUS_READING_START,

            previousReading,
          });
          return callback(null, previousReading);
        }
      )
    );
  };
export const getFormInformations =
  (
    callback = () => {
      // This is intentional
    }
  ) =>
  (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_FORM_INFORMATIONS, isLoading: true });

    let skuList = [];
    dispatch(
      getSKUList((err, productTypeList) => {
        if (err) {
          return callback(err);
        }
        if (productTypeList) {
          skuList = productTypeList;
        }
        let rateConfigList = [];
        dispatch(
          getRateConfigList((err, rateList) => {
            if (err) {
              return callback(err);
            }
            if (rateList) {
              rateConfigList = rateList;
            }
            let flavorList = [];
            dispatch(
              getFlavorList((err, flavorTypeList) => {
                if (err) {
                  return callback(err);
                }
                if (flavorTypeList) {
                  flavorList = flavorTypeList;
                }

                let sWItemDetailsList = [];
                dispatch(
                  getsWItemDetailsList((err, sWItemDetailsTypeList) => {
                    if (err) {
                      return callback(err);
                    }
                    if (sWItemDetailsTypeList) {
                      sWItemDetailsList = sWItemDetailsTypeList;
                    }
                    let sWTransactionsList = [];
                    dispatch(
                      getsWTransactionsList((err, sWTransactionsTypeList) => {
                        if (err) {
                          return callback(err);
                        }
                        if (sWTransactionsTypeList) {
                          sWTransactionsList = sWTransactionsTypeList;
                        }

                        let dataSetList = [];

                        dispatch(
                          getDataSetList((err, dataList) => {
                            if (err) {
                              dispatch({
                                type: actionTypes.FORM_INFORMATION_ERROR,
                                error: err,
                              });
                              return callback(err);
                            }
                            if (dataList) {
                              dataSetList = dataList;
                            }

                            let standardTitleList = [];
                            dispatch(
                              getStandardTitle((err, dataList) => {
                                if (err) {
                                  dispatch({
                                    type: actionTypes.FORM_INFORMATION_ERROR,
                                    error: err,
                                  });
                                  return callback(err);
                                }
                                if (dataList) {
                                  standardTitleList = dataList;
                                }

                                let trainingDetailList = [];

                                dispatch(
                                  getTrainingDetails((err, dataList) => {
                                    if (err) {
                                      dispatch({
                                        type: actionTypes.FORM_INFORMATION_ERROR,
                                        error: err,
                                      });
                                      return callback(err);
                                    }
                                    if (dataList) {
                                      trainingDetailList = dataList;
                                    }

                                    let gpidDetailList = [];

                                    dispatch(
                                      getGpidDetails((err, dataList) => {
                                        if (err) {
                                          dispatch({
                                            type: actionTypes.FORM_INFORMATION_ERROR,
                                            error: err,
                                          });
                                          return callback(err);
                                        }
                                        if (dataList) {
                                          gpidDetailList = dataList;
                                        }

                                        let corntypeList = [];

                                        dispatch(
                                          getCornDetails((err, dataList) => {
                                            if (err) {
                                              dispatch({
                                                type: actionTypes.FORM_INFORMATION_ERROR,
                                                error: err,
                                              });
                                              return callback(err);
                                            }
                                            if (dataList) {
                                              corntypeList = dataList;
                                            }

                                            dispatch({
                                              type: actionTypes.SET_FORM_INFORMATIONS,
                                              dataSetList,
                                              skuList,
                                              flavorList,
                                              sWTransactionsList,
                                              sWItemDetailsList,
                                              rateConfigList,
                                              standardTitleList,
                                              trainingDetailList,
                                              gpidDetailList,
                                              corntypeList,
                                            });
                                            return callback(null, dataSetList);
                                          })
                                        );
                                      })
                                    );
                                  })
                                );
                              })
                            );
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  };
