import React from "react";
import "./PostLogout.css";
import { Header } from "../../containers/Header/Header";
import { useSelector } from "react-redux";
import { selectRoot } from "react-formio";

export const PostLogout = () => {
  const languageParams = useSelector((state) =>
    selectRoot("languageParams", state)
  );
  const { language: currentLanguage } = languageParams;

  return (
    <div className={`${currentLanguage === "ar" ? "rtl" : ""}`}>
      <Header hideActions />
      <div className="post-logout-container">
        <h2 className="post-logout-title">
          You have been successfully logged out!
        </h2>
        <p className="post-logout-message">
          Thank you for using paperless application. You may close this tab now.
        </p>
      </div>
    </div>
  );
};
