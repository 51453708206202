import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { initAuth, Formio } from "react-formio";
import { selectRoot } from "react-formio";
import App from "../App";
import { AppConfig } from "../config";
import Loader from "../components/loader/Loader";

export function FormIOSessionProvider() {
  const dispatch = useDispatch();

  const [isSwappingToken, setIsSwappingToken] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const formIOAuth = useSelector((state) => selectRoot("auth", state));

  useEffect(() => {
    const performFormIOTokenSwap = async () => {
      const tokenManager = oktaAuth.tokenManager;
      const accessTokenResult = await tokenManager.get("accessToken");
      const accessToken = accessTokenResult.accessToken;

      if (accessToken) {
        Formio.registerPlugin(
          {
            requestOptions: (options) => {
              options.headers["Authorization"] = `Bearer ${accessToken}`;
              return options;
            },
          },
          "httpHeadersTransform"
        );
        const header = new Formio.Headers();

        const formio = new Formio(AppConfig.projectUrl);
        await formio.currentUser({
          external: true,
          header: header,
        });

        // Initialize the current user
        dispatch(initAuth());
      }
    };

    if (!formIOAuth.authenticated && !isSwappingToken) {
      setIsSwappingToken(true);
      performFormIOTokenSwap();
    }
  }, [formIOAuth, isSwappingToken]);

  if (!formIOAuth.authenticated) {
    return <Loader />;
  } else {
    return <App />;
  }
}
