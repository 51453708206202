/* eslint no-undef: 0 */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { selectRoot, getForm } from "react-formio";
import { setFiltersValue } from "./Actions";
import {
  getAccessByGpid,
  getShift,
  getTranslations,
  getLine,
  getUserForms,
} from "./Actions/UserForms";
import { PropTypes } from "prop-types";
import ReactForm from "../../containers/Form";
import List from "./List";
import Item from "./Item/index";
import { Forms } from "../../config";
import routeService from "../../services/routeService";
import {
  normalizeDataSetCategory,
  mergeAndGetLanguageTranslations,
} from "../../utils";
import { eachComponent } from "formiojs/utils/formUtils";
import { i18next } from "../../i18n";
class Form extends Component {
  state = {
    headerFilterForm: null,
    approverFilter: false,
    shiftAccess: null,
    lines: null,
  };

  setPlantDefaults(form, access, defaultPlantId) {
    let plantSelect = [];
    eachComponent(form.components, (component) => {
      if (component.key === "plant") {
        plantSelect = component;
      }
    });
    if (plantSelect) {
      const lineIds = [];
      access.forEach(({ data }) => lineIds.push(...data.lineId));

      const previousFilter = plantSelect.filter ? `${plantSelect.filter}&` : "";
      plantSelect.filter = `${previousFilter}data.productionLineId__in=${lineIds.join(
        ","
      )}`;
      plantSelect.uniqueOptions = true;
      if (defaultPlantId !== null && defaultPlantId !== undefined) {
        plantSelect.defaultValue = defaultPlantId.toString();
      }
    }
  }
  setShiftDefaults(form, shiftAccess, defaultPlantId, shiftId, webform) {
    /**Populating Shift value basedon selcted plant-  starts */
    let shiftSelect,
      shiftSelectWeb,
      formShiftSelect,
      formShiftSelectWeb = [];
    eachComponent(form.components, (component) => {
      if (component.key === "shift") {
        shiftSelect = component;
        shiftSelect.data = { values: [] };
      }
      if (component.key === "formShift") {
        formShiftSelect = component;
        formShiftSelect.data = { values: [] };
      }
    });
    if (webform && webform !== undefined) {
      shiftSelectWeb = webform.getComponent("shift");
      formShiftSelectWeb = webform.getComponent("formShift");
      shiftSelectWeb.component.data.values = [];
      formShiftSelectWeb.component.data.values = [];
    }
    const shiftValues = [];
    let defaultShift = "";
    let allShiftValue = "";
    shiftAccess.forEach(function (elem) {
      if (elem.data && elem.data.plantId.includes(defaultPlantId)) {
        const value = normalizeDataSetCategory(elem.data.shiftName);
        allShiftValue += value + ",";
        if (webform && webform !== undefined) {
          formShiftSelectWeb.component.data.values.push({
            label: elem.data.shiftName,
            value,
          });
          shiftSelectWeb.component.data.values.push({
            label: elem.data.shiftName,
            value,
          });
        }
        // else{
        formShiftSelect.data.values.push({ label: elem.data.shiftName, value });
        shiftSelect.data.values.push({ label: elem.data.shiftName, value });

        // }

        shiftValues.push({ label: elem.data.shiftName, value });
        if (
          shiftId !== undefined &&
          shiftId !== "" &&
          normalizeDataSetCategory(shiftId) === value
        ) {
          defaultShift = value;
        }
      }
    });

    if (defaultShift !== "") {
      if (webform && webform !== undefined) {
        shiftSelectWeb.component.defaultValue = defaultShift;
      }
      //else{
      shiftSelect.defaultValue = defaultShift;
      //}
    } else if (shiftValues.length !== 0) {
      if (webform && webform !== undefined) {
        shiftSelectWeb.component.defaultValue = shiftValues[0].value;
      }
      //else{
      shiftSelect.defaultValue = shiftValues[0].value;
      //}
    }

    /**If shiftcomponent is of approver flow/csv export, add ALL options to the drop down */

    const value = allShiftValue;

    if (webform && webform !== undefined) {
      formShiftSelectWeb.component.data.values.push({ label: "All", value });

      formShiftSelectWeb.component.defaultValue = allShiftValue;
    }
    //else{
    formShiftSelect.data.values.push({ label: "All", value });

    formShiftSelect.defaultValue = allShiftValue;
    // }

    /**Populating Shift value basedon selcted plant-  ends */
  }

  setLineDefaults(form, defaultLineId, plantId, lines, webform) {
    let lineSelect,
      lineSelectWeb,
      apprLineSelect,
      apprLineSelectWeb = [];
    eachComponent(form.components, (component) => {
      if (component.key === "line") {
        component.data = { values: [] };
        lineSelect = component;
        lineSelect.data = { values: [] };
      }
      if (component.key === "apprLine") {
        component.data = { values: [] };
        apprLineSelect = component;
        apprLineSelect.data.values = { values: [] };
        apprLineSelect.defaultValue = "All";
      }
    });
    if (webform && webform !== undefined) {
      lineSelectWeb = webform.getComponent("line");
      apprLineSelectWeb = webform.getComponent("apprLine");
      lineSelectWeb.component.data.values = [];
      apprLineSelectWeb.component.data.values = [];
    }
    if (!lineSelect && !apprLineSelect) {
      return;
    }
    const lineList = lines
      .filter(
        (line) => line.data && parseInt(line.data.plantId) === parseInt(plantId)
      )
      .map((line) => {
        return line;
      });

    const lineIds = [];
    lineList.sort(function (a, b) {
      return a.data.productionLineId.localeCompare(b.data.productionLineId);
    });
    lineList.forEach(({ data }) => lineIds.push(data.productionLineId));

    if (webform && webform !== undefined) {
      lineSelectWeb.component.data.values = [];
      apprLineSelectWeb.component.data.values = [];
      apprLineSelectWeb.component.defaultValue = "All";
    }
    //else{
    lineSelect.data.values = [];
    apprLineSelect.data.values = [];
    apprLineSelect.defaultValue = "All";

    // }

    lineIds.forEach(function (item) {
      const value = item;
      if (webform && webform !== undefined) {
        apprLineSelectWeb.component.data.values.push({ label: item, value });
        lineSelectWeb.component.data.values.push({ label: item, value });
      }
      // else{
      apprLineSelect.data.values.push({ label: item, value });
      lineSelect.data.values.push({ label: item, value });
      // }
    });

    const value = lineIds.toString() + ",All";
    if (webform && webform !== undefined) {
      apprLineSelectWeb.component.data.values.push({ label: "All", value });
    }
    // else{
    apprLineSelect.data.values.push({ label: "All", value });
    //  }

    if (
      defaultLineId !== null &&
      defaultLineId !== undefined &&
      lineIds.includes(defaultLineId)
    ) {
      if (webform && webform !== undefined) {
        lineSelectWeb.component.defaultValue = defaultLineId;
        apprLineSelectWeb.component.defaultValue = defaultLineId;
      }
      //  else{
      lineSelect.defaultValue = defaultLineId;
      apprLineSelect.defaultValue = defaultLineId;
      //  }
    } else if (lineIds.length !== 0) {
      if (webform && webform !== undefined) {
        lineSelectWeb.component.defaultValue = lineIds[0];
        apprLineSelectWeb.component.defaultValue = lineIds[0];
      }
      // else{
      lineSelect.defaultValue = lineIds[0];
      apprLineSelect.defaultValue = lineIds[0];
      // }
    }
  }

  componentDidMount() {
    const {
      auth,
      getUserAccess,
      getUserShift,
      getUserLine,
      getHeaderFilterForm,
      getLanguageTranslations,
    } = this.props;

    if (this.props.headerFilterForm && this.props.headerFilterForm.id) {
      return this.setState({
        headerFilterForm: this.props.headerFilterForm.form,
      });
    }
    getLanguageTranslations((err, translations) => {
      if (err) {
        return;
      }
      const langArr = mergeAndGetLanguageTranslations(translations, null, null);
      window.sessionStorage.setItem("langArray", JSON.stringify(langArr));

      getHeaderFilterForm((err, headerFilterForm) => {
        if (err) {
          return;
        }
        const gpid = auth.user.data.gpid
          ? auth.user.data.gpid
          : auth.user.data.nameID !== undefined
          ? parseInt(auth.user.data.nameID)
          : "";
        getUserAccess(gpid, auth.user.data.email, auth.user, (err, access) => {
          const filterValues =
            headerFilterForm.data ||
            JSON.parse(window.sessionStorage.getItem("filters")) ||
            {};

          if (err) {
            this.props.onFiltersChange(filterValues);
            return this.setState({ headerFilterForm });
          }

          const plantId =
            filterValues.plant !== undefined && filterValues.plant !== ""
              ? filterValues.plant
              : auth.user.data.plantId;

          getUserShift(plantId, (err, shiftAccess) => {
            if (err) {
              this.props.onFiltersChange(filterValues);
              return this.setState({ headerFilterForm });
            }
            this.setState({
              shiftAccess: shiftAccess,
            });
            getUserLine(plantId, (err, lines) => {
              if (err) {
                this.props.onFiltersChange(filterValues);
                return this.setState({ headerFilterForm });
              }
              this.setState({
                lines: lines,
              });
              const lineId = auth.user.data.productionLineId;
              const shiftId = auth.user.data.dataSetCategory;
              this.setPlantDefaults(headerFilterForm, access, plantId);
              this.setShiftDefaults(
                headerFilterForm,
                shiftAccess,
                plantId,
                shiftId,
                undefined
              );
              this.setLineDefaults(
                headerFilterForm,
                lineId,
                plantId,
                lines,
                undefined
              );
              this.props.onFiltersChange(filterValues);
              this.setState({ headerFilterForm });
            });
          });
        });
      });
    });
  }
  static propTypes = {
    auth: PropTypes.object,
    headerFilterForm: PropTypes.object,
    languageParams: PropTypes.object,
    getUserAccess: PropTypes.func,
    userForms: PropTypes.shape({
      approverFormNames: PropTypes.array,
      forms: PropTypes.array,
    }),
    getUserShift: PropTypes.func,
    getUserForms: PropTypes.func,
    getUserLine: PropTypes.func,
    getLanguageTranslations: PropTypes.func,
    getHeaderFilterForm: PropTypes.func,
    onFiltersChange: PropTypes.func,
    location: PropTypes.object,
  };
  render() {
    const { headerFilterForm, shiftAccess, lines } = this.state;
    const {
      languageParams: { language },
      location,
      userForms,
      auth,
      getUserForms,
    } = this.props;
    const isApprover = routeService.isApproverFromLocation(location);
    const isCSV = routeService.isCSVFromLocation(location);

    if (
      userForms.approverFormNames.length !== 0 &&
      headerFilterForm &&
      headerFilterForm.components &&
      isApprover
    ) {
      let formNameSelect = [];

      if (this.webform) {
        formNameSelect = this.webform.getComponent("formName");
      }
      const values = [];
      if (
        userForms.approverFormNames.find((x) => x.includes("All@@@All")) ===
        undefined
      )
        userForms.approverFormNames.push("All@@@All");
      userForms.approverFormNames.forEach(function (item) {
        const value = item.split("@@@")[1];
        const label = item.split("@@@")[0];
        values.push({
          label: label.split("::")[0] + " " + i18next.t(label.split("::")[1]),
          value,
        });
      });

      formNameSelect.component.data = { values };
      formNameSelect.component.defaultValue = "All";
    }

    if (
      userForms.forms.length !== 0 &&
      headerFilterForm &&
      headerFilterForm.components &&
      isCSV
    ) {
      let formNameSelect = [];

      if (this.webform) {
        formNameSelect = this.webform.getComponent("formNameCsv");
      }
      const values = [];
      formNameSelect.component.data = { values };

      userForms.forms.forEach(function (elem) {
        if (
          elem.type !== "resource" &&
          !(elem.dropDownName && elem.dropDownName === "Admin")
        ) {
          const value = elem._id;
          let key =
            elem.formReference + " " + i18next.t(elem.formNameDescription);
          if (key.trim() === "undefined") {
            key = elem.title;
          }
          formNameSelect.component.defaultValue = elem.title;
          values.push({ label: key, value });
        }
      });
      values.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });
      formNameSelect.component.data = { values };
    }
    const options = {
      readOnly:
        !(
          location.pathname === routeService.getPagePath.formsList(language) ||
          location.pathname ===
            `${routeService.getPagePath.formsList(language)}/`
        ) &&
        !isApprover &&
        !isCSV &&
        (!location.pathname.includes("/submission") ||
          location.pathname.includes("/submission/")),
    };

    return (
      <div>
        <div className="pep-header">
          {headerFilterForm ? (
            <ReactForm
              form={headerFilterForm}
              onChange={(changes) => {
                if (changes.changed) {
                  /* comment out the check for change in plant and line, if no home page
                   refresh needs to be done based on plant or line selection */
                  if (
                    changes.changed.component.key === "searchHome" &&
                    changes.data["searchHome"].search === false
                  ) {
                    changes.data["searchForm"] = "";
                    changes.data["trainerForm"] = "";
                    this.props.onFiltersChange(changes.data);
                    window.sessionStorage.setItem(
                      "filters",
                      JSON.stringify(changes.data)
                    );
                  }
                  if (changes.changed.component.key === "plant") {
                    this.setShiftDefaults(
                      headerFilterForm,
                      shiftAccess,
                      changes.changed.value,
                      "",
                      this.webform
                    );
                    this.setLineDefaults(
                      headerFilterForm,
                      "",
                      changes.changed.value,
                      lines,
                      this.webform
                    );
                    changes.data.line = "";
                    changes.data.shift = "";
                    changes.data.apprLine = "";
                    changes.data.formShift = "";
                    this.props.onFiltersChange(changes.data);
                    getUserForms(
                      auth.user.data.gpid,
                      null,
                      auth.user,
                      changes.data,
                      1,
                      {
                        limit: 999999,
                        type: "form",
                        sort: "created",
                      }
                    );
                  } else if (changes.changed.component.key === "line") {
                    getUserForms(
                      auth.user.data.gpid,
                      null,
                      auth.user,
                      changes.data,
                      1,
                      {
                        limit: 999999,
                        type: "form",
                        sort: "created",
                      }
                    );
                    this.props.onFiltersChange(changes.data);
                  } else {
                    this.props.onFiltersChange(changes.data);
                  }
                }
              }}
              ref={(instance) => {
                if (instance) {
                  instance.createPromise.then(() => {
                    this.webform = instance.formio;
                    const properties = { ...this.webform._form.properties };
                    this.webform._form.properties.isHidden = false;
                    let searchForm = [];
                    let searchHome = [];
                    let frmDateFilter = [];
                    let toDateFilter = [];
                    const filterValues = JSON.parse(
                      window.sessionStorage.getItem("filters")
                    );
                    if (filterValues && filterValues.searchForm) {
                      filterValues.searchForm = "";
                      filterValues.searchHome.search = false;
                      filterValues.trainerForm = "";
                      this.props.onFiltersChange(filterValues);
                      window.sessionStorage.setItem(
                        "filters",
                        JSON.stringify(filterValues)
                      );
                    }
                    this.webform._submission.data.searchForm = "";
                    eachComponent(
                      this.webform._form.components,
                      (component) => {
                        if (component.key === "searchForm") {
                          searchForm = component;
                          searchForm.customConditional = "";
                          searchForm.hidden = true;
                        }
                        if (component.key === "searchHome") {
                          searchHome = component;
                          searchHome.input = false;
                          searchHome.hidden = true;
                        }
                        if (component.key === "frmDateFilter") {
                          frmDateFilter = component;
                          frmDateFilter.hidden = true;
                          frmDateFilter.customConditional = "";
                        }
                        if (component.key === "toDateFilter") {
                          toDateFilter = component;
                          toDateFilter.hidden = true;
                          toDateFilter.customConditional = "";
                        }
                        if (
                          component.key === "date" ||
                          component.key === "line" ||
                          component.key === "shift" ||
                          component.key === "plant"
                        ) {
                          component.hidden = false;
                          component.customConditional =
                            "show = data.searchHome && data.searchHome.search && data.searchHome.search!==undefined?!data.searchHome.search:true;";
                        }
                        component.disabled = false;
                      }
                    );
                    let formId = "";
                    const f1 = location.pathname.split("/submission")[0];
                    formId = f1.split("form/")[1];
                    if (
                      (location.pathname ===
                        routeService.getPagePath.formsList(language) ||
                        location.pathname ===
                          `${routeService.getPagePath.formsList(language)}/`) &&
                      !isApprover &&
                      !isCSV
                    ) {
                      searchForm.customConditional =
                        "show = data.searchHome && data.searchHome.search && data.searchHome.search!==undefined?data.searchHome.search:false;";
                      searchForm.hidden = true;
                      searchHome.hidden = false;
                      frmDateFilter.hidden = true;
                      toDateFilter.hidden = true;
                      toDateFilter.customConditional = "show =false;";
                      frmDateFilter.customConditional = "show =false;";

                      eachComponent(
                        this.webform._form.components,
                        (component) => {
                          if (
                            component.key === "date" ||
                            component.key === "line" ||
                            component.key === "shift" ||
                            component.key === "plant"
                          ) {
                            component.hidden = false;
                          }
                        }
                      );
                    }
                    if (
                      location.pathname.includes("/submission") &&
                      !location.pathname.includes("/submission/") &&
                      (isApprover === undefined || !isApprover) &&
                      (isCSV === undefined || !isCSV)
                    ) {
                      searchForm.customConditional =
                        "show = data.searchHome && data.searchHome.search && data.searchHome.search!==undefined?data.searchHome.search:false;";
                      searchForm.hidden = true;
                      searchHome.hidden = false;
                      frmDateFilter.hidden = true;
                      toDateFilter.hidden = true;
                      toDateFilter.customConditional = "show =false;";
                      frmDateFilter.customConditional = "show =false;";

                      if (
                        formId !== "" &&
                        formId !== undefined &&
                        Forms.FoodSafety.id.includes(formId)
                      ) {
                        frmDateFilter.hidden = false;
                        toDateFilter.hidden = false;
                        toDateFilter.customConditional = "";
                        frmDateFilter.customConditional = "";
                      }

                      eachComponent(
                        this.webform._form.components,
                        (component) => {
                          if (
                            component.key !== "searchHome" &&
                            component.key !== "searchForm" &&
                            component.key !== "frmDateFilter" &&
                            component.key !== "toDateFilter"
                          ) {
                            component.disabled = true;
                          }
                        }
                      );
                    }
                    if (
                      location.pathname.includes("/submission/") ||
                      (location.pathname.includes("/form/") &&
                        !location.pathname.includes("/submission"))
                    ) {
                      searchHome.hidden = true;
                      frmDateFilter.customConditional = "show = false;";

                      toDateFilter.customConditional = "show = false;";

                      searchForm.customConditional = "show = false;";

                      eachComponent(
                        this.webform._form.components,
                        (component) => {
                          if (
                            component.key === "date" ||
                            component.key === "line" ||
                            component.key === "shift" ||
                            component.key === "plant"
                          ) {
                            component.hidden = false;
                            component.customConditional = "";
                          }
                        }
                      );
                    }
                    if (
                      (isApprover !== undefined && isApprover) ||
                      (isCSV !== undefined && isCSV)
                    ) {
                      this.webform._form.properties.isHidden = true;
                    }
                    eachComponent(
                      this.webform._form.components,
                      (component) => {
                        if (
                          component.properties &&
                          component.properties.hideIfCsv
                        ) {
                          component.hidden = isCSV;
                        }
                        if (
                          component.properties &&
                          component.properties.hideIfApprover
                        ) {
                          component.hidden = isApprover;
                        }
                      }
                    );

                    this.webform.rebuild();
                  });
                }
              }}
              options={{
                ...{
                  template: "bootstrap",
                  iconset: "fa",
                  language: `${language}`,
                },
                ...options,
              }}
              areFilters={true}
            />
          ) : (
            <div className="rt-sec">
              <div className="rt rt-4"></div>
              <div className="rt rt-4"></div>
              <div className="rt rt-4"></div>
              <div className="rt rt-4"></div>
              <div className="clearfix"></div>
            </div>
          )}
        </div>
        <Switch>
          <Route
            exact
            path={routeService.getPagePath.formsList(language)}
            component={List}
          />
          <Route
            path={routeService.getPagePath.form(language, ":formId")}
            component={Item}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageParams: selectRoot("languageParams", state),
    auth: selectRoot("auth", state),
    headerFilterForm: selectRoot("headerFilterForm", state),
    userForms: selectRoot("userForms", state),
    formInformations: selectRoot("formInformations", state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFiltersChange: (filters) => {
      if (
        filters.toDate === undefined &&
        filters.fromDate === undefined &&
        filters.date !== undefined &&
        filters.date !== ""
      ) {
        filters.toDate = filters.date;
        filters.fromDate = filters.date;
      }
      filters.trainerForm = "";
      window.sessionStorage.setItem("filters", JSON.stringify(filters));
      dispatch(setFiltersValue(filters));
    },
    getHeaderFilterForm: (cb) =>
      dispatch(getForm("headerFilterForm", Forms.HeaderFilter.id, cb)),
    getUserAccess: (gpid, email, user, cb) =>
      dispatch(getAccessByGpid(gpid, email, user, cb)),
    getUserForms: (gpid, email, user, filters, page, query = {}) =>
      dispatch(getUserForms(gpid, email, user, filters, page, query)),
    getUserShift: (gpid, cb) => dispatch(getShift(gpid, cb)),
    getUserLine: (gpid, cb) => dispatch(getLine(gpid, cb)),
    getLanguageTranslations: (cb) => {
      dispatch(getTranslations(cb));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
