import { useLocation } from "react-router";

export function useRouteLanguage() {
  const location = useLocation();

  const splittedPathnames = location.pathname.split("/");
  if (splittedPathnames.length > 1) {
    return splittedPathnames[1];
  }

  return null;
}
