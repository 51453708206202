/* eslint no-useless-computed-key: 0 */
/* eslint array-callback-return: 0 */
/* eslint no-undef: 0 */
/* eslint no-unused-vars:0 */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  selectError,
  Errors,
  getSubmissions,
  clearFormError,
  clearSubmissionError,
} from "react-formio";
import PropTypes from "prop-types";
import { eachComponent } from "formiojs/utils/formUtils";
import { push } from "connected-react-router";
import moment from "moment-timezone";
import { isEqual, isEmpty } from "lodash";
import {
  formLevelValidations,
  setShiftLevelRecord,
  formCompletionStatus,
  formPrePopulate,
  reportCalculations,
  colorComponent,
  rateConfigComponent,
  getCalculatedKg,
  isGpidSpecific,
  isGpidSpecificApprover,
  showShiftBasedFields,
} from "./helper";
import {
  signatureOffline,
  getSubmissionDefaultQuery,
  mergeAndGetLanguageTranslations,
} from "../../../utils";
import { i18next } from "../../../i18n";
import Form from "../../../containers/Form";

import Loading from "../../../containers/Loading";
import BottomControlButtons from "../../../containers/BottomControlButtons/BottomControlButtons";
import ImportantInstructions from "../../../containers/ImportantInstructions/ImportantInstructions";

import QuaggaBarCode from "../../../containers/BarcodePicker/QuaggaBarCode";

import routeService from "../../../services/routeService";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Forms,
  UserMessages,
  PageTexts,
  AppConfig,
  OfflinePluginConfig,
  Resources,
} from "../../../config";
import Message from "../../../containers/Message";
import FormioOfflineProject from "formio-plugin-offline";
import {
  finishSubmissionsDequeuing,
  startSubmissionsDequeuing,
} from "../Actions";

const offlinePlugin = FormioOfflineProject.getInstance(
  AppConfig.projectUrl,
  AppConfig.projectUrl,
  OfflinePluginConfig
);

const isOfflineSubmissions = (formId, filters) =>
  offlinePlugin.submissionQueue.some((offlineSubmission) => {
    return (
      (offlineSubmission.request.data !== null &&
        offlineSubmission.request.form === formId &&
        offlineSubmission.request.data.data.line === filters.line &&
        parseInt(offlineSubmission.request.data.data.plant) === filters.plant &&
        offlineSubmission.request.data.data.date.includes(
          filters.date.split("T")[0]
        )) ||
      // Check if offline signature belongs this form
      (offlineSubmission.request.form === Forms.Signature.id &&
        offlineSubmission.request.data.data.formId === formId)
    );
  });

const View = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      secondaryButtonPath: "",
      isTotalLoaded: false,
      isPrePopulated: false,
      serverSubmissionLoaded: false,
      draftSubmission: { isDraftSubmission: true, invalidData: [] },
      currentPage: 0,
      confirmSubmit: false,
      firstChange: true,
      formMessage: {
        text: "",
        type: "",
      },
      isButtonLoading: false,
      fieldUpdated: false,
      isFinishedDequeuing: false,
      ipAddress: "",
      statusBar: {},
      csvArrayList: {},
      csvArrayData: {},
      csvArrayList1: {},
      csvArrayData1: {},
      showLoader: false,
      countData: 1,
      errorMsg: "",
      errorMsg1: "",
      totalCount: 0,
      insertCount: 0,
      dropdownSupplier: {},
      dropdownDepartment: {},
      dropdownShift: {},
    };
    this.showLoaderCommon = false;
    this.dropdownTrainingName = {};
    this.csvArrayDataCore8 = {};
    this.csvArrayListCore8 = {};
    this.totalCountCore8 = 0;
    this.countDataCore8 = 0;
    this.errorMsg = "";
    this.errorMsg1 = "";
    this.gpidInfoArray = {};
    this.supplierEmpty = false;
    this.departmentEmpty = false;
    this.shiftEmpty = false;
    this.trainingNameEmpty = false;
    this.trainingGPIDEmpty = false;

    this.dropdownobservation = {};
    this.dropdownDepartment = {};
    this.dropdownArea = {};
    this.dropdownTypeAudit = {};
    this.dropdownShiftName = {};
    this.dropdownstatus = {};
    this.dropdownReportDep = {};
    this.observationEmpty = false;
    this.areaEmpty = false;
    this.typeAuditEmpty = false;
    this.reportDepEmpty = false;
    this.shiftNameEmpty = false;
    this.issueStatusEmpty = false;
    this.dropdownRate = {};
    this.dropdownTypeScope = {};
    this.dropdownRepeated = {};
    this.dataReturn = "";
    this.standardTitleList = {};
    this.auditCategory = "";
    this.standardNo = "";
    this.clauseInfo = {};
    this.emptyReturn = 0;
    this.observationNull = false;
    this.observationClassNull = false;
    this.departmentNull = false;
    this.areaNameNull = false;
    this.auditTypeNull = false;
    this.ShiftNameNull = false;
    this.reporterNull = false;
    this.issueStatusNull = false;
    this.reportingDepartmentNull = false;
    this.findingNull = false;
    this.auditRateNull = false;
    this.auditScopeNull = false;
    this.repeatedNull = false;
    this.auditorNull = false;
    this.auditeeNameNull = false;
    this.clauseNull = false;
    this.trainingNameNull = false;
    this.traineeGPIDNull = false;
    this.hr57NameNull = false;
    this.hr57commentsNull = false;
    this.hr57supplierNull = false;
    this.hr57departmentNull = false;
    this.hr57shiftperiodNull = false;
    this.hr57idNull = false;
  }

  componentDidMount() {
    const { resetFormErrors } = this.props;

    if (!offlinePlugin.submissionQueueLength()) {
      this.setState({ isFinishedDequeuing: true });
    }

    resetFormErrors();

    window.addEventListener("online", this.onlineListener);

    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const {
      form: { form },
      formInformations,
    } = this.props;
    /* EP-01 seasoning and size value set to empty onload*/
    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (
            (component.properties &&
              component.properties.isSize &&
              component.properties.isSize != undefined) ||
            (component.properties &&
              component.properties.isSeasoning &&
              component.properties.isSeasoning != undefined)
          ) {
            component.data.values = [];
          }
        }
      );
    }
  }

  componentWillUnmount() {
    delete this.webform;

    window.removeEventListener("online", this.onlineListener);
  }

  getIpAddress = () => {
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ipAddress: data.ip });
      })
      .catch(() => {});
  };

  onlineListener = () => {
    const {
      location,
      match: {
        params: { formId },
      },
      filters,
    } = this.props;

    let preventPageReload = true;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.display === "commonForShift"
    ) {
      preventPageReload = false;
    }

    // If commonForShift page then run dequeueOfflineSubmissions
    const isOfflineSubmissionsForForm = isOfflineSubmissions(formId, filters);
    const isAbleToProcessQueue =
      offlinePlugin.submissionQueueLength() &&
      !offlinePlugin.dequeuing &&
      isOfflineSubmissionsForForm;

    if (isAbleToProcessQueue) {
      this.dequeueOfflineSubmissions(formId, preventPageReload);
    }
  };

  dequeueOfflineSubmissions = (formId, preventPageReload = true) => {
    this.props.startSubmissionDequeuing();

    offlinePlugin
      .dequeueSubmissions(true, 0, true, (req) => {
        return (
          (req.data && req.type === "submission") ||
          (req.data && req.data.data) ||
          req.method === "DELETE"
        );
      })
      .finally(() => {
        this.props.finishSubmissionDequeuing();
        this.setState({ isFinishedDequeuing: true });
        if (!preventPageReload) {
          window.location.reload();
        } else {
          this.props.goToFormPreview();
        }
      });
  };

  onChangeHandler = (change) => {
    const {
      location,
      filters,
      auth: { user },
      form: { form },
      languageParams: { language },
      userForms,
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const date = moment(filters.date).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]");
    const decimalIssue = false;
    if (change.changed) {
      delete change.changed.flags;
      delete change.changed.instance;
    }
    if (!change._id && this.state.submission._id) {
      change._id = this.state.submission._id;
      if (this.webform) {
        this.webform.submission._id = this.state.submission._id;
      }
    }

    // Update submission of webform instance to save it for draft
    if (this.webform !== undefined) {
      this.webform.submission.metadata = {
        viewPage: currentPage,
        userName: user.data.name,
      };
      if (!(form && form.type === "resource")) {
        this.webform.submission.data.date = date;
        this.webform.submission.data.shift = filters.shift;
        this.webform.submission.data.line = filters.line;
        this.webform.submission.data.plant = `${filters.plant}`;
        this.webform.submission.data.gpid = user.data.gpid;
        if (form.properties && form.properties.isTraining) {
          this.webform.submission.data.trainer = user.data.name;
        }
      }
    }

    if (this.state.firstChange && !change.changed) {
      this.setState((prevState) => {
        return {
          submission: {
            ...change,
            data: {
              ...change.data,
              ...filters,
              plant: `${filters.plant}`,
            },
          },
          firstChange: false,
        };
      });
    }

    if (change.changed) {
      let draftSubmission = this.state.draftSubmission;

      if (
        this.webform &&
        !change._id &&
        !this.state.isButtonLoading &&
        change.changed.value
      ) {
        this.formLoadWait();
      } else if (this.state.isButtonLoading) {
        // Reset button after data change (No hourly level forms)
        this.setState({ isButtonLoading: false });
      }

      if (change._id && !this.state.submission._id) {
        if (this.webform) {
          // Prevent draft when state submission doesn't have id and set it form change
          this.webform.options.saveDraft = false;
          this.webform.draftEnabled = false;
          this.webform.savingDraft = true;
        }
      }

      if (
        this.isUniqueInWizardFormSubmissions() ||
        this.isUniqueInFormSubmissions()
      ) {
        const invalidValues = this.getInvalidUniqueInSubmissionsValues(change);

        if (invalidValues.length === 0) {
          if (
            (!this.webform.savingDraft ||
              this.state.draftSubmission.invalidData.length > 0) &&
            !(!this.state.submission._id && !change.changed.value)
          ) {
            this.webform.draftEnabled = true;
            this.webform.savingDraft = false;

            this.webform.saveDraft();
          }
          draftSubmission = { isDraftSubmission: true, invalidData: [] };
        } else {
          this.webform.options.saveDraft = false;
          this.webform.draftEnabled = false;
          this.webform.savingDraft = true;
          draftSubmission = {
            isDraftSubmission: false,
            invalidData: invalidValues,
          };
        }
        this.setState({ draftSubmission: draftSubmission });
      } else {
        /*/ Added check to prevent draft for RBS-03   reportLevel !== 'dayLevel'
          For now commenting out autosave  for all shift level records till formio platform fix */
        if (this.webform !== undefined && !this.webform.options.saveDraft) {
          this.webform.options.saveDraft = true;
          this.webform.draftEnabled = true;
          this.webform.savingDraft = false;
          if (
            !this.webform.component.properties ||
            (this.webform.component.properties &&
              this.webform.component.properties.display !== "commonForShift")
          ) {
            this.webform.saveDraft();
          }
        }
      }

      /**SKU-Flavor table  look up  code starts
       * Add API key for the component  , based on which SKU/Flavor value is to be fetched from master data
       * Eg PK-33 Product Type filed is the SKU dropdown, so add APIKEY for the select component
       *  as "skuFlavorSource=productType" --productType is the key in SKU master table based on which values are fetched
       * Add APIKEY "masterData="SkuList/FlavorList" , depending on the table that form needs to refer.
       * The fields to be populated, based on the sku value have to be given the APIKey "skuFlavorTarget"
       * and the value to be the corresponding column from the masterTable
       * In PK-33 targetWeight is the field which need to be populated and the master data column for same is tgWeight
       * "skuFlavorTarget=tgWeight"
       * API key productChange is to identify change happen on product deropdown
       * API key isSeasoning & isSize is to identify seasoning and size dropdowns
       */
      if (
        change.changed.component.properties &&
        change.changed.component.properties.skuFlavorSource &&
        change.changed.component.properties.masterData
      ) {
        let seasoning = [];
        let sizes = [];
        const filterValues =
          JSON.parse(window.sessionStorage.getItem("filters")) || {};
        const plantId =
          filterValues.plant !== undefined && filterValues.plant !== ""
            ? filterValues.plant
            : auth.user.data.plantId;
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.properties.skuFlavorTarget &&
            component.properties.masterData &&
            change.changed.component.properties.masterData ===
              component.properties.masterData &&
            (component.properties.skuFlavorOrigin === undefined ||
              component.properties.skuFlavorOrigin === "" ||
              (component.properties.skuFlavorOrigin &&
                change.changed.component.key ===
                  component.properties.skuFlavorOrigin))
          ) {
            if (component.type != "select") {
              this.webform.submission.data[component.key] =
                component.type === "number" ? 0 : "";
            }
          }
        });
        eachComponent(this.webform.component.components, (component) => {
          const changeValue =
            change.data[change.changed.component.key] !== undefined
              ? change.data[change.changed.component.key]
              : change.changed.value;
          if (
            component.properties &&
            (component.properties.isSize ||
              component.properties.isSeasoning ||
              component.properties.isSizeCases) &&
            change.changed.component.properties.productChange
          ) {
            this.webform.submission.data[component.key] = "";
          }
          if (component.properties && component.properties.skuFlavorSource) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(filters.plant) &&
                  (item.data[component.properties.skuFlavorSource] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) === item.data[component.properties.skuFlavorSource])) &&
                  component.key === change.changed.component.key
              )
              .map((item) => {
                if (
                  component.properties &&
                  component.properties.isTargetSelect &&
                  change.changed.component.properties.productChange &&
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(plantId)
                ) {
                  seasoning.push(
                    item.data[component.properties.isTargetSelect]
                  );
                }
                eachComponent(
                  this.webform.component.components,
                  (component) => {
                    if (
                      component.properties &&
                      component.properties.skuFlavorTarget &&
                      component.properties.masterData &&
                      change.changed.component.properties.masterData ===
                        component.properties.masterData &&
                      (component.properties.skuFlavorOrigin === undefined ||
                        component.properties.skuFlavorOrigin === "" ||
                        (component.properties.skuFlavorOrigin &&
                          change.changed.component.key ===
                            component.properties.skuFlavorOrigin))
                    ) {
                      /**887686- Mourad requirement to read translated data for item name and transaction name from solid recources
                       * Created field in table with the fieldname-lang format  eg: itemName-ar transactionName-ar and mapped acrodingly
                       */
                      const translatedField =
                        component.properties.skuFlavorTarget + "-" + language;
                      if (component.type != "select") {
                        this.webform.submission.data[component.key] =
                          item.data[translatedField] !== undefined &&
                          item.data[translatedField] !== ""
                            ? item.data[translatedField]
                            : item.data[component.properties.skuFlavorTarget]
                            ? item.data[component.properties.skuFlavorTarget]
                            : component.type === "number"
                            ? 0
                            : "";
                      }
                    }
                  }
                );
              });
          }
          if (
            component.properties &&
            component.properties.skuListTarget &&
            component.properties.masterData &&
            component.properties.isSize &&
            change.changed.component.properties.productChange &&
            formInformations[component.properties.masterData] !== undefined
          ) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  (item.data[component.properties.skuListTarget] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) === item.data[component.properties.skuListTarget]))
              )
              .map((item) => {
                if (
                  component.properties.isSize &&
                  plantId === item.data["plantId"][0]
                ) {
                  sizes.push(item.data[component.properties.isSize]);
                }
              });
          }
        });
        sizes = sizes.filter((element, index, self) => {
          return index == self.indexOf(element);
        });
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.type === "select" &&
            change.changed.component.properties.productChange &&
            (component.properties.isSeasoning || component.properties.isSize)
          ) {
            this.webform.rebuild();
            if (
              component.properties &&
              component.type === "select" &&
              component.properties.isSeasoning
            ) {
              component.data.values = [];
              let selectedComp = this.webform.getComponent(component.key);
              selectedComp.component.data.values = [];
              seasoning.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
                selectedComp.component.data.values.push({
                  label: data,
                  value: data,
                });
              });
            }
            if (
              component.properties &&
              component.type === "select" &&
              component.properties.isSize
            ) {
              component.data.values = [];
              let selectedComp = this.webform.getComponent(component.key);
              selectedComp.component.data.values = [];
              sizes.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
                selectedComp.component.data.values.push({
                  label: data,
                  value: data,
                });
              });
            }
          }
        });
      }
      if (
        change.changed.component.properties &&
        change.changed.component.properties.skuFlavorSource2 &&
        change.changed.component.properties.masterData
      ) {
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.properties.skuFlavorTarget2 &&
            component.properties.masterData &&
            change.changed.component.properties.masterData ===
              component.properties.masterData &&
            (component.properties.skuFlavorOrigin === undefined ||
              component.properties.skuFlavorOrigin === "" ||
              (component.properties.skuFlavorOrigin &&
                change.changed.component.key ===
                  component.properties.skuFlavorOrigin))
          ) {
            if (component.type != "select") {
              this.webform.submission.data[component.key] =
                component.type === "number" ? 0 : "";
            }
          }
        });
        eachComponent(this.webform.component.components, (component) => {
          const changeValue =
            change.data[change.changed.component.key] !== undefined
              ? change.data[change.changed.component.key]
              : change.changed.value;
          if (component.properties && component.properties.skuFlavorSource2) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(filters.plant) &&
                  (item.data[component.properties.skuFlavorSource2] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) ===
                        item.data[component.properties.skuFlavorSource2])) &&
                  component.key === change.changed.component.key
              )
              .map((item) => {
                eachComponent(
                  this.webform.component.components,
                  (component) => {
                    if (
                      component.properties &&
                      component.properties.skuFlavorTarget2 &&
                      component.properties.masterData &&
                      change.changed.component.properties.masterData ===
                        component.properties.masterData &&
                      (component.properties.skuFlavorOrigin === undefined ||
                        component.properties.skuFlavorOrigin === "" ||
                        (component.properties.skuFlavorOrigin &&
                          change.changed.component.key ===
                            component.properties.skuFlavorOrigin))
                    ) {
                      /**887686- Mourad requirement to read translated data for item name and transaction name from solid recources
                       * Created field in table with the fieldname-lang format  eg: itemName-ar transactionName-ar and mapped acrodingly
                       */
                      const translatedField =
                        component.properties.skuFlavorTarget2 + "-" + language;

                      if (component.type != "select") {
                        this.webform.submission.data[component.key] =
                          item.data[translatedField] !== undefined &&
                          item.data[translatedField] !== ""
                            ? item.data[translatedField]
                            : item.data[component.properties.skuFlavorTarget2]
                            ? item.data[component.properties.skuFlavorTarget2]
                            : component.type === "number"
                            ? 0
                            : "";
                      }
                    }
                  }
                );
              });
          }
        });
      }
      //Changes related to Paperless India - This code handles more than 2 flavour drop down in a form
      //Start
      if (
        change.changed.component.properties &&
        change.changed.component.properties.skuFlavorSource1 &&
        change.changed.component.properties.masterData
      ) {
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.properties.skuFlavorTarget1 &&
            component.properties.masterData &&
            change.changed.component.properties.masterData ===
              component.properties.masterData &&
            (component.properties.skuFlavorOrigin === undefined ||
              component.properties.skuFlavorOrigin === "" ||
              (component.properties.skuFlavorOrigin &&
                change.changed.component.key ===
                  component.properties.skuFlavorOrigin))
          ) {
            if (component.type != "select") {
              this.webform.submission.data[component.key] =
                component.type === "number" ? 0 : "";
            }
          }
        });
        eachComponent(this.webform.component.components, (component) => {
          const changeValue =
            change.data[change.changed.component.key] !== undefined
              ? change.data[change.changed.component.key]
              : change.changed.value;
          if (component.properties && component.properties.skuFlavorSource1) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(filters.plant) &&
                  (item.data[component.properties.skuFlavorSource1] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) ===
                        item.data[component.properties.skuFlavorSource1])) &&
                  component.key === change.changed.component.key
              )
              .map((item) => {
                eachComponent(
                  this.webform.component.components,
                  (component) => {
                    if (
                      component.properties &&
                      component.properties.skuFlavorTarget1 &&
                      component.properties.masterData &&
                      change.changed.component.properties.masterData ===
                        component.properties.masterData &&
                      (component.properties.skuFlavorOrigin === undefined ||
                        component.properties.skuFlavorOrigin === "" ||
                        (component.properties.skuFlavorOrigin &&
                          change.changed.component.key ===
                            component.properties.skuFlavorOrigin))
                    ) {
                      /**887686- Mourad requirement to read translated data for item name and transaction name from solid recources
                       * Created field in table with the fieldname-lang format  eg: itemName-ar transactionName-ar and mapped acrodingly
                       */
                      const translatedField =
                        component.properties.skuFlavorTarget1 + "-" + language;

                      if (component.type != "select") {
                        this.webform.submission.data[component.key] =
                          item.data[translatedField] !== undefined &&
                          item.data[translatedField] !== ""
                            ? item.data[translatedField]
                            : item.data[component.properties.skuFlavorTarget1]
                            ? item.data[component.properties.skuFlavorTarget1]
                            : component.type === "number"
                            ? 0
                            : "";
                      }
                    }
                  }
                );
              });
          }
        });
      }
      //End

      //Changes related to Paperless India - This code handles more than 2 flavour drop down in a form
      //Start
      if (
        change.changed.component.properties &&
        change.changed.component.properties.skuFlavorSource3 &&
        change.changed.component.properties.masterData
      ) {
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.properties.skuFlavorTarget3 &&
            component.properties.masterData &&
            change.changed.component.properties.masterData ===
              component.properties.masterData &&
            (component.properties.skuFlavorOrigin === undefined ||
              component.properties.skuFlavorOrigin === "" ||
              (component.properties.skuFlavorOrigin &&
                change.changed.component.key ===
                  component.properties.skuFlavorOrigin))
          ) {
            if (component.type != "select") {
              this.webform.submission.data[component.key] =
                component.type === "number" ? 0 : "";
            }
          }
        });
        eachComponent(this.webform.component.components, (component) => {
          const changeValue =
            change.data[change.changed.component.key] !== undefined
              ? change.data[change.changed.component.key]
              : change.changed.value;
          if (component.properties && component.properties.skuFlavorSource3) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(filters.plant) &&
                  (item.data[component.properties.skuFlavorSource3] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) ===
                        item.data[component.properties.skuFlavorSource3])) &&
                  component.key === change.changed.component.key
              )
              .map((item) => {
                eachComponent(
                  this.webform.component.components,
                  (component) => {
                    if (
                      component.properties &&
                      component.properties.skuFlavorTarget3 &&
                      component.properties.masterData &&
                      change.changed.component.properties.masterData ===
                        component.properties.masterData &&
                      (component.properties.skuFlavorOrigin === undefined ||
                        component.properties.skuFlavorOrigin === "" ||
                        (component.properties.skuFlavorOrigin &&
                          change.changed.component.key ===
                            component.properties.skuFlavorOrigin))
                    ) {
                      /**887686- Mourad requirement to read translated data for item name and transaction name from solid recources
                       * Created field in table with the fieldname-lang format  eg: itemName-ar transactionName-ar and mapped acrodingly
                       */
                      const translatedField =
                        component.properties.skuFlavorTarget3 + "-" + language;

                      if (component.type != "select") {
                        this.webform.submission.data[component.key] =
                          item.data[translatedField] !== undefined &&
                          item.data[translatedField] !== ""
                            ? item.data[translatedField]
                            : item.data[component.properties.skuFlavorTarget3]
                            ? item.data[component.properties.skuFlavorTarget3]
                            : component.type === "number"
                            ? 0
                            : "";
                      }
                    }
                  }
                );
              });
          }
        });
      }
      //End
      if (this.webform && this.webform.component) {
        const statusBar = formCompletionStatus(
          this.webform.component,
          change,
          this.webform
        );
        if (statusBar.showProgress) {
          this.setState({ statusBar: statusBar });
        }
      }

      const changeData = { ...change.data };
      let stateData = { ...this.state.submission.data };
      delete stateData.date;
      delete changeData.date;
      changeData.approvalStatus = stateData.approvalStatus;
      changeData.fromDate = stateData.fromDate;
      changeData.toDate = stateData.toDate;
      changeData.apprLine = stateData.apprLine;
      changeData.searchHome = stateData.searchHome;
      changeData.frmDateFilter = stateData.frmDateFilter;
      changeData.toDateFilter = stateData.toDateFilter;
      changeData.searchForm = stateData.searchForm;
      changeData.formNameCsv = stateData.formNameCsv;
      changeData.submitter = stateData.submitter;
      changeData.formName = stateData.formName;
      changeData.formShift = stateData.formShift;

      stateData = {
        ...changeData,
        ...stateData,
      };

      if (
        //  !isEqual(changeData, stateData) &&
        //  change.changed.value !== stateData[ change.changed.component.key ]  &&
        (change.changed.component &&
          change.changed.component.key !== "date" &&
          change.changed.component.key !== "gpid" &&
          change.changed.component.key !== "shift" &&
          change.changed.component.key !== "line" &&
          change.changed.component.key !== "plant") ||
        (change.changed.component &&
          change.changed.component.key === "date" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "shift" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "line" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "gpid" &&
          change.changed.component.hidden !== true) ||
        (change.changed.component &&
          change.changed.component.key === "plant" &&
          change.changed.value !== "")
      ) {
        let state = !this.state.submission.state
          ? ""
          : this.state.submission.state === "submitted"
          ? "submitted"
          : "draft";
        if (
          (change.changed.value || change.changed.value === "") &&
          !change.changed.component.hidden &&
          !change.changed.component.calculateValue &&
          change.changed.component.properties &&
          change.changed.component.properties.totalFor === undefined &&
          change.changed.component.properties.countCriteria === undefined
        ) {
          state = "draft";
        }

        if (
          change.data[change.changed.component.key] === "" ||
          change.data[change.changed.component.key] === undefined ||
          change.data[change.changed.component.key] === null ||
          change.data[change.changed.component.key] === 0
        ) {
          if (change.data[change.changed.component.key] === 0) {
            change.data[change.changed.component.key] = 0;
          } else {
            change.data[change.changed.component.key] = "";
            if (change.changed.component.type === "number") {
              change.data[change.changed.component.key] = undefined;
            }
          }
        }

        if (
          this.webform &&
          this.webform.component &&
          this.webform.component.properties &&
          this.webform.component.properties.webApiPopulate
        ) {
          this.webform.submission.data["ipAddress"] = this.state.ipAddress;
        }

        const rateConfig = rateConfigComponent(
          this.webform,
          change,
          formInformations.rateConfigList
        );
        if (
          rateConfig != "" &&
          rateConfig !== undefined &&
          change.changed &&
          change.changed.component &&
          change.changed.component.properties.colorCodeSrc === undefined
        ) {
          // eslint-disable-next-line no-return-assign
          Object.entries(rateConfig).forEach(
            ([key, value]) => (change.data[key] = value)
          );
        }

        if (
          change &&
          change.changed &&
          change.changed.component &&
          change.changed.component.type === "number" &&
          change.changed.value &&
          this.state &&
          this.state.submission &&
          this.state.submission.data
        ) {
          let prevValue =
            this.state.submission.data[change.changed.component.key];
          if (Number.isInteger(prevValue) || prevValue === 0) {
            prevValue += ".";
            return prevValue;
          }
        }

        /**SKU-Flavor table  look up  code ends */
        if (
          this.webform &&
          this.webform.component.properties &&
          this.webform.component.properties.utilityForm
        ) {
          if (
            change.changed.value &&
            this.state.submission.data &&
            change.changed.value !==
              this.state.submission.data[change.changed.component.key]
          ) {
            this.setState((prevState) => {
              return {
                isTotalLoaded: false,
                submission: {
                  ...prevState.submission,
                  ...change,
                  state,
                  data: {
                    ...prevState.submission.data,
                    ...change.data,
                  },
                },
                fieldUpdated: true,
                confirmSubmit: false,
              };
            });
          }
        } else {
          this.setState((prevState) => {
            return {
              isTotalLoaded: false,
              submission: {
                ...prevState.submission,
                ...change,
                state,
                data: {
                  ...prevState.submission.data,
                  ...change.data,
                },
              },
              fieldUpdated: true,
              confirmSubmit: false,
            };
          });
        }
      }

      let viewOnlyTab = false;
      if (
        this.webform &&
        this.webform.component.properties &&
        this.webform.component.properties.viewOnly === "true"
      ) {
        viewOnlyTab = true;
      }
      let informAboutDraft = false;
      //Check to prevent draft error message if no changes actually happenined in the tab
      //Check added to disable draft message in case default date is set in the component
      const pageComponents = [];
      const formComponents = ["gpid", "date", "line", "plant", "shift"];
      const formComp = form.components[parseInt(currentPage)];

      eachComponent(formComp.components, (component) => {
        pageComponents.push(component.key);
      });
      if (
        this.state.submission._id &&
        !viewOnlyTab &&
        change.changed &&
        !isEqual(changeData, stateData) &&
        change.changed.value &&
        change.changed.value !== null &&
        change.changed.value !== "" &&
        change.changed.component.calculateValue === "" &&
        pageComponents.includes(change.changed.component.key) &&
        !formComponents.includes(change.changed.component.key)
      ) {
        informAboutDraft = true;
      }

      this.setFormMessage(informAboutDraft, false, decimalIssue);
      // To show total based on the filter value (i.e PC-12)
      if (
        change.changed &&
        change.changed.value &&
        change.changed.component &&
        change.changed.component.properties &&
        change.changed.component.properties.filterSubmissionRequired
      ) {
        this.setState({ isTotalLoaded: false });
      }
    }
  };

  onComponentChangeHandler = () => {
    const {
      match: {
        params: { formId },
      },
      languageParams: { language },
      location,
      approverFlowCheck,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);

    if (this.webform !== undefined && this.state.currentPage !== currentPage) {
      this.getBackButtonPath(language, formId, currentPage, approverFlowCheck);
    }
  };

  formLoadWait = () => {
    this.setState({
      isButtonLoading: true,
    });

    this.interval = setTimeout(() => {
      this.setState({
        isButtonLoading: false,
      });
    }, 2000); //this will enable button after 2 seconds you can change time here.
  };

  isUniqueInWizardFormSubmissions() {
    return (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.uniqueInSubmissions
    );
  }

  isUniqueInFormSubmissions() {
    return (
      this.webform &&
      this.webform.form.properties &&
      this.webform.form.properties.uniqueInSubmissions
    );
  }

  getUniqueInSubmissionsKeys() {
    let uniqueKeysString;

    if (this.isUniqueInWizardFormSubmissions()) {
      uniqueKeysString = this.webform.component.properties.uniqueInSubmissions;
    }
    if (this.isUniqueInFormSubmissions()) {
      uniqueKeysString = this.webform.form.properties.uniqueInSubmissions;
    }

    return uniqueKeysString.length ? uniqueKeysString.split(",") : [];
  }

  getRequiredFields() {
    let requiredKeysString = [];

    if (this.webform !== undefined) {
      if (this.webform.component.properties) {
        requiredKeysString =
          this.webform.component.properties.requiredInSubmission;
      } else if (this.webform.form.properties) {
        requiredKeysString = this.webform.form.properties.requiredInSubmission;
      }
    }

    return requiredKeysString && requiredKeysString.length
      ? requiredKeysString.split(",")
      : [];
  }

  getInvalidInSubmissionValues(newSubmission) {
    const requiredValues = this.getRequiredFields();

    return requiredValues.filter((uniqueValue) => {
      return !newSubmission.data[uniqueValue];
    });
  }

  getInvalidUniqueInSubmissionsValues(newSubmission) {
    const {
      submissions: { submissions },
    } = this.props;
    const uniqueValues = this.getUniqueInSubmissionsKeys();

    return uniqueValues.filter((uniqueValue) =>
      submissions.some((sub) => {
        const isValuesEqual =
          sub.data[uniqueValue] === newSubmission.data[uniqueValue];

        return (
          sub.data[uniqueValue] &&
          isValuesEqual &&
          sub._id !== newSubmission._id &&
          sub.metadata &&
          newSubmission.metadata &&
          sub.metadata.viewPage === newSubmission.metadata.viewPage
        );
      })
    );
  }

  onSaveDraftBegin = () => this.setState({ isButtonLoading: true });
  onSaveDraft = (submission = {}) => {
    if (!submission._id) {
      return this.setState({ isButtonLoading: false });
    }

    if (!this.state.submission || !this.state.submission._id) {
      return this.setState({
        submission: {
          ...this.state.submission,
          _id: submission._id,
        },
        isButtonLoading: false,
      });
    }

    this.setState({ isButtonLoading: false });
  };
  onSubmitHandler = (closeForm) => {
    const {
      onSubmit,
      filters,
      location,
      auth: { user },
      form: { form },
    } = this.props;
    const { submission } = this.state;
    const date = moment(filters.date).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]");
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    let preventRedirectAfterSubmission = false;

    let submissionObject = {};
    if (form && form.type === "resource") {
      submissionObject = {
        ...submission,
        data: {
          ...submission.data,
        },
        state: "submitted",
      };
    } else {
      submissionObject = {
        ...submission,
        data: {
          ...submission.data,
          line: filters.line,
          plant: `${filters.plant}`,
          shift: filters.shift,
          date: date,
          gpid: user.data.gpid,
        },
        state: "submitted",
      };
    }

    let returningPage = currentPage;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.display === "commonForShift"
    ) {
      returningPage = 0;
      preventRedirectAfterSubmission = parseInt(currentPage) === 0;
    }

    // Avoid duplicate records on immediate submit after draft
    if (this.webform !== undefined) {
      if (
        this.webform.submission._id &&
        this.webform.submission._id !== this.state.submission._id
      ) {
        this.setState((prevState) => {
          return {
            submission: {
              ...prevState.submission,
              _id: this.webform.submission._id,
            },
          };
        });
        submissionObject._id = this.webform.submission._id;
      }
    }

    // Check for record to have required fields filled
    const invalidValues = this.getInvalidInSubmissionValues(submissionObject);

    if (invalidValues.length !== 0) {
      const formMessage = {
        text: "",
        type: "",
      };

      if (invalidValues.length === 1) {
        formMessage.text = `Field '${invalidValues.join(", ")}' is required.`;
      } else {
        formMessage.text = `Fields ${invalidValues.join(", ")} are required.`;
      }
      formMessage.type = "danger";

      if (!isEqual(formMessage, this.state.formMessage)) {
        window.scrollTo(0, 0);
        this.setState({ formMessage });
      }

      return;
    }

    // Check for record to be unique for selected time slot
    if (
      this.webform &&
      this.webform !== undefined &&
      (this.isUniqueInWizardFormSubmissions() ||
        this.isUniqueInFormSubmissions())
    ) {
      const invalidValuesUniqueInSubmissions =
        this.getInvalidUniqueInSubmissionsValues(submissionObject);

      if (invalidValuesUniqueInSubmissions.length === 0) {
        // Prevent app from creating draft once user press submit
        this.webform.options.saveDraft = false;
        this.webform.draftEnabled = false;
        this.webform.savingDraft = true;
        this.webform.triggerSaveDraft.cancel();

        onSubmit(
          submissionObject,
          returningPage,
          preventRedirectAfterSubmission,
          this.webform,
          closeForm
        );

        this.setState({ confirmSubmit: true, isButtonLoading: true });
      } else {
        this.setState({
          formMessage: {
            text: `Record for selected ${invalidValuesUniqueInSubmissions.join(
              ", "
            )} already exists.`,
            type: "danger",
          },
        });
      }
    } else if (this.webform && this.webform !== undefined) {
      // Prevent app from creating draft once user press submit
      this.webform.options.saveDraft = false;
      this.webform.draftEnabled = false;
      this.webform.savingDraft = true;
      this.webform.triggerSaveDraft.cancel();

      onSubmit(
        submissionObject,
        returningPage,
        preventRedirectAfterSubmission,
        this.webform,
        closeForm
      );
      this.setState(
        { confirmSubmit: true, isButtonLoading: true },
        () => {
          this.setFormMessage(); //added for submit message
          this.formLoadWait();
        },
        window.scrollTo(0, 0)
      );
    }
  };
  onNewSubmitHandler = () => {
    this.onSubmitHandler("close");
  };
  onSubmitForApprovalHandler = () => {
    const { goToSignaturePage } = this.props;

    goToSignaturePage();
  };

  getBackButtonPath = (language, formId, currentPage, approverFlowCheck) => {
    let path = "";

    if (
      this.webform &&
      this.webform.component &&
      this.webform.component.properties &&
      this.webform.component.properties.display &&
      (this.webform.component.properties.display === "commonForShift" ||
        this.webform.component.components[Number.parseFloat(currentPage)]
          .properties.display === "commonForShift")
    ) {
      path = routeService.getPagePath.submission(language, formId);
      /* Check to navigate to submission page rather than preview in case first page is a common for shift page -starts */
      if (
        this.webform &&
        this.webform.components &&
        this.webform.components[0] &&
        this.webform.components[0].component.properties &&
        this.webform.components[0].component.properties.display ===
          "commonForShift"
      ) {
        path = routeService.getPagePath.formPage(language, formId, 0);
      }
      /* Check to navigate to submission page rather than preview in case first page is a common for shift page -ends */

      if (parseInt(currentPage) === 0) {
        path = routeService.getPagePath.formsList(language);
        if (approverFlowCheck && approverFlowCheck.approverView) {
          path = routeService.getPagePath.approver(
            language,
            Forms.Signature.id
          );
        }
      }
    } else {
      path = routeService.getPagePath.submissionPage(
        language,
        formId,
        currentPage
      );
      if (parseInt(currentPage) === 0) {
        path = routeService.getPagePath.submission(language, formId);
      }
    }

    this.setState({
      secondaryButtonPath: path,
      currentPage: currentPage,
    });
  };

  getButtonSettings = () => {
    return {
      showPrevious: false,
      showNext: false,
      showSubmit: false,
      showCancel: false,
    };
  };

  getSubmissionToDisplay() {
    const {
      location,
      submissions: { submissions },
      form: { form },
      previousDayReading,
    } = this.props;
    const { submission, serverSubmissionLoaded } = this.state;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const shiftSel = this.props.filters.shift;

    let submissionToDisplay = null;

    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.display === "commonForShift"
    ) {
      /**Previous Days data fetch-previousDayReading.previousReading exists, populate the last entered data,  code starts */

      const previousDaySubmission =
        previousDayReading.previousReading &&
        previousDayReading.previousReading !== null &&
        previousDayReading.previousReading.data
          ? previousDayReading.previousReading.data
          : null;

      /**Previous Days data fetch-userForms.previousread exists, populate the last entered data,  code ends */

      /*For forms with day level calculations, remove other shift data for display purpose alone in hourly/shift level tabs
       Such form tabs should have api key  have "dispLevel" with value "shiftLevel"  */
      const submissionFromServer = submissions.find(
        (submission) =>
          submission.metadata &&
          submission.metadata.viewPage &&
          parseInt(submission.metadata.viewPage) === parseInt(currentPage) &&
          (this.webform.component.properties.dispLevel === undefined ||
            (this.webform.component.properties.dispLevel === "shiftLevel" &&
              submission.data.shift === shiftSel))
      ) || { data: {} };
      let mergedSubmission;
      // Get data from `totalFor` components to display
      if (submission.data || previousDaySubmission !== null) {
        const totalSubmission = {};

        eachComponent(form.components, (component) => {
          if (component.properties && component.properties.totalFor) {
            totalSubmission[component.key] = submission.data[component.key];
          }
        });

        mergedSubmission = {
          ...submission,
          ...(!serverSubmissionLoaded ? submissionFromServer : {}),
          data: {
            ...submission.data,
            ...(!serverSubmissionLoaded ? submissionFromServer.data : {}),
            ...totalSubmission,
          },
        };

        if (submissionFromServer._id && !serverSubmissionLoaded) {
          this.setState({ serverSubmissionLoaded: true });
        }

        Object.keys(submission.data).forEach((key) => {
          if (
            submission.data[key] &&
            typeof submission.data[key] !== "object"
          ) {
            mergedSubmission.data[key] = submission.data[key];
          }
        });

        // Check for default component value override
        eachComponent(form.components, (component) => {
          if (
            component.defaultValue === submission.data[component.key] &&
            submission.changed &&
            component.key === submission.changed.component.key &&
            component.defaultValue !== submission.changed.value
          ) {
            mergedSubmission.data[component.key] =
              submissionFromServer.data[component.key];
          }
        });

        // Check for empty value manual override
        if (
          submission.changed &&
          (submission.changed.value === "" ||
            submission.changed.value === undefined ||
            submission.changed.value === null ||
            submission.changed.value === 0)
        ) {
          mergedSubmission.data[submission.changed.component.key] =
            submission.changed.value;
        }
      }
      /**Previous Days data fetch- populate the last entered data,  code starts */

      if (previousDaySubmission !== null && mergedSubmission.data) {
        eachComponent(form.components, (component) => {
          if (
            component.properties &&
            component.properties.previousRead &&
            previousDaySubmission !== null
          ) {
            mergedSubmission.data[component.key] =
              previousDaySubmission[component.properties.previousRead];
          }
        });
      }

      /**Previous Days data fetch-, populate the last entered data,  code ends */

      submissionToDisplay =
        (submissions && submissions.length !== 0) ||
        previousDaySubmission !== null
          ? mergedSubmission
          : null;

      if (
        submissionToDisplay &&
        !isEqual(submissionToDisplay.data, submission.data)
      ) {
        this.setState({
          submission: {
            ...submissionToDisplay,
            ...this.state.submission,
            data: {
              ...submissionToDisplay.data,
            },
          },
        });
      }
    }
  }

  setFormMessage(informAboutDraft, shouldClear, decimalAlert) {
    const { draftSubmission, confirmSubmit, submission } = this.state;
    const formMessage = {};

    if (
      informAboutDraft &&
      !(
        this.state.formMessage.text &&
        this.state.formMessage.text.includes("required.")
      )
    ) {
      formMessage.text = UserMessages.DRAFT_SUBMISSION_PRESENT;
      formMessage.type = "warning";
    }
    if (!draftSubmission.isDraftSubmission) {
      formMessage.text = `The draft will not be saved. Record for selected ${draftSubmission.invalidData.join(
        ", "
      )} already exists.`;
      formMessage.type = "warning";
    } else if (confirmSubmit && this.state.insertCount !== 0) {
      formMessage.text = `${this.state.insertCount} rows data saved successfully. Please wait to upload all data`;
      formMessage.type = "info";
    } else if (confirmSubmit) {
      formMessage.text = UserMessages.DATA_SAVED;
      formMessage.type = "info";
    } else if (
      // Check if unique fields already changed
      draftSubmission.isDraftSubmission &&
      this.state.formMessage.text &&
      this.state.formMessage.text.includes("Record for selected")
    ) {
      formMessage.text = "";
      formMessage.type = "";
    }

    // Check if required fields already filled
    const invalidValues = this.getInvalidInSubmissionValues(submission);
    if (
      !invalidValues.length &&
      this.state.formMessage.text &&
      this.state.formMessage.text.includes("required.")
    ) {
      formMessage.text = "";
      formMessage.type = "";
    }

    if (shouldClear) {
      formMessage.text = "";
      formMessage.type = "";
    }
    if (decimalAlert) {
      formMessage.text =
        "Decimal point is removed on backspace from the numeric field. Please re-enter decimal if required";
      formMessage.type = "warning";
    }

    if (
      formMessage.text !== undefined &&
      !isEqual(formMessage, this.state.formMessage)
    ) {
      this.setState({ formMessage });
    }
  }

  /*****  outsourcing file import starts ****/

  dropdownDataHR57 = () => {
    const {
      form: { form },
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    /* EP-01 seasoning and size value set to empty onload*/
    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      var result = 0;
      result = eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (component.type === "select" && component.label === "Supplier") {
            this.setState({ dropdownSupplier: component.data.values });
          }
          if (component.type === "select" && component.label === "Department") {
            this.setState({ dropdownDepartment: component.data.values });
          }
          if (
            component.type === "select" &&
            component.label === "Shift" &&
            component.key === "outsourcingShift"
          ) {
            this.setState({ dropdownShift: component.data.values });
          }
        }
      );
    }
  };

  csvImportHR57 = (e) => {
    if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        this.processHR57(data);
      };
      reader.readAsBinaryString(file);
      this.dropdownDataHR57();
    }
  };

  processHR57 = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));
    this.setState({
      csvArrayList: JSON.parse(JSON.stringify(list)),
      csvArrayData: JSON.parse(JSON.stringify(list)),
      errorMsg: "",
      errorMsg1: "",
      countData: 1,
      totalCount: JSON.parse(JSON.stringify(list.length)),
    });

    this.supplierEmpty = false;
    this.departmentEmpty = false;
    this.shiftEmpty = false;
    this.hr57NameNull = false;
    this.hr57commentsNull = false;
    this.hr57supplierNull = false;
    this.hr57departmentNull = false;
    this.hr57shiftperiodNull = false;
    this.hr57idNull = false;
    this.importDataHR57();
    this.setState({ formMessage: { text: "", type: "" } });
  };

  importDataHR57 = () => {
    var csvArrayData = this.state.csvArrayData;
    const { redirectDispatch } = this.props;
    if (csvArrayData.length > 0) {
      this.setState({ showLoader: true });
      var name = this.removeWhiteSpace(csvArrayData[0].Name);
      var comments = this.removeWhiteSpace(csvArrayData[0].Comments);
      var id = this.removeWhiteSpace(csvArrayData[0].Id);
      var supplier = this.removeWhiteSpace(csvArrayData[0].Supplier);
      var department = this.removeWhiteSpace(csvArrayData[0].Department);
      var shiftperiod = this.removeWhiteSpace(csvArrayData[0].ShiftPeriod);

      var result = this.emptyCheckHR57(
        id,
        name,
        supplier,
        department,
        shiftperiod
      );

      if (this.emptyReturn === 0) {
        var errorMsg = this.state.errorMsg;
        var newerrorMsg = errorMsg + " Row" + this.state.countData + ",";
        this.setState({ errorMsg: newerrorMsg });
      }
      var result1 = this.dataCheckHR57(supplier, department, shiftperiod);

      if (this.dataReturn == 0) {
        var errorMsgData = this.state.errorMsg1;
        var newerrorMsgData =
          errorMsgData + " Row" + this.state.countData + ",";
        this.setState({ errorMsg1: newerrorMsgData });
      }

      csvArrayData.shift();
      var countData = this.state.countData;
      this.setState({ csvArrayData: csvArrayData, countData: countData + 1 });

      this.interval = setTimeout(() => {
        if (csvArrayData.length !== 0) {
          this.importDataHR57();
        }
      }, 400);

      if (
        this.state.errorMsg === "" &&
        this.state.errorMsg1 === "" &&
        csvArrayData.length === 0
      ) {
        setTimeout(() => {
          this.dataUpdateHR57();
        }, 2000);
      }
    } else {
      this.setState({ showLoader: false });
    }

    if (this.state.errorMsg !== "" && csvArrayData.length === 0) {
      var emptyDataMsgNull = "";
      var emptyDataMsg1Null = "";
      var emptyDataMsg2Null = "";
      var emptyDataMsg3Null = "";
      var emptyDataMsg4Null = "";
      var emptyDataMsg5Null = "";
      var emptyDataMsgIdNull = "";

      if (this.hr57idNull) {
        emptyDataMsgIdNull = "Id,";
      }
      if (this.hr57NameNull) {
        emptyDataMsg1Null = "Name ,";
      }
      if (this.hr57commentsNull) {
        emptyDataMsg2Null = "Comments,";
      }
      if (this.hr57supplierNull) {
        emptyDataMsg3Null = "Supplier,";
      }
      if (this.hr57departmentNull) {
        emptyDataMsg4Null = "Department,";
      }
      if (this.hr57shiftperiodNull) {
        emptyDataMsg5Null = "Shift Period,";
      }

      emptyDataMsgNull =
        emptyDataMsgIdNull +
        emptyDataMsg1Null +
        emptyDataMsg2Null +
        emptyDataMsg3Null +
        emptyDataMsg4Null +
        emptyDataMsg5Null;
      var emptyDataMsgFinalNull = emptyDataMsgNull.replace(/,\s*$/, "");

      var errorMsg = this.state.errorMsg;
      var errorMsg1 = errorMsg.replace(/,\s*$/, "");
      this.setState({ errorMsg: errorMsg1 });
      var displayedMsg = ` Please enter valid data  in  (${emptyDataMsgFinalNull})    ${this.state.errorMsg}  and upload again`;
      setTimeout(() => {
        this.setState({ showLoader: false });
        redirectDispatch();
        this.props.showMessage(displayedMsg);
      }, 1000);
    }
    if (
      this.state.errorMsg === "" &&
      this.state.errorMsg1 !== "" &&
      csvArrayData.length === 0
    ) {
      var emptyDataMsg = "";
      var emptyDataMsg1 = "";
      var emptyDataMsg2 = "";
      var emptyDataMsg3 = "";
      if (this.supplierEmpty) {
        var emptyDataMsg1 = "Supplier,";
      }
      if (this.departmentEmpty) {
        var emptyDataMsg2 = "Department,";
      }
      if (this.shiftEmpty) {
        var emptyDataMsg3 = "ShiftPeriod";
      }
      emptyDataMsg = emptyDataMsg1 + emptyDataMsg2 + emptyDataMsg3;
      var emptyDataMsgFinal = emptyDataMsg.replace(/,\s*$/, "");

      var errorMsgData1 = this.state.errorMsg1;
      var errorMsg2 = errorMsgData1.replace(/,\s*$/, "");
      this.setState({ errorMsg1: errorMsg2 });

      var displayedMsg = ` One or more of the dropdowns (${emptyDataMsgFinal}) is wrong in ${this.state.errorMsg1} `;

      setTimeout(() => {
        this.setState({ showLoader: false });
        redirectDispatch();
        this.props.showMessage(displayedMsg);
      }, 1000);
    }
  };

  dataUpdateHR57 = () => {
    const { redirectDispatch, submissions } = this.props;
    var csvArrayList = this.state.csvArrayList;
    if (csvArrayList.length > 0) {
      var { submission } = this.state;
      this.setState({ showLoader: true });
      var submission = {
        ...submission,
        data: {
          ...submission.data,
          name: "",
          comments: "",
          id: "",
          outsourcingSupplier: "",
          outsourcingShift: "",
          outsourcingDept: "",
        },
      };
      var name = csvArrayList[0].Name;
      var comments = csvArrayList[0].Comments;
      var id = csvArrayList[0].Id;
      var supplier = csvArrayList[0].Supplier;
      var department = csvArrayList[0].Department;
      var shiftperiod = csvArrayList[0].ShiftPeriod;

      submission.data.name = name;
      submission.data.comments = comments;
      submission.data.id = id;
      submission.data.outsourcingSupplier = supplier;
      submission.data.outsourcingShift = shiftperiod;
      submission.data.outsourcingDept = department;
      this.setState({ submission });

      this.csvonSubmitHR57();
      csvArrayList.shift();
      this.setState({ csvArrayList: csvArrayList });

      this.interval = setTimeout(() => {
        if (csvArrayList.length !== 0) {
          this.dataUpdateHR57();
        }
      }, 4000);
      if (csvArrayList.length === 0) {
        this.setState({ showLoader: false });
        setTimeout(() => {
          redirectDispatch();
          this.props.callMessage(this.state.totalCount);
        }, 1000);
      }
    } else {
      this.setState({ showLoader: false });
    }
  };

  emptyCheckHR57 = (id, name, supplier, department, shiftperiod) => {
    this.emptyReturn = 1;
    if (name === null || name === "") {
      this.hr57NameNull = true;
      this.emptyReturn = 0;
    }

    if (supplier === null || supplier === "") {
      this.hr57supplierNull = true;
      this.emptyReturn = 0;
    }
    if (department === null || department === "") {
      this.hr57departmentNull = true;
      this.emptyReturn = 0;
    }
    if (shiftperiod === null || shiftperiod === "") {
      this.hr57shiftperiodNull = true;
      this.emptyReturn = 0;
    }
    if (id === null || id === "" || !this.isNumber(id)) {
      this.hr57idNull = true;
      this.emptyReturn = 0;
    }
  };

  dataCheckHR57 = (supplier, department, shiftperiod) => {
    let arr = this.state.dropdownSupplier;
    var checkSupplier = arr.findIndex((item) => item.value === supplier);

    let arr1 = this.state.dropdownDepartment;
    var checkDepartment = arr1.findIndex((item) => item.value === department);

    let arr2 = this.state.dropdownShift;
    var checkshift = arr2.findIndex((item) => item.value === shiftperiod);

    this.dataReturn = 1;
    if (checkSupplier === -1) {
      this.supplierEmpty = true;
      this.dataReturn = 0;
    }
    if (checkDepartment === -1) {
      this.departmentEmpty = true;
      this.dataReturn = 0;
    }
    if (checkshift === -1) {
      this.shiftEmpty = true;
      this.dataReturn = 0;
    }
  };

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  /***** outsourcing file import ends ****/

  importCSVData = (e, csvtype) => {
    this.errorMsg = "";
    this.errorMsg1 = "";
    if (csvtype === "hr57") {
      this.csvImportHR57(e);
    } else {
      if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          this.processCSVData(data, csvtype);
        };
        reader.readAsBinaryString(file);
        if (csvtype === "core8") {
          this.dropdownDataCore8();
        } else if (csvtype === "hg77") {
          this.dropdownDataHG77();
        } else if (csvtype === "hg78") {
          this.dropdownDataHG78();
        }
      }
    }
  };

  processCSVData = (dataString, csvtype) => {
    var dataStringLines = dataString.split(/\r\n|\n/);
    var headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    var list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      var row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        var obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    // prepare columns list from headers
    var columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    this.csvArrayListCore8 = JSON.parse(JSON.stringify(list));
    this.csvArrayDataCore8 = JSON.parse(JSON.stringify(list));
    this.countDataCore8 = 1;
    this.totalCountCore8 = JSON.parse(JSON.stringify(list.length));
    this.errorMsg = "";
    this.errorMsg1 = "";
    if (csvtype === "core8") {
      this.trainingNameEmpty = false;
      this.trainingGPIDEmpty = false;
      this.trainingNameNull = false;
      this.traineeGPIDNull = false;
      this.emptyReturn = 0;
      this.importDataCore8();
    } else if (csvtype === "hg77") {
      this.observationEmpty = false;
      this.departmentEmpty = false;
      this.areaEmpty = false;
      this.typeAuditEmpty = false;
      this.reportDepEmpty = false;
      this.shiftNameEmpty = false;
      this.issueStatusEmpty = false;
      this.emptyReturn = 0;
      this.observationNull = false;
      this.observationClassNull = false;
      this.departmentNull = false;
      this.areaNameNull = false;
      this.auditTypeNull = false;
      this.ShiftNameNull = false;
      this.reporterNull = false;
      this.issueStatusNull = false;
      this.reportingDepartmentNull = false;

      this.importDataHG77();
    } else if (csvtype === "hg78") {
      this.rateEmpty = false;
      this.departmentEmpty = false;
      this.areaEmpty = false;
      this.auditScopeEmpty = false;
      this.repeatedEmpty = false;
      this.shiftNameEmpty = false;
      this.issueStatusEmpty = false;
      this.findingNull = false;
      this.auditRateNull = false;
      this.auditScopeNull = false;
      this.repeatedNull = false;
      this.auditorNull = false;
      this.auditeeNameNull = false;
      this.clauseNull = false;
      this.departmentNull = false;
      this.areaNameNull = false;
      this.ShiftNameNull = false;
      this.issueStatusNull = false;
      this.emptyReturn = 0;
      this.importDataHG78();
    }
    this.setState({ formMessage: { text: "", type: "" } });
  };

  dropdownDataCore8 = () => {
    const {
      form: { form },
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    /* EP-01 seasoning and size value set to empty onload*/
    this.gpidInfoArray = formInformations.gpidDetailList;

    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      var result = 0;
      result = eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (
            component.type === "select" &&
            component.label === "Training name"
          ) {
            this.dropdownTrainingName = component.data.values;
          }
        }
      );
    }
  };

  importDataCore8 = () => {
    var csvArrayDataCore8 = this.csvArrayDataCore8;

    if (csvArrayDataCore8.length > 0) {
      this.showLoaderCommon = true;
      var trainingName = this.removeWhiteSpace(
        csvArrayDataCore8[0].trainingName
      );
      var traineeGPID = this.removeWhiteSpace(csvArrayDataCore8[0].traineeGPID);
      var result = this.emptyCheckCore8(trainingName, traineeGPID);

      if (this.emptyReturn === 0) {
        var errorMsg = this.errorMsg;
        var newerrorMsg = errorMsg + " Row" + this.countDataCore8 + ",";
        this.errorMsg = newerrorMsg;
      }
      var result1 = this.dataCheckCore8(trainingName, traineeGPID);

      if (this.dataReturn == 0) {
        var errorMsgData = this.errorMsg1;
        var newerrorMsgData = errorMsgData + " Row" + this.countDataCore8 + ",";
        this.errorMsg1 = newerrorMsgData;
      }

      csvArrayDataCore8.shift();
      var countDataCore8 = this.countDataCore8;
      this.csvArrayDataCore8 = csvArrayDataCore8;
      this.countDataCore8 = countDataCore8 + 1;

      this.interval = setTimeout(() => {
        if (csvArrayDataCore8.length !== 0) {
          this.importDataCore8();
        }
      }, 400);

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayDataCore8.length === 0
      ) {
        setTimeout(() => {
          this.dataUpdateCore8();
        }, 2000);
      }
    } else {
      this.showLoaderCommon = false;
    }

    if (this.errorMsg !== "" && csvArrayDataCore8.length === 0) {
      var emptyDataMsgNull = "";
      var emptyDataMsg1Null = "";
      var emptyDataMsg2Null = "";
      if (this.trainingNameNull) {
        emptyDataMsg1Null = "Training Name ,";
      }
      if (this.traineeGPIDNull) {
        emptyDataMsg2Null = "Gpid,";
      }
      emptyDataMsgNull = emptyDataMsg1Null + emptyDataMsg2Null;

      var emptyDataMsgFinalNull = emptyDataMsgNull.replace(/,\s*$/, "");

      var errorMsg = this.errorMsg;
      var errorMsg1 = errorMsg.replace(/,\s*$/, "");
      this.errorMsg = errorMsg1;
      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` Please enter valid data in  (${emptyDataMsgFinalNull})   ${this.errorMsg}  and upload again`,
            type: "danger",
          },
        });
      }, 500);
    }
    if (
      this.errorMsg === "" &&
      this.errorMsg1 !== "" &&
      csvArrayDataCore8.length === 0
    ) {
      var emptyDataMsg = "";
      var emptyDataMsg1 = "";
      var emptyDataMsg2 = "";

      if (this.trainingNameEmpty) {
        var emptyDataMsg1 = "Training Name,";
      }
      if (this.trainingGPIDEmpty) {
        var emptyDataMsg2 = "GPId,";
      }

      emptyDataMsg = emptyDataMsg1 + emptyDataMsg2;
      var emptyDataMsgFinal = emptyDataMsg.replace(/,\s*$/, "");

      var errorMsgData1 = this.errorMsg1;
      var errorMsg2 = errorMsgData1.replace(/,\s*$/, "");
      this.errorMsg1 = errorMsg2;
      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` One or more of the data (${emptyDataMsgFinal}) is wrong in  ${this.errorMsg1} `,
            type: "danger",
          },
        });
      }, 500);
    }
  };

  dataUpdateCore8 = () => {
    const { redirectDispatch } = this.props;
    var csvArrayListCore8 = this.csvArrayListCore8;
    if (csvArrayListCore8.length > 0) {
      var { submission } = this.state;
      this.showLoaderCommon = true;
      var submission = {
        ...submission,
        data: {
          ...submission.data,
          trainingName: "",
          traineeGpid: "",
          name: "",
          jobTitle: "",
          entity: "",
          section: "",
        },
      };
      var trainingName = csvArrayListCore8[0].trainingName;
      var traineeGPID = csvArrayListCore8[0].traineeGPID;
      this.prePopulateCore8(traineeGPID);

      submission.data.name = this.gpIDUserInfo.data.name;
      submission.data.jobTitle = this.gpIDUserInfo.data.jobTitle;
      submission.data.entity = this.gpIDUserInfo.data.entity;
      submission.data.section = this.gpIDUserInfo.data.section;

      submission.data.trainingName = trainingName;
      submission.data.traineeGpid = traineeGPID;

      this.setState({ submission: submission });
      this.csvonSubmitHR57();
      csvArrayListCore8.shift();
      this.csvArrayListCore8 = csvArrayListCore8;

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayListCore8.length !== 0
      ) {
        setTimeout(() => {
          this.dataUpdateCore8();
        }, 3000);
      }

      if (csvArrayListCore8.length === 0) {
        this.showLoaderCommon = false;
        setTimeout(() => {
          redirectDispatch();
          this.props.callMessage(this.totalCountCore8);
        }, 1000);
      }
    } else {
      this.showLoaderCommon = false;
    }
  };

  emptyCheckCore8 = (trainingName, traineeGPID) => {
    this.emptyReturn = 1;
    if (trainingName === null || trainingName === "") {
      this.trainingNameNull = true;
      this.emptyReturn = 0;
    }
    if (traineeGPID === null || traineeGPID === "") {
      this.traineeGPIDNull = true;
      this.emptyReturn = 0;
    }
  };

  dataCheckCore8 = (trainingName, traineeGPID) => {
    let arr = this.dropdownTrainingName;
    var checkTraining = arr.findIndex((item) => item.value === trainingName);

    let arr1 = this.gpidInfoArray;
    var checkGPID = arr1.findIndex((item1) => item1.data.gpid == traineeGPID);

    this.dataReturn = 1;
    if (checkTraining === -1) {
      this.trainingNameEmpty = true;
      this.dataReturn = 0;
    }
    if (checkGPID === -1) {
      this.trainingGPIDEmpty = true;
      this.dataReturn = 0;
    }
  };

  prePopulateCore8 = (traineeGPID) => {
    let arr1 = this.gpidInfoArray;

    this.gpIDUserInfo = arr1.find((item1) => item1.data.gpid == traineeGPID);
  };
  /***** core8 file import ends ****/

  /***** HG77 file import ends ****/

  removeWhiteSpace = (inputStr) => {
    return inputStr.trim();
  };

  dropdownDataHG77 = () => {
    const {
      form: { form },
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    /* EP-01 seasoning and size value set to empty onload*/
    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      var result = 0;
      result = eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (
            component.type === "select" &&
            component.label === "Classification of observation"
          ) {
            this.dropdownobservation = component.data.values;
          }
          if (component.type === "select" && component.label === "Department") {
            this.dropdownDepartment = component.data.values;
          }
          if (component.type === "select" && component.label === "Area") {
            this.dropdownArea = component.data.values;
          }
          if (
            component.type === "select" &&
            component.label === "Type of audit"
          ) {
            this.dropdownTypeAudit = component.data.values;
          }
          if (component.type === "select" && component.label === "Shift Name") {
            this.dropdownShiftName = component.data.values;
          }
          if (component.type === "select" && component.label === "Status") {
            this.dropdownstatus = component.data.values;
          }
          if (
            component.type === "select" &&
            component.label === "Reporting department"
          ) {
            this.dropdownReportDep = component.data.values;
          }
        }
      );
    }
  };

  importDataHG77 = () => {
    var csvArrayDataCore8 = this.csvArrayDataCore8;
    if (csvArrayDataCore8.length > 0) {
      this.showLoaderCommon = true;
      var observations = this.removeWhiteSpace(
        csvArrayDataCore8[0].observations
      );
      var observationClassification = this.removeWhiteSpace(
        csvArrayDataCore8[0].observationClassification
      );

      var department = this.removeWhiteSpace(csvArrayDataCore8[0].department);
      var areaName = this.removeWhiteSpace(csvArrayDataCore8[0].areaName);
      var locationOfTheViolation = this.removeWhiteSpace(
        csvArrayDataCore8[0].violation
      );
      var auditType = this.removeWhiteSpace(csvArrayDataCore8[0].auditType);
      var findingDate = this.removeWhiteSpace(csvArrayDataCore8[0].findingDate);
      var findtime = this.removeWhiteSpace(csvArrayDataCore8[0].time);
      var ShiftName = this.removeWhiteSpace(csvArrayDataCore8[0].ShiftName);
      var reporter = this.removeWhiteSpace(csvArrayDataCore8[0].reporter);
      var issueStatus = this.removeWhiteSpace(csvArrayDataCore8[0].issueStatus);
      var rootCause = this.removeWhiteSpace(csvArrayDataCore8[0].rootCause);
      var correctiveAction = this.removeWhiteSpace(
        csvArrayDataCore8[0].correctiveAction
      );
      var plannedDate = this.removeWhiteSpace(csvArrayDataCore8[0].plannedDate);
      var reportingDepartment = this.removeWhiteSpace(
        csvArrayDataCore8[0].reportingDepartment
      );
      var actuallyClosedOn = this.removeWhiteSpace(
        csvArrayDataCore8[0].actuallyClosedOn
      );
      var closedWithinPlannedDate = this.removeWhiteSpace(
        csvArrayDataCore8[0].closedWithinPlannedDate
      );

      var result = this.emptyCheckHG77(
        observations,
        observationClassification,
        department,
        areaName,
        locationOfTheViolation,
        auditType,
        findingDate,
        findtime,
        ShiftName,
        reporter,
        issueStatus,
        rootCause,
        correctiveAction,
        plannedDate,
        reportingDepartment,
        actuallyClosedOn,
        closedWithinPlannedDate
      );
      if (this.emptyReturn === 0) {
        var errorMsg = this.errorMsg;
        var newerrorMsg = errorMsg + " Row" + this.countDataCore8 + ",";
        this.errorMsg = newerrorMsg;
      }

      var result1 = this.dataCheckHG77(
        observationClassification,
        department,
        areaName,
        auditType,
        ShiftName,
        issueStatus,
        reportingDepartment
      );

      if (this.dataReturn == 0) {
        var errorMsgData = this.errorMsg1;
        var newerrorMsgData = errorMsgData + " Row" + this.countDataCore8 + ",";
        this.errorMsg1 = newerrorMsgData;
      }

      csvArrayDataCore8.shift();
      var countDataCore8 = this.countDataCore8;
      this.csvArrayDataCore8 = csvArrayDataCore8;
      this.countDataCore8 = countDataCore8 + 1;

      this.interval = setTimeout(() => {
        if (csvArrayDataCore8.length !== 0) {
          this.importDataHG77();
        }
      }, 400);

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayDataCore8.length === 0
      ) {
        setTimeout(() => {
          this.dataUpdateHG77();
        }, 2000);
      }
    } else {
      this.showLoaderCommon = false;
    }

    if (this.errorMsg !== "" && csvArrayDataCore8.length === 0) {
      var emptyDataMsgNull = "";
      var emptyDataMsg1Null = "";
      var emptyDataMsg2Null = "";
      var emptyDataMsg3Null = "";
      var emptyDataMsg4Null = "";
      var emptyDataMsg5Null = "";
      var emptyDataMsg6Null = "";
      var emptyDataMsg7Null = "";
      var emptyDataMsg8Null = "";
      var emptyDataMsg9Null = "";

      if (this.observationNull) {
        emptyDataMsg1Null = "Observation ,";
      }
      if (this.observationClassNull) {
        emptyDataMsg2Null = "Observation Classification,";
      }
      if (this.departmentNull) {
        emptyDataMsg3Null = "Department,";
      }
      if (this.areaNameNull) {
        emptyDataMsg4Null = "Area Name,";
      }
      if (this.auditTypeNull) {
        emptyDataMsg5Null = "Type of audit,";
      }
      if (this.ShiftNameNull) {
        emptyDataMsg6Null = "Shift Name,";
      }
      if (this.reporterNull) {
        emptyDataMsg7Null = "Reporter ,";
      }
      if (this.issueStatusNull) {
        emptyDataMsg8Null = "Issue Status,";
      }
      if (this.reportingDepartmentNull) {
        emptyDataMsg9Null = "Report Department,";
      }

      emptyDataMsgNull =
        emptyDataMsg1Null +
        emptyDataMsg2Null +
        emptyDataMsg3Null +
        emptyDataMsg4Null +
        emptyDataMsg5Null +
        emptyDataMsg6Null +
        emptyDataMsg7Null +
        emptyDataMsg8Null +
        emptyDataMsg9Null;
      var emptyDataMsgFinalNull = emptyDataMsgNull.replace(/,\s*$/, "");

      var errorMsg = this.errorMsg;
      var errorMsg1 = errorMsg.replace(/,\s*$/, "");
      this.errorMsg = errorMsg1;

      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` Please enter valid data in  (${emptyDataMsgFinalNull})  ${this.errorMsg}  and upload again`,
            type: "danger",
          },
        });
      }, 500);
    }
    if (
      this.errorMsg === "" &&
      this.errorMsg1 !== "" &&
      csvArrayDataCore8.length === 0
    ) {
      var emptyDataMsg = "";
      var emptyDataMsg1 = "";
      var emptyDataMsg2 = "";
      var emptyDataMsg3 = "";
      var emptyDataMsg4 = "";
      var emptyDataMsg5 = "";
      var emptyDataMsg6 = "";
      var emptyDataMsg7 = "";

      if (this.observationEmpty) {
        var emptyDataMsg1 = "Observation Classification,";
      }
      if (this.departmentEmpty) {
        var emptyDataMsg2 = "Department,";
      }
      if (this.areaEmpty) {
        var emptyDataMsg3 = "Area Name,";
      }
      if (this.typeAuditEmpty) {
        var emptyDataMsg4 = "Type of audit,";
      }
      if (this.shiftNameEmpty) {
        var emptyDataMsg5 = "Shift Name,";
      }
      if (this.issueStatusEmpty) {
        var emptyDataMsg6 = "Issue Status,";
      }
      if (this.reportDepEmpty) {
        var emptyDataMsg7 = "Report Department,";
      }

      emptyDataMsg =
        emptyDataMsg1 +
        emptyDataMsg2 +
        emptyDataMsg3 +
        emptyDataMsg4 +
        emptyDataMsg5 +
        emptyDataMsg6 +
        emptyDataMsg7;
      var emptyDataMsgFinal = emptyDataMsg.replace(/,\s*$/, "");

      var errorMsgData1 = this.errorMsg1;
      var errorMsg2 = errorMsgData1.replace(/,\s*$/, "");
      this.errorMsg1 = errorMsg2;
      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` One or more of the data (${emptyDataMsgFinal}) is wrong in  ${this.errorMsg1} `,
            type: "danger",
          },
        });
      }, 500);
    }
  };

  dataUpdateHG77 = () => {
    const { redirectDispatch } = this.props;
    var csvArrayListCore8 = this.csvArrayListCore8;
    if (csvArrayListCore8.length > 0) {
      var { submission } = this.state;

      this.showLoaderCommon = true;
      var submission = {
        ...submission,
        data: {
          ...submission.data,
          observations: "",
          observationclassification: "",
          department: "",
          area: "",
          locationOfTheViolation: "",
          auditType: "",
          findingDate: "",
          "team-ShiftName": "",
          reporter: "",
          issueStatus: "",
          rootCause: "",
          correctiveAction: "",
          plannedDate: "",
          reportingDepartment: "",
          actuallyClosedOn: "",
          closedWithinPlannedDate: "",
        },
      };
      var observations = csvArrayListCore8[0].observations;
      var observationClassification =
        csvArrayListCore8[0].observationClassification;
      var department = csvArrayListCore8[0].department;
      var areaName = csvArrayListCore8[0].areaName;
      var locationOfTheViolation = csvArrayListCore8[0].violation;
      var auditType = csvArrayListCore8[0].auditType;

      var findingDate1 = this.removeWhiteSpace(
        csvArrayListCore8[0].findingDate
      );
      if (findingDate1 !== "") {
        var findingDate = moment(findingDate1).format("YYYY-MM-DD");
      } else {
        var findingDate = moment().format("YYYY-MM-DD");
      }

      var findtime = csvArrayListCore8[0].time;
      var ShiftName = csvArrayListCore8[0].ShiftName;
      var reporter = csvArrayListCore8[0].reporter;
      var issueStatus = csvArrayListCore8[0].issueStatus;
      var rootCause = csvArrayListCore8[0].rootCause;
      var correctiveAction = csvArrayListCore8[0].correctiveAction;

      var plannedDate1 = this.removeWhiteSpace(
        csvArrayListCore8[0].plannedDate
      );

      if (plannedDate1 !== "") {
        var plannedDate = moment(plannedDate1).format("YYYY-MM-DD");
      } else {
        var plannedDate = "";
      }

      var actuallyClosedOn1 = this.removeWhiteSpace(
        csvArrayListCore8[0].actuallyClosedOn
      );

      if (actuallyClosedOn1 !== "") {
        var actuallyClosedOn = moment(actuallyClosedOn1).format("YYYY-MM-DD");
      } else {
        var actuallyClosedOn = "";
      }
      var reportingDepartment = csvArrayListCore8[0].reportingDepartment;
      var closedWithinPlannedDate =
        csvArrayListCore8[0].closedWithinPlannedDate;

      submission.data.observations = observations;
      submission.data.observationClassification = observationClassification;
      submission.data.department = department;
      submission.data.area = areaName;
      submission.data.locationOfTheViolation = locationOfTheViolation;
      submission.data.auditType = auditType;
      submission.data.findingDate = findingDate;
      submission.data["team-ShiftName"] = ShiftName;

      submission.data.reporter = reporter;
      submission.data.issueStatus = issueStatus;
      submission.data.rootCause = rootCause;
      submission.data.correctiveAction = correctiveAction;
      submission.data.plannedDate = plannedDate;
      submission.data.reportingDepartment = reportingDepartment;
      submission.data.actuallyClosedOn = actuallyClosedOn;
      submission.data.closedWithinPlannedDate = closedWithinPlannedDate;
      this.setState({ submission: submission });
      this.csvonSubmitHR57();
      csvArrayListCore8.shift();
      this.csvArrayListCore8 = csvArrayListCore8;

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayListCore8.length !== 0
      ) {
        setTimeout(() => {
          this.dataUpdateHG77();
        }, 4000);
      }

      if (csvArrayListCore8.length === 0) {
        this.showLoaderCommon = false;
        setTimeout(() => {
          redirectDispatch();
          this.props.callMessage(this.totalCountCore8);
        }, 1000);
      }
    } else {
      this.showLoaderCommon = false;
    }
  };

  emptyCheckHG77 = (
    observations,
    observationClassification,
    department,
    areaName,
    locationOfTheViolation,
    auditType,
    findingDate,
    time,
    ShiftName,
    reporter,
    issueStatus,
    rootCause,
    correctiveAction,
    plannedDate,
    reportingDepartment,
    actuallyClosedOn,
    closedWithinPlannedDate
  ) => {
    this.emptyReturn = 1;
    if (observations === null || observations === "") {
      this.observationNull = true;
      this.emptyReturn = 0;
    }
    if (
      observationClassification === null ||
      observationClassification === ""
    ) {
      this.observationClassNull = true;
      this.emptyReturn = 0;
    }
    if (department === null || department === "") {
      this.departmentNull = true;
      this.emptyReturn = 0;
    }
    if (areaName === null || areaName === "") {
      this.areaNameNull = true;
      this.emptyReturn = 0;
    }
    if (auditType === null || auditType === "") {
      this.auditTypeNull = true;
      this.emptyReturn = 0;
    }
    if (ShiftName === null || ShiftName === "") {
      this.ShiftNameNull = true;
      this.emptyReturn = 0;
    }
    if (reporter === null || reporter === "") {
      this.reporterNull = true;
      this.emptyReturn = 0;
    }
    if (issueStatus === null || issueStatus === "") {
      this.issueStatusNull = true;
      this.emptyReturn = 0;
    }
    if (reportingDepartment === null || reportingDepartment === "") {
      this.reportingDepartmentNull = true;
      this.emptyReturn = 0;
    }
  };

  dataCheckHG77 = (
    observationClassification,
    department,
    areaName,
    auditType,
    ShiftName,
    issueStatus,
    reportingDepartment
  ) => {
    let arr = this.dropdownobservation;
    var checkobservation = arr.findIndex(
      (item) => item.value === observationClassification
    );

    let arr1 = this.dropdownDepartment;
    var checkDepartment = arr1.findIndex((item1) => item1.value == department);

    let arr2 = this.dropdownArea;
    var checkArea = arr2.findIndex((item1) => item1.value == areaName);

    let arr3 = this.dropdownTypeAudit;
    var checkTypeAudit = arr3.findIndex((item1) => item1.value == auditType);

    let arr4 = this.dropdownShiftName;
    var checkShiftName = arr4.findIndex((item1) => item1.value == ShiftName);

    let arr5 = this.dropdownstatus;
    var checkIssueStatus = arr5.findIndex(
      (item1) => item1.value == issueStatus
    );

    let arr6 = this.dropdownReportDep;
    var checkReportDep = arr6.findIndex(
      (item1) => item1.value == reportingDepartment
    );
    this.dataReturn = 1;
    if (checkobservation === -1) {
      this.observationEmpty = true;
      this.dataReturn = 0;
    }
    if (checkDepartment === -1) {
      this.departmentEmpty = true;
      this.dataReturn = 0;
    }
    if (checkArea === -1) {
      this.areaEmpty = true;
      this.dataReturn = 0;
    }
    if (checkTypeAudit === -1) {
      this.typeAuditEmpty = true;
      this.dataReturn = 0;
    }
    if (checkShiftName === -1) {
      this.shiftNameEmpty = true;
      this.dataReturn = 0;
    }
    if (checkIssueStatus === -1) {
      this.issueStatusEmpty = true;
      this.dataReturn = 0;
    }
    if (checkReportDep === -1) {
      this.reportDepEmpty = true;
      this.dataReturn = 0;
    }
  };

  /***** HG77 file import ends ****/

  /***** HG78 file import starts ****/

  prePopulateHG78 = (clause) => {
    let arr1 = this.standardTitleList;
    var clauseNo = clause.substring(0, 4);
    this.clauseInfo = arr1.find((item1) => item1.data.standardNo == clauseNo);

    this.standardNo = "";
    this.auditCategory = "";
    let categories = "";
    if (clause) {
      this.standardNo = clause.substring(0, 4);
    }
    if (this.standardNo) {
      let rangeOneStart = 1.01;
      let rangeOneEnd = 1.37;

      let rangeTwoStart = 2.01;
      let rangeTwoEnd = 2.23;

      let rangeThreeStart = 3.01;
      let rangeThreeEnd = 3.11;

      let rangeFourStart = 4.01;
      let rangeFourEnd = 4.16;

      let rangeFiveStart = 5.01;
      let rangeFiveEnd = 5.27;

      if (this.standardNo >= rangeOneStart && this.standardNo <= rangeOneEnd) {
        categories = "Operational Methods and Personnel Practices";
      } else if (
        this.standardNo >= rangeTwoStart &&
        this.standardNo <= rangeTwoEnd
      ) {
        categories = "Maintenance for Food Safety";
      } else if (
        this.standardNo >= rangeThreeStart &&
        this.standardNo <= rangeThreeEnd
      ) {
        categories = "Cleaning Practices";
      } else if (
        this.standardNo >= rangeFourStart &&
        this.standardNo <= rangeFourEnd
      ) {
        categories = "Integrated Pest Management";
      } else if (
        this.standardNo >= rangeFiveStart &&
        this.standardNo <= rangeFiveEnd
      ) {
        categories = "Adequacy of Prerequisite and Food Safety Programs";
      }
    }
    this.auditCategory = categories;
  };

  dropdownDataHG78 = () => {
    const {
      form: { form },
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    this.standardTitleList = formInformations.standardTitleList;
    /* EP-01 seasoning and size value set to empty onload*/
    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      var result = 0;
      result = eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (component.type === "select" && component.label === "Rate") {
            this.dropdownRate = component.data.values;
          }
          if (component.type === "select" && component.label === "Department") {
            this.dropdownDepartment = component.data.values;
          }
          if (component.type === "select" && component.label === "Area") {
            this.dropdownArea = component.data.values;
          }
          if (
            component.type === "select" &&
            component.label === "Audit scope"
          ) {
            this.dropdownTypeScope = component.data.values;
          }
          if (component.type === "select" && component.label === "Repeated") {
            this.dropdownRepeated = component.data.values;
          }
          if (component.type === "select" && component.label === "Shift Name") {
            this.dropdownShiftName = component.data.values;
          }
          if (component.type === "select" && component.label === "Status") {
            this.dropdownstatus = component.data.values;
          }
        }
      );
    }
  };

  importDataHG78 = () => {
    var csvArrayDataCore8 = this.csvArrayDataCore8;
    if (csvArrayDataCore8.length > 0) {
      this.showLoaderCommon = true;
      var finding = this.removeWhiteSpace(csvArrayDataCore8[0].finding);
      var auditRate = this.removeWhiteSpace(csvArrayDataCore8[0].auditRate);
      var department = this.removeWhiteSpace(csvArrayDataCore8[0].department);
      var areaName = this.removeWhiteSpace(csvArrayDataCore8[0].areaName);
      var auditScope = this.removeWhiteSpace(csvArrayDataCore8[0].auditScope);

      var findingDate = this.removeWhiteSpace(csvArrayDataCore8[0].findingDate);
      var repeated = this.removeWhiteSpace(csvArrayDataCore8[0].repeated);
      var ShiftName = this.removeWhiteSpace(csvArrayDataCore8[0].ShiftName);
      var auditor = this.removeWhiteSpace(csvArrayDataCore8[0].auditor);
      var issueStatus = this.removeWhiteSpace(csvArrayDataCore8[0].issueStatus);
      var rootCause = this.removeWhiteSpace(csvArrayDataCore8[0].rootCause);
      var correctiveAction = this.removeWhiteSpace(
        csvArrayDataCore8[0].correctiveAction
      );
      var plannedDate = this.removeWhiteSpace(csvArrayDataCore8[0].plannedDate);
      var auditeeName = this.removeWhiteSpace(csvArrayDataCore8[0].auditeeName);
      var actuallyClosedOn = this.removeWhiteSpace(
        csvArrayDataCore8[0].actuallyClosedOn
      );
      var closedWithinPlannedDate = this.removeWhiteSpace(
        csvArrayDataCore8[0].closedWithinPlannedDate
      );
      var clause = this.removeWhiteSpace(csvArrayDataCore8[0].clause);

      var result = this.emptyCheckHG78(
        finding,
        auditRate,
        department,
        areaName,
        auditScope,
        findingDate,
        repeated,
        ShiftName,
        auditor,
        issueStatus,
        rootCause,
        correctiveAction,
        plannedDate,
        auditeeName,
        actuallyClosedOn,
        closedWithinPlannedDate,
        clause
      );

      if (this.emptyReturn === 0) {
        var errorMsg = this.errorMsg;
        var newerrorMsg = errorMsg + " Row" + this.countDataCore8 + ",";
        this.errorMsg = newerrorMsg;
      }

      var result1 = this.dataCheckHG78(
        auditRate,
        department,
        areaName,
        auditScope,
        ShiftName,
        issueStatus,
        repeated
      );

      if (this.dataReturn == 0) {
        var errorMsgData = this.errorMsg1;
        var newerrorMsgData = errorMsgData + " Row" + this.countDataCore8 + ",";
        this.errorMsg1 = newerrorMsgData;
      }

      csvArrayDataCore8.shift();
      var countDataCore8 = this.countDataCore8;
      this.csvArrayDataCore8 = csvArrayDataCore8;
      this.countDataCore8 = countDataCore8 + 1;

      this.interval = setTimeout(() => {
        if (csvArrayDataCore8.length !== 0) {
          this.importDataHG78();
        }
      }, 400);

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayDataCore8.length === 0
      ) {
        setTimeout(() => {
          this.dataUpdateHG78();
        }, 2000);
      }
    } else {
      this.showLoaderCommon = false;
    }

    if (this.errorMsg !== "" && csvArrayDataCore8.length === 0) {
      var emptyDataMsgNull = "";
      var emptyDataMsg1Null = "";
      var emptyDataMsg2Null = "";
      var emptyDataMsg3Null = "";
      var emptyDataMsg4Null = "";
      var emptyDataMsg5Null = "";
      var emptyDataMsg6Null = "";
      var emptyDataMsg7Null = "";
      var emptyDataMsg8Null = "";
      var emptyDataMsg9Null = "";
      var emptyDataMsg10Null = "";
      var emptyDataMsg11Null = "";

      if (this.findingNull) {
        emptyDataMsg1Null = "Finding ,";
      }
      if (this.auditRateNull) {
        emptyDataMsg2Null = "Rate,";
      }
      if (this.departmentNull) {
        emptyDataMsg3Null = "Department,";
      }
      if (this.areaNameNull) {
        emptyDataMsg4Null = "Area Name,";
      }
      if (this.auditScopeNull) {
        emptyDataMsg5Null = "Audit Scope,";
      }
      if (this.ShiftNameNull) {
        emptyDataMsg6Null = "Shift Name,";
      }
      if (this.repeatedNull) {
        emptyDataMsg7Null = "Repeated ,";
      }
      if (this.issueStatusNull) {
        emptyDataMsg8Null = "Issue Status,";
      }
      if (this.auditorNull) {
        emptyDataMsg9Null = "Auditor,";
      }
      if (this.auditeeNameNull) {
        emptyDataMsg10Null = "Auditee Name,";
      }
      if (this.clauseNull) {
        emptyDataMsg11Null = "Clause,";
      }

      emptyDataMsgNull =
        emptyDataMsg1Null +
        emptyDataMsg2Null +
        emptyDataMsg3Null +
        emptyDataMsg4Null +
        emptyDataMsg5Null +
        emptyDataMsg6Null +
        emptyDataMsg7Null +
        emptyDataMsg8Null +
        emptyDataMsg9Null +
        emptyDataMsg10Null +
        emptyDataMsg11Null;
      var emptyDataMsgFinalNull = emptyDataMsgNull.replace(/,\s*$/, "");

      var errorMsg = this.errorMsg;
      var errorMsg1 = errorMsg.replace(/,\s*$/, "");
      this.errorMsg = errorMsg1;
      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` Please enter valid data in  (${emptyDataMsgFinalNull})  ${this.errorMsg}  and upload again`,
            type: "danger",
          },
        });
      }, 500);
    }
    if (
      this.errorMsg === "" &&
      this.errorMsg1 !== "" &&
      csvArrayDataCore8.length === 0
    ) {
      var emptyDataMsg = "";
      var emptyDataMsg1 = "";
      var emptyDataMsg2 = "";
      var emptyDataMsg3 = "";
      var emptyDataMsg4 = "";
      var emptyDataMsg5 = "";
      var emptyDataMsg6 = "";
      var emptyDataMsg7 = "";

      if (this.rateEmpty) {
        var emptyDataMsg1 = "Rate,";
      }
      if (this.departmentEmpty) {
        var emptyDataMsg2 = "Department,";
      }
      if (this.areaEmpty) {
        var emptyDataMsg3 = "Area Name,";
      }
      if (this.auditScopeEmpty) {
        var emptyDataMsg4 = "Audit Scope,";
      }
      if (this.shiftNameEmpty) {
        var emptyDataMsg5 = "Shift Name,";
      }
      if (this.issueStatusEmpty) {
        var emptyDataMsg6 = "Issue Status,";
      }
      if (this.repeatedEmpty) {
        var emptyDataMsg7 = "Repeated,";
      }

      emptyDataMsg =
        emptyDataMsg1 +
        emptyDataMsg2 +
        emptyDataMsg3 +
        emptyDataMsg4 +
        emptyDataMsg5 +
        emptyDataMsg6 +
        emptyDataMsg7;
      var emptyDataMsgFinal = emptyDataMsg.replace(/,\s*$/, "");

      var errorMsgData1 = this.errorMsg1;
      var errorMsg2 = errorMsgData1.replace(/,\s*$/, "");
      this.errorMsg1 = errorMsg2;
      this.showLoaderCommon = false;
      setTimeout(() => {
        this.setState({
          formMessage: {
            text: ` One or more of the data (${emptyDataMsgFinal}) is wrong in  ${this.errorMsg1} `,
            type: "danger",
          },
        });
      }, 500);
    }
  };

  dataUpdateHG78 = () => {
    const { redirectDispatch } = this.props;
    var csvArrayListCore8 = this.csvArrayListCore8;
    if (csvArrayListCore8.length > 0) {
      var { submission } = this.state;
      this.showLoaderCommon = true;
      var submission = {
        ...submission,
        data: {
          ...submission.data,
          finding: "",
          auditRate: "",
          department: "",
          area: "",
          auditScope: "",
          auditType: "",
          findingDate: "",
          repeated: "",
          "team-ShiftName": "",
          auditor: "",
          issueStatus: "",
          rootCause: "",
          correctiveAction: "",
          plannedDate: "",
          auditeeName: "",
          actuallyClosedOn: "",
          closedWithinPlannedDate: "",
          clause: "",
          categories: "",
          standardNo: "",
          standardTitle: "",
        },
      };

      var clause = csvArrayListCore8[0].clause;

      this.prePopulateHG78(clause);

      var finding = csvArrayListCore8[0].finding;
      var auditRate = csvArrayListCore8[0].auditRate;
      var department = csvArrayListCore8[0].department;
      var areaName = csvArrayListCore8[0].areaName;
      var auditScope = csvArrayListCore8[0].auditScope;

      var findingDate1 = this.removeWhiteSpace(
        csvArrayListCore8[0].findingDate
      );

      if (findingDate1 !== "") {
        var findingDate = moment(findingDate1).format("YYYY-MM-DD");
      } else {
        var findingDate = moment().format("YYYY-MM-DD");
      }

      var repeated = csvArrayListCore8[0].repeated;
      var ShiftName = csvArrayListCore8[0].ShiftName;
      var auditor = csvArrayListCore8[0].auditor;
      var issueStatus = csvArrayListCore8[0].issueStatus;
      var rootCause = csvArrayListCore8[0].rootCause;
      var correctiveAction = csvArrayListCore8[0].correctiveAction;
      var auditeeName = csvArrayListCore8[0].auditeeName;
      var closedWithinPlannedDate =
        csvArrayListCore8[0].closedWithinPlannedDate;

      var plannedDate1 = this.removeWhiteSpace(
        csvArrayListCore8[0].plannedDate
      );

      if (plannedDate1 !== "") {
        var plannedDate = moment(plannedDate1).format("YYYY-MM-DD");
      } else {
        var plannedDate = "";
      }

      var actuallyClosedOn1 = this.removeWhiteSpace(
        csvArrayListCore8[0].actuallyClosedOn
      );

      if (actuallyClosedOn1 !== "") {
        var actuallyClosedOn = moment(actuallyClosedOn1).format("YYYY-MM-DD");
      } else {
        var actuallyClosedOn = "";
      }

      submission.data.categories = this.auditCategory;
      submission.data.standardNo = this.standardNo;
      submission.data.standardTitle = this.clauseInfo.data.standardTitle;

      submission.data.finding = finding;
      submission.data.auditRate = auditRate;
      submission.data.department = department;
      submission.data.area = areaName;
      submission.data.auditScope = auditScope;
      submission.data.findingDate = findingDate;
      submission.data["team-ShiftName"] = ShiftName;
      submission.data.auditor = auditor;
      submission.data.issueStatus = issueStatus;
      submission.data.rootCause = rootCause;
      submission.data.correctiveAction = correctiveAction;
      submission.data.plannedDate = plannedDate;
      submission.data.repeated = repeated;
      submission.data.auditeeName = auditeeName;
      submission.data.actuallyClosedOn = actuallyClosedOn;
      submission.data.closedWithinPlannedDate = closedWithinPlannedDate;
      submission.data.clause = clause;

      this.setState({ submission: submission });
      this.csvonSubmitHR57();
      csvArrayListCore8.shift();
      this.csvArrayListCore8 = csvArrayListCore8;

      if (
        this.errorMsg === "" &&
        this.errorMsg1 === "" &&
        csvArrayListCore8.length !== 0
      ) {
        setTimeout(() => {
          this.dataUpdateHG78();
        }, 4000);
      }

      if (csvArrayListCore8.length === 0) {
        this.showLoaderCommon = false;
        setTimeout(() => {
          redirectDispatch();
          this.props.callMessage(this.totalCountCore8);
        }, 1000);
      }
    } else {
      this.showLoaderCommon = false;
    }
  };

  emptyCheckHG78 = (
    finding,
    auditRate,
    department,
    areaName,
    auditScope,
    findingDate,
    repeated,
    ShiftName,
    auditor,
    issueStatus,
    rootCause,
    correctiveAction,
    plannedDate,
    auditeeName,
    actuallyClosedOn,
    closedWithinPlannedDate,
    clause
  ) => {
    this.emptyReturn = 1;
    if (finding === null || finding === "") {
      this.findingNull = true;
      this.emptyReturn = 0;
    }
    if (auditRate === null || auditRate === "") {
      this.auditRateNull = true;
      this.emptyReturn = 0;
    }
    if (department === null || department === "") {
      this.departmentNull = true;
      this.emptyReturn = 0;
    }
    if (areaName === null || areaName === "") {
      this.areaNameNull = true;
      this.emptyReturn = 0;
    }
    if (auditScope === null || auditScope === "") {
      this.auditScopeNull = true;
      this.emptyReturn = 0;
    }
    if (repeated === null || repeated === "") {
      this.repeatedNull = true;
      this.emptyReturn = 0;
    }
    if (ShiftName === null || ShiftName === "") {
      this.ShiftNameNull = true;
      this.emptyReturn = 0;
    }
    if (auditor === null || auditor === "") {
      this.auditorNull = true;
      this.emptyReturn = 0;
    }
    if (issueStatus === null || issueStatus === "") {
      this.issueStatusNull = true;
      this.emptyReturn = 0;
    }
    if (auditeeName === null || auditeeName === "") {
      this.auditeeNameNull = true;
      this.emptyReturn = 0;
    }
    if (clause === null || clause === "") {
      this.clauseNull = true;
      this.emptyReturn = 0;
    }
  };

  dataCheckHG78 = (
    auditRate,
    department,
    areaName,
    auditScope,
    ShiftName,
    issueStatus,
    repeated
  ) => {
    let arr = this.dropdownRate;
    var checkobservation = arr.findIndex((item) => item.value === auditRate);

    let arr1 = this.dropdownDepartment;
    var checkDepartment = arr1.findIndex((item1) => item1.value == department);

    let arr2 = this.dropdownArea;
    var checkArea = arr2.findIndex((item1) => item1.value == areaName);

    let arr3 = this.dropdownTypeScope;
    var checkTypeAudit = arr3.findIndex((item1) => item1.value == auditScope);

    let arr4 = this.dropdownShiftName;
    var checkShiftName = arr4.findIndex((item1) => item1.value == ShiftName);

    let arr5 = this.dropdownstatus;
    var checkIssueStatus = arr5.findIndex(
      (item1) => item1.value == issueStatus
    );

    let arr6 = this.dropdownRepeated;
    var checkReportDep = arr6.findIndex((item1) => item1.value == repeated);

    this.dataReturn = 1;
    if (checkobservation === -1) {
      this.rateEmpty = true;
      this.dataReturn = 0;
    }
    if (checkDepartment === -1) {
      this.departmentEmpty = true;
      this.dataReturn = 0;
    }
    if (checkArea === -1) {
      this.areaEmpty = true;
      this.dataReturn = 0;
    }
    if (checkTypeAudit === -1) {
      this.auditScopeEmpty = true;
      this.dataReturn = 0;
    }
    if (checkShiftName === -1) {
      this.shiftNameEmpty = true;
      this.dataReturn = 0;
    }
    if (checkIssueStatus === -1) {
      this.issueStatusEmpty = true;
      this.dataReturn = 0;
    }
    if (checkReportDep === -1) {
      this.repeatedEmpty = true;
      this.dataReturn = 0;
    }
  };

  /***** HG78 file import ENDS ****/

  csvonSubmitHR57 = (closeForm) => {
    const {
      onSubmitCSV,
      filters,
      location,
      auth: { user },
      form: { form },
    } = this.props;

    const { submission } = this.state;

    const date = moment(filters.date).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]");
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    let preventRedirectAfterSubmission = false;

    let submissionObject = {};
    if (form && form.type === "resource") {
      submissionObject = {
        ...submission,
        data: {
          ...submission.data,
        },
        state: "submitted",
      };
    } else {
      submissionObject = {
        ...submission,
        data: {
          ...submission.data,
          line: filters.line,
          plant: `${filters.plant}`,
          shift: filters.shift,
          date: date,
          gpid: user.data.gpid,
        },
        state: "submitted",
      };
    }

    let returningPage = currentPage;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.display === "commonForShift"
    ) {
      returningPage = 0;
      preventRedirectAfterSubmission = parseInt(currentPage) === 0;
    }

    // Avoid duplicate records on immediate submit after draft
    if (this.webform !== undefined) {
      if (
        this.webform.submission._id &&
        this.webform.submission._id !== this.state.submission._id
      ) {
        this.setState((prevState) => {
          return {
            submission: {
              ...prevState.submission,
              _id: this.webform.submission._id,
            },
          };
        });
        submissionObject._id = this.webform.submission._id;
      }
    }

    // Check for record to have required fields filled
    const invalidValues = this.getInvalidInSubmissionValues(submissionObject);

    if (invalidValues.length !== 0) {
      const formMessage = {
        text: "",
        type: "",
      };

      if (invalidValues.length === 1) {
        formMessage.text = `Field '${invalidValues.join(", ")}' is required.`;
      } else {
        formMessage.text = `Fields ${invalidValues.join(", ")} are required.`;
      }
      formMessage.type = "danger";

      if (!isEqual(formMessage, this.state.formMessage)) {
        window.scrollTo(0, 0);
        this.setState({ formMessage });
      }

      return;
    }

    // Check for record to be unique for selected time slot
    if (
      this.webform &&
      this.webform !== undefined &&
      (this.isUniqueInWizardFormSubmissions() ||
        this.isUniqueInFormSubmissions())
    ) {
      const invalidValuesUniqueInSubmissions =
        this.getInvalidUniqueInSubmissionsValues(submissionObject);

      if (invalidValuesUniqueInSubmissions.length === 0) {
        // Prevent app from creating draft once user press submit
        this.webform.options.saveDraft = false;
        this.webform.draftEnabled = false;
        this.webform.savingDraft = true;
        this.webform.triggerSaveDraft.cancel();
        onSubmitCSV(
          submissionObject,
          returningPage,
          preventRedirectAfterSubmission,
          this.webform,
          closeForm
        );

        this.setState({ confirmSubmit: true, isButtonLoading: true });
      } else {
        this.setState({
          formMessage: {
            text: `Record for selected ${invalidValuesUniqueInSubmissions.join(
              ", "
            )} already exists.`,
            type: "danger",
          },
        });
      }
    } else if (this.webform && this.webform !== undefined) {
      // Prevent app from creating draft once user press submit
      this.webform.options.saveDraft = false;
      this.webform.draftEnabled = false;
      this.webform.savingDraft = true;
      this.webform.triggerSaveDraft.cancel();
      onSubmitCSV(
        submissionObject,
        returningPage,
        preventRedirectAfterSubmission,
        this.webform,
        closeForm
      );
      var insertCount = this.state.insertCount;
    }
  };

  render() {
    const {
      hideComponents,
      onSubmit,
      errors,
      signature,
      location,
      match: {
        params: { formId },
      },
      languageParams: { language },
      form: { form, isActive, url },
      submissions: { submissions },
      otherformData,
      auth,
      filters,
      offlineQueue,
      userForms,
      approverFlowCheck,
    } = this.props;
    const {
      secondaryButtonPath,
      isTotalLoaded,
      isPrePopulated,
      isButtonLoading,
      formMessage,
      submission,
      isFinishedDequeuing,
    } = this.state;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    let prePopulate = false;
    if (
      location.search.includes("prePopulate") ||
      (form.components &&
        form.components[currentPage].properties &&
        form.components[currentPage].properties.prepopulateTabData)
    ) {
      prePopulate = true;
    }
    let shiftBasedFieldDisplay = false;
    if (
      form.components &&
      form.components[currentPage].properties &&
      form.components[currentPage].properties.shiftBasedFieldDisplay
    ) {
      shiftBasedFieldDisplay = true;
    }

    if (form && form.type === "resource") {
      eachComponent(
        form.components,
        (component) => {
          if (
            component.properties &&
            component.properties.disableComponentAdd === "true"
          ) {
            component.disabled = true;
          } else if (
            component.properties &&
            component.properties.disableComponent === "true" &&
            component.properties.disableComponentAdd === undefined
          ) {
            component.disabled = false;
          }
          if (component.properties && component.properties.defaultComponent) {
            component.defaultValue =
              filters[component.properties.defaultComponent];
          }
        },
        true
      );
    }

    const isOfflineSubmissionsForForm = isOfflineSubmissions(formId, filters);
    const isAbleToProcessQueue =
      offlinePlugin.submissionQueueLength() &&
      !offlinePlugin.dequeuing &&
      isOfflineSubmissionsForForm;
    const isReadyToDeque =
      navigator.onLine && !(isActive || offlineQueue.dequeuing);

    if (isAbleToProcessQueue && isReadyToDeque && !isFinishedDequeuing) {
      this.dequeueOfflineSubmissions(formId, false);
    }

    // if(this.state.ipAddress!=='' && (form && form.components && form.components[ currentPage ] &&
    //   form.components[ currentPage ].properties && form.components[ currentPage ].properties.webApiPopulate)){
    //   eachComponent(
    //     form.components,
    //      (component) => {

    //        if (component && component.key==='ipAddress' && component.defaultValue ===null) {
    //          component.defaultValue = this.state.ipAddress!==''?this.state.ipAddress:0;
    //        }
    //       });
    // }

    if (
      this.webform &&
      this.webform._form.display === "wizard" &&
      parseInt(this.webform.page) !== parseInt(currentPage)
    ) {
      this.setState({ isTotalLoaded: false });
      this.webform.setPage(currentPage);
      this.setFormMessage(false, true);
    }
    const isSubmitAndAdd =
      this.webform &&
      this.webform.component &&
      this.webform.component.properties &&
      this.webform.component.properties.prepopulateNewRecord;
    const primaryButtonText = !isSubmitAndAdd ? "Submit" : "Submit & Add New";
    const ternaryButtonText = "Submit & Close";

    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);
    const gpidSpecificApproverView = isGpidSpecificApprover(
      form,
      approverFlowCheck
    );
    const specificGpid = gpidSpecificView
      ? auth.user.data.gpid
      : gpidSpecificApproverView
      ? filters.trainerForm
      : undefined;

    let isShiftClosed =
      !!signature.submission.data &&
      !!signature.submission.data.formStatus &&
      signature.submission.data.formStatus !== "" &&
      (!gpidSpecificView ||
        (gpidSpecificView &&
          signature.submission.data.gpid === specificGpid)) &&
      (!gpidSpecificApproverView ||
        (gpidSpecificApproverView &&
          specificGpid !== undefined &&
          signature.submission.data.gpid === filters.trainerForm));
    // Check if there is signature in offline queue and disable editing
    if (offlinePlugin.submissionQueue.length) {
      isShiftClosed = signatureOffline(
        form._id,
        this.props.filters,
        specificGpid
      );
    }

    if (
      !userForms.departmentApprover &&
      Forms.HrForms.id === form._id &&
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.display === "commonForShift"
    ) {
      isShiftClosed = true;
    }
    if (
      approverFlowCheck &&
      approverFlowCheck.approverView &&
      isShiftClosed &&
      userForms &&
      userForms.approverForms &&
      userForms.approverForms.includes(form._id)
    ) {
      isShiftClosed =
        !!signature.submission.data &&
        !!signature.submission.data.formStatus &&
        signature.submission.data.formStatus === "Approved" &&
        !userForms.superApprover &&
        (!gpidSpecificApproverView ||
          (gpidSpecificApproverView &&
            specificGpid !== undefined &&
            signature.submission.data.gpid === filters.trainerForm));
    }
    const isBarCodePage =
      this.webform &&
      this.webform.component &&
      this.webform.component.properties &&
      this.webform.component.properties.getBarCode;
    if (form && form.type === "resource" && form._id === Forms.Line.id) {
      isShiftClosed = true;
    }

    const options = { ...this.props.options, readOnly: false };

    /* Check to disable submit button for tabs which are view only[Reports] -starts */
    /* Tab API property to set as  viewOnly=true */
    let viewOnlyTab = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.viewOnly === "true"
    ) {
      viewOnlyTab = true;
    }
    /** API - dataFlow==='shiftToHourTab'
     * This is for forms which need data submitted at a shift level tab to be populated to each hourly tab added/edited
     */
    let shiftToHourFetch = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.dataFlow === "shiftToHourTab"
    ) {
      shiftToHourFetch = true;
    }

    let previousDataFetch = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.previousReading
    ) {
      previousDataFetch = true;
    }
    /* Check to disable submit button for tabs which are view only[Reports] -ends */
    const shiftSel = this.props.filters.shift;

    /* If filtersubmission property is there then we have to filter the submissions
      to get totalValue */
    let isFilter = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.filterSubmission
    ) {
      isFilter = true;
    }

    let prePopData = {};
    if (prePopulate && submissions.length !== 0) {
      prePopData = formPrePopulate(
        form,
        submissions,
        isTotalLoaded,
        auth.user.data.gpid,
        gpidSpecificView
      );
    }

    const result = formLevelValidations(
      form,
      submissions,
      currentPage,
      isTotalLoaded,
      otherformData,
      shiftToHourFetch,
      isFilter,
      submission,
      shiftSel
    );

    /* Set total submission tot current form if any forms have total logic incurred
       Make sure that total field have custom property `totalFor`
       Where 'totalFor' value equals to corresponding field API key */
    // eslint-disable-next-line no-mixed-operators
    if (
      (this.webform !== undefined &&
        result !== undefined &&
        !isEmpty(result) &&
        !isTotalLoaded) ||
      (isFilter && !isTotalLoaded) ||
      (this.webform !== undefined &&
        !isEmpty(prePopData) &&
        prePopData !== undefined &&
        !isPrePopulated)
    ) {
      let totalSubmission = {};
      if (shiftToHourFetch || otherformData !== null) {
        totalSubmission = result;
      }
      if (prePopData) {
        totalSubmission = prePopData;
      }
      if (
        form.components[parseInt(currentPage)] &&
        form.components[parseInt(currentPage)].properties &&
        form.components[parseInt(currentPage)].properties.countCalculation
      ) {
        totalSubmission = result;
      }
      eachComponent(form.components, (component) => {
        if (component.properties && component.properties.totalFor) {
          if (result == null) {
            totalSubmission[component.key] = 0;
          } else {
            const totalForProperties = component.properties.totalFor.split(",");
            let total = 0;

            if (totalForProperties.length > 0) {
              totalForProperties.filter((item) => {
                Object.keys(result).filter((key) => {
                  if (key === item) {
                    total += result[item];
                  }
                });
              });
            }

            totalSubmission[component.key] = total;
          }
        }
      });

      setTimeout(() => {
        this.setState((prevState) => {
          return {
            isTotalLoaded: true,
            isPrePopulated: true,
            submission: {
              ...prevState.submission,
              data: {
                ...prevState.submission.data,
                ...totalSubmission,
              },
            },
          };
        });
      }, 500);
    }

    colorComponent(this.webform, submission, auth.user);
    if (shiftBasedFieldDisplay && this.webform !== undefined) {
      showShiftBasedFields(this.webform, filters.shift, auth.user.data);
    }
    let status = {};
    if (this.webform && this.webform.component) {
      status = formCompletionStatus(
        this.webform.component,
        submission,
        this.webform
      );
    }
    if (isActive || offlineQueue.dequeuing) {
      return <Loading />;
    }

    if ((submissions && submissions.length !== 0) || previousDataFetch) {
      this.getSubmissionToDisplay();
    }
    const isPrimaryButtonDisabled =
      !isBarCodePage &&
      (((primaryButtonText === "Submit" ||
        primaryButtonText === "Submit & Add New") &&
        isShiftClosed) ||
        viewOnlyTab ||
        !this.state.submission ||
        !this.state.submission._id ||
        status["complete-Percent"] !== 100);
    return (
      <>
        {" "}
        <div>
          {status["showProgress"] && (
            <div className={"sticky"}>
              {" "}
              <ProgressBar>
                <ProgressBar
                  animated
                  variant="success"
                  now={status["complete-Percent"]}
                  label={`${status["completed"]}`}
                  key={1}
                />
                <ProgressBar
                  animated
                  variant="danger"
                  now={status["empty-Percent"]}
                  label={`${status["empty"]}`}
                  key={2}
                />
              </ProgressBar>
              <Errors errors={errors} />
            </div>
          )}

          {formMessage.text && (
            <Message type={formMessage.type} text={formMessage.text} />
          )}
          {this.showLoaderCommon && (
            <div className="pep-c-loader">
              {" "}
              <div className="pep-c-iload"></div>{" "}
            </div>
          )}

          {this.state.showLoader ? (
            <div className="pep-c-loader">
              {" "}
              <div className="pep-c-iload"></div>{" "}
            </div>
          ) : (
            <>
              <div className={"pep-forms"}>
                <div
                  className={` ${
                    isShiftClosed ? "read-only  cursor-disable" : ""
                  }`}
                >
                  <Form
                    form={form}
                    url={url}
                    options={{
                      ...{
                        templates: {
                          wizardHeader: { form: " " },
                          wizardNav: { form: " " },
                        },
                        template: "bootstrap",
                        iconset: "fa",
                        saveDraft: false,
                        saveDraftThrottle: 10000,
                        skipDraftRestore: true,
                        buttonSettings: this.getButtonSettings(),
                        language: `${language}`,
                      },
                      ...options,
                    }}
                    hideComponents={hideComponents}
                    submission={submission}
                    pageToSet={isShiftClosed ? currentPage : null}
                    onChange={this.onChangeHandler}
                    onSubmit={onSubmit}
                    onComponentChange={this.onComponentChangeHandler}
                    onSaveDraftBegin={this.onSaveDraftBegin}
                    onSaveDraft={this.onSaveDraft}
                    ref={(instance) => {
                      if (instance && !this.webform) {
                        instance.createPromise.then(() => {
                          this.webform = instance.formio;

                          if (this.webform._form.display === "wizard") {
                            this.webform.setPage(currentPage);
                          }
                          this.webform.restoreDraft(auth.user._id);
                        });
                      }
                    }}
                  />
                  <div>
                    {/* <BarcodePicker/> */}
                    {isBarCodePage && <QuaggaBarCode />}
                  </div>
                </div>

                <BottomControlButtons
                  //  secondaryButtonText={ i18next.t(PageTexts.BACK) }
                  secondaryButtonText={
                    parseInt(currentPage) === 0 &&
                    approverFlowCheck &&
                    approverFlowCheck.approverView
                      ? i18next.t(PageTexts.BACK_TO_APPROVER)
                      : i18next.t(PageTexts.BACK)
                  }
                  primaryButtonText={i18next.t(primaryButtonText)}
                  ternaryButtonText={i18next.t(ternaryButtonText)}
                  secondaryButtonPath={secondaryButtonPath}
                  primaryButtonAction={this.onSubmitHandler}
                  ternaryButtonAction={this.onNewSubmitHandler}
                  isPrimaryButtonLoading={isButtonLoading}
                  isSaveAndClose={isSubmitAndAdd}
                  isPrimaryButtonDisabled={isPrimaryButtonDisabled}
                  fromData={this.props}
                  fromNames={Forms}
                  csvImportOutsourcingHead={this.csvImportHR57}
                  importCSVData={this.importCSVData}
                />
                {form.properties && form.properties.information && (
                  <ImportantInstructions text={form.properties.information} />
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    form: selectRoot("form", state),
    auth: selectRoot("auth", state),
    signature: selectRoot("signature", state),
    submissions: selectRoot("submissions", state),
    //submission: selectRoot('submission', state),
    userForms: selectRoot("userForms", state),
    formInformations: selectRoot("formInformations", state),

    previousDayReading: selectRoot("previousDayReading", state),
    errors: [selectError("form", state), selectError("submission", state)],
    options: {
      noAlerts: false,
    },
    languageParams: selectRoot("languageParams", state),
    filters: selectRoot("filters", state),
    offlineQueue: selectRoot("offlineQueue", state),
    approverFlowCheck: selectRoot("approverFlowCheck", state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { location, form } = ownProps;
  const { submissions } = ownProps;
  const currentPage = routeService.getCurrentPageNumberFromLocation(location);
  const language = routeService.getLanguageFromRoute();

  return {
    redirectDispatch: () => {
      dispatch(
        push(
          routeService.getPagePath.submission(
            language,
            ownProps.match.params.formId
          )
        )
      );
    },
    onSubmitCSV: (
      submission,
      returningPage,
      preventRedirectAfterSubmission,
      webform,
      closeForm
    ) => {
      let title = "";
      if (
        form.form &&
        form.form.components &&
        form.form.components[currentPage]
      ) {
        title = form.form.components[currentPage].title;
      }

      if (
        webform &&
        webform.form &&
        webform.components &&
        webform.component &&
        webform.component.properties &&
        webform.component.properties.codeCalculations
      ) {
        // eslint-disable-next-line no-param-reassign
        submission = getCalculatedKg(webform, submission);
      }
      const userName = submission.metadata.userName;

      submission.metadata = {
        viewPage: currentPage,
        recordType: "hourLevelAdded",
        isOnline: navigator.onLine,
        tabName: title !== undefined ? title : "",
        userName: userName,
      };

      if (form && form.id && Forms.FoodSafety.id.includes(form.id)) {
        submission.data.date =
          submission.data.findingDate !== "" &&
          submission.data.findingDate !== undefined
            ? submission.data.findingDate + "T00:00:00.000Z"
            : submission.data.date;
      }
      submission._id = "";
      // eslint-disable-next-line no-param-reassign
      submission = setShiftLevelRecord(
        form,
        submissions,
        submission,
        currentPage
      );

      dispatch(
        saveSubmission(
          "submission",
          submission,
          ownProps.match.params.formId,
          (err) => {
            const reportSubmission = {
              state: "submitted",
              data: {
                date: submission.data.date,
                shift: submission.data.shift,
                line: submission.data.line,
                plant: submission.data.plant,
                formId: ownProps.match.params.formId,
                submissionObject: JSON.stringify(submission.data),
                submissionMode: submission.metadata.recordType,
                page: submission.metadata.viewPage,
                isOnline: navigator.onLine.toString(),
                submissionStatus: "Success",
                submissionId: submission._id ? submission._id : "",
                gpid: submission.data.gpid ? submission.data.gpid : 0,
                deviceBrowser: navigator.userAgent ? navigator.userAgent : "",
                errorMessage: "",
              },
            };

            if (err) {
              reportSubmission.data.errorMessage = JSON.stringify(err);
              reportSubmission.state = "Not submitted";
              reportSubmission.data.submissionStatus = "Failed";
              dispatch(
                saveSubmission("submission", reportSubmission, Forms.Report.id)
              );
            }
            if (!err) {
              if (form.id === Resources.Language.id) {
                const langArr = mergeAndGetLanguageTranslations(
                  null,
                  submission
                );
                window.sessionStorage.setItem(
                  "langArray",
                  JSON.stringify(langArr)
                );
              }
              /** // KPI Reporting requirements  Code starts*/
              let shiftSubmission;
              if (
                form.form &&
                form.form.components[currentPage] &&
                form.form.components[currentPage].properties &&
                form.form.components[currentPage].properties.kpiReport
              ) {
                const tabName =
                  form.form.components[1] && form.form.components[1].title
                    ? form.form.components[1].title
                    : "";
                shiftSubmission = reportCalculations(
                  form.form.components[currentPage],
                  currentPage,
                  submissions,
                  submission,
                  "insert",
                  tabName
                );
              }
              if (shiftSubmission !== undefined) {
                dispatch(
                  saveSubmission(
                    "submission",
                    shiftSubmission,
                    ownProps.match.params.formId,
                    (err) => {
                      if (!err) {
                        dispatch(resetSubmissions("submission"));
                      }
                    }
                  )
                );
              }
              if (
                closeForm !== "close" &&
                form &&
                form.form &&
                form.form.components &&
                form.form.components[parseInt(currentPage)] &&
                form.form.components[parseInt(currentPage)].properties &&
                form.form.components[parseInt(currentPage)].properties
                  .prepopulateNewRecord
              ) {
                // Inserting comments for later use and avoid blank block
              } else if (preventRedirectAfterSubmission) {
                /*/ KPI Reporting requirements  Code ends */
                const defaultQuery = getSubmissionDefaultQuery(
                  form.form,
                  submission.data,
                  null,
                  ownProps.match.params.formId
                );
                dispatch(
                  getSubmissions(
                    "submissions",
                    1,
                    { ...defaultQuery },
                    ownProps.match.params.formId
                  )
                );
              } else {
                if (
                  (form &&
                    form.form &&
                    form.form.components &&
                    form.form.components[0] &&
                    form.form.components[0].properties &&
                    form.form.components[0].properties.display ===
                      "commonForShift") ||
                  parseInt(currentPage) !== 0
                ) {
                  dispatch(
                    push(
                      routeService.getPagePath.submissionPage(
                        language,
                        ownProps.match.params.formId,
                        returningPage
                      )
                    )
                  );
                }
              }
            }
          }
        )
      );
    },
    onSubmit: (
      submission,
      returningPage,
      preventRedirectAfterSubmission,
      webform,
      closeForm
    ) => {
      let title = "";
      if (
        form.form &&
        form.form.components &&
        form.form.components[currentPage]
      ) {
        title = form.form.components[currentPage].title;
      }

      if (
        webform &&
        webform.form &&
        webform.components &&
        webform.component &&
        webform.component.properties &&
        webform.component.properties.codeCalculations
      ) {
        // eslint-disable-next-line no-param-reassign
        submission = getCalculatedKg(webform, submission);
      }
      const userName = submission.metadata.userName;

      submission.metadata = {
        viewPage: currentPage,
        recordType: "hourLevelAdded",
        isOnline: navigator.onLine,
        tabName: title !== undefined ? title : "",
        userName: userName,
      };

      if (form && form.id && Forms.FoodSafety.id.includes(form.id)) {
        submission.data.date =
          submission.data.findingDate !== "" &&
          submission.data.findingDate !== undefined
            ? submission.data.findingDate + "T00:00:00.000Z"
            : submission.data.date;
      }
      // eslint-disable-next-line no-param-reassign
      submission = setShiftLevelRecord(
        form,
        submissions,
        submission,
        currentPage
      );
      dispatch(
        saveSubmission(
          "submission",
          submission,
          ownProps.match.params.formId,
          (err) => {
            const reportSubmission = {
              state: "submitted",
              data: {
                date: submission.data.date,
                shift: submission.data.shift,
                line: submission.data.line,
                plant: submission.data.plant,
                formId: ownProps.match.params.formId,
                submissionObject: JSON.stringify(submission.data),
                submissionMode: submission.metadata.recordType,
                page: submission.metadata.viewPage,
                isOnline: navigator.onLine.toString(),
                submissionStatus: "Success",
                submissionId: submission._id ? submission._id : "",
                gpid: submission.data.gpid ? submission.data.gpid : 0,
                deviceBrowser: navigator.userAgent ? navigator.userAgent : "",
                errorMessage: "",
              },
            };

            if (err) {
              reportSubmission.data.errorMessage = JSON.stringify(err);
              reportSubmission.state = "Not submitted";
              reportSubmission.data.submissionStatus = "Failed";
              dispatch(
                saveSubmission("submission", reportSubmission, Forms.Report.id)
              );
            }
            if (!err) {
              dispatch(resetSubmissions("submission"));
              if (form.id === Resources.Language.id) {
                const langArr = mergeAndGetLanguageTranslations(
                  null,
                  submission
                );
                window.sessionStorage.setItem(
                  "langArray",
                  JSON.stringify(langArr)
                );
              }
              /** // KPI Reporting requirements  Code starts*/
              let shiftSubmission;
              if (
                form.form &&
                form.form.components[currentPage] &&
                form.form.components[currentPage].properties &&
                form.form.components[currentPage].properties.kpiReport
              ) {
                const tabName =
                  form.form.components[1] && form.form.components[1].title
                    ? form.form.components[1].title
                    : "";
                shiftSubmission = reportCalculations(
                  form.form.components[currentPage],
                  currentPage,
                  submissions,
                  submission,
                  "insert",
                  tabName
                );
              }
              if (shiftSubmission !== undefined) {
                dispatch(
                  saveSubmission(
                    "submission",
                    shiftSubmission,
                    ownProps.match.params.formId,
                    (err) => {
                      if (!err) {
                        dispatch(resetSubmissions("submission"));
                      }
                    }
                  )
                );
              }
              if (
                closeForm !== "close" &&
                form &&
                form.form &&
                form.form.components &&
                form.form.components[parseInt(currentPage)] &&
                form.form.components[parseInt(currentPage)].properties &&
                form.form.components[parseInt(currentPage)].properties
                  .prepopulateNewRecord
              ) {
                dispatch(
                  push(
                    routeService.getPagePath.submissionNew(
                      language,
                      ownProps.match.params.formId
                    )
                  )
                );
              } else if (preventRedirectAfterSubmission) {
                /*/ KPI Reporting requirements  Code ends */
                const defaultQuery = getSubmissionDefaultQuery(
                  form.form,
                  submission.data,
                  null,
                  ownProps.match.params.formId
                );
                dispatch(
                  getSubmissions(
                    "submissions",
                    1,
                    { ...defaultQuery },
                    ownProps.match.params.formId
                  )
                );
              } else {
                if (
                  (form &&
                    form.form &&
                    form.form.components &&
                    form.form.components[0] &&
                    form.form.components[0].properties &&
                    form.form.components[0].properties.display ===
                      "commonForShift") ||
                  parseInt(currentPage) !== 0
                ) {
                  dispatch(
                    push(
                      routeService.getPagePath.submissionPage(
                        language,
                        ownProps.match.params.formId,
                        returningPage
                      )
                    )
                  );
                } else {
                  dispatch(
                    push(
                      routeService.getPagePath.submission(
                        language,
                        ownProps.match.params.formId
                      )
                    )
                  );
                }
              }
            }
          }
        )
      );
    },
    resetFormErrors: () => {
      dispatch(clearFormError("form"));
      dispatch(clearSubmissionError("submission"));
    },
    goToFormPreview: () => {
      const language = routeService.getLanguageFromRoute();

      dispatch(
        push(
          routeService.getPagePath.submissionPage(
            language,
            ownProps.match.params.formId,
            0
          )
        )
      );
    },
    startSubmissionDequeuing: () => dispatch(startSubmissionsDequeuing()),
    finishSubmissionDequeuing: () => dispatch(finishSubmissionsDequeuing()),
  };
};
View.propTypes = {
  auth: PropTypes.object,
  signatures: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  filters: PropTypes.object,
  submissions: PropTypes.object,
  otherformData: PropTypes.object,
  hideComponents: PropTypes.object,
  signature: PropTypes.object,
  errors: PropTypes.any,
  approverForms: PropTypes.object,
  options: PropTypes.object,
  getSignaturesData: PropTypes.func,
  languageParams: PropTypes.object,
  getSubmissions: PropTypes.func,
  resetSubmission: PropTypes.func,
  getSubmission: PropTypes.func,
  submission: PropTypes.object,
  getForm: PropTypes.func,
  onSubmit: PropTypes.func,
  goToSignaturePage: PropTypes.func,
  startSubmissionDequeuing: PropTypes.func,
  finishSubmissionDequeuing: PropTypes.func,
  goToFormPreview: PropTypes.func,
  form: PropTypes.object,
  isSaveAndClose: PropTypes.bool,
  approverFlowCheck: PropTypes.object.isRequired,
  userForms: PropTypes.object,
  formInformations: PropTypes.shape({
    rateConfigList: PropTypes.array,
  }),
  previousDayReading: PropTypes.shape({
    previousReading: PropTypes.any,
  }),
  offlineQueue: PropTypes.shape({
    dequeuing: PropTypes.bool,
  }),
  resetFormErrors: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
