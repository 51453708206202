/* eslint no-undef: 0 */
import { formConfig } from "./formConfig.js";
import { appConfig } from "./appconfig";

var PROJECT_URL = "";
const domainUrl = appConfig.REACT_APP_DOMAIN
  ? appConfig.REACT_APP_DOMAIN
  : "demo";

let API_URL = appConfig.REACT_APP_API_URL;

switch (domainUrl) {
  case "demo":
    PROJECT_URL = appConfig.REACT_APP_DEMO;
    break;
  case "dev":
    PROJECT_URL = appConfig.REACT_APP_DEVELOPMENT;
    break;
  case "production":
    PROJECT_URL = appConfig.REACT_APP_PRODUCTION;
    break;
  case "qa":
    PROJECT_URL = appConfig.REACT_APP_QA;
    break;
  default:
    PROJECT_URL = appConfig.REACT_APP_DEMO;
}

// eslint-disable-next-line prefer-const
let query = {};
window.location.search
  .substr(1)
  .split("&")
  .forEach(function (item) {
    query[item.split("=")[0]] =
      item.split("=")[1] && decodeURIComponent(item.split("=")[1]);
  });

PROJECT_URL = query.projectUrl || PROJECT_URL;
API_URL = query.apiUrl || API_URL;

export const AppConfig = {
  projectUrl: PROJECT_URL,
  apiUrl: API_URL,
  version: "0.8.6",
};

export const AuthConfig = {
  anonState: "/auth",
  authState: "/",
  login: {
    form: "user/login?live=1",
  },
  register: {
    form: "user/register",
  },
};

export const PageTexts = {
  IMPORTANT_INSTRUCTIONS_INTRO: "IMPORTANT_INSTRUCTIONS_INTRO",
  IMPORTANT_INSTRUCTIONS_TEXT: "IMPORTANT_INSTRUCTIONS_TEXT",
  DELETE_BUTTON_TEXT: "DELETE_BUTTON_TEXT",
  REJECT_BUTTON_TEXT: "REJECT_BUTTON_TEXT",

  CSV_EXPORT_BUTTON_TEXT: "CSV_EXPORT_BUTTON_TEXT",
  SUBMIT_FOR_APPROVAL: "SUBMIT_FOR_APPROVAL",
  BACK: "BACK",
  BACK_TO_APPROVER: "BACK_TO_APPROVER",
  APPROVE_FORM: "APPROVE_FORM",
  APPROVE_SHIFT: "AAPPROVE_SHIFT",
  SAVE: "SAVE",
  SUBMIT_AND_END_SHIFT: "SUBMIT_AND_END_SHIFT",
  COMMON_FOR_SHIFT: "COMMON_FOR_SHIFT",
};

export const UserMessages = {
  NO_RECORDS: "NO_RECORDS",
  NO_RECORDS_DAY: "NO_RECORDS_DAY",
  SHIFT_ENDED_TEXT: "SHIFT_ENDED_TEXT",
  SHIFT_APPROVED: "SHIFT_APPROVED",
  SHIFT_PENDING_APPROVAL: "SHIFT_PENDING_APPROVAL",
  RECORDS_NOT_ADDED: "RECORDS_NOT_ADDED",
  SHIFT_INSTRUCTIONS_TEXT: "SHIFT_INSTRUCTIONS_TEXT",
  SHIFT_APPROVE_INSTRUCTIONS_TEXT: "SHIFT_APPROVE_INSTRUCTIONS_TEXT",
  SHIFT_DRAFT_RECORD_ALERT: "SHIFT_DRAFT_RECORD_ALERT",
  SHIFT_DUPLICATE_RECORD_ALERT: "SHIFT_DUPLICATE_RECORD_ALERT",
  DELETE_MESSAGE_CONFIRM: "DELETE_MESSAGE_CONFIRM",
  REJECT_FORM_CONFIRM: "REJECT_FORM_CONFIRM",
  DRAFT_WILL_NOT_BE_SAVED: "DRAFT_WILL_NOT_BE_SAVED",
  ALREADY_EXISTS: "ALREADY_EXISTS",
  RECORD_SELECTED: "RECORD_SELECTED",
  DRAFT_SUBMISSION_PRESENT: "DRAFT_SUBMISSION_PRESENT",
  SELECT_DETAILS: "SELECT_DETAILS",
  DATA_SAVED: "DATA_SAVED",
};
export const Logout = {
  url: formConfig.Logout[appConfig.REACT_APP_DOMAIN],
};
export const logoutToken = {
  url: PROJECT_URL,
};
export const Forms = {
  GpidSpecificForms: {
    id: formConfig.GpidSpecificForms[appConfig.REACT_APP_DOMAIN],
  },
  FoodSafety: {
    id: formConfig.FoodSafety[appConfig.REACT_APP_DOMAIN],
  },
  InternalAuditReport: {
    id: formConfig.InternalAuditReport[appConfig.REACT_APP_DOMAIN],
  },
  PreviousFetch: {
    id: formConfig.PreviousFetch[appConfig.REACT_APP_DOMAIN],
  },
  HrForms: {
    id: formConfig.HrForms[appConfig.REACT_APP_DOMAIN],
  },
  Cleanliness: {
    id: formConfig.Cleanliness[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/cleanlinesfollowup`,
  },
  Signature: {
    id: formConfig.Signature[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/signature`,
  },
  CSV: {
    id: formConfig.CSV[appConfig.REACT_APP_DOMAIN],
  },
  Line: {
    id: formConfig.Line[appConfig.REACT_APP_DOMAIN],
  },
  Report: {
    id: formConfig.Report[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/inprogressreport`,
  },

  HeaderFilter: {
    id: formConfig.HeaderFilter[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/headerformsfilter`,
  },
  FryerControl: {
    id: formConfig.FryerControl[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/fryercontrol`,
  },
  ConsumptionReport: {
    id: formConfig.ConsumptionReport[appConfig.REACT_APP_DOMAIN],
  },
  Followuponachievingplan: {
    id: formConfig.Followuponachievingplan[appConfig.REACT_APP_DOMAIN],
  },
  PAE: {
    id: formConfig.PAE[appConfig.REACT_APP_DOMAIN],
  },
  CS59: {
    id: formConfig.CS59[appConfig.REACT_APP_DOMAIN],
  },
  CS59Ind: {
    id: formConfig.CS59Ind[appConfig.REACT_APP_DOMAIN],
  },
  PC7: {
    id: formConfig.PC7[appConfig.REACT_APP_DOMAIN],
  },
  PC12: {
    id: formConfig.PC12[appConfig.REACT_APP_DOMAIN],
  },
  PC12Ind: {
    id: formConfig.PC12Ind[appConfig.REACT_APP_DOMAIN],
  },
  PK29: {
    id: formConfig.PK29[appConfig.REACT_APP_DOMAIN],
  },
  PK33: {
    id: formConfig.PK33[appConfig.REACT_APP_DOMAIN],
  },
};

export const Resources = {
  Gpid: {
    id: formConfig.Gpid[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/gpid`,
  },
  Language: {
    id: formConfig.Language[appConfig.REACT_APP_DOMAIN],
  },
  UserAccess: {
    id: formConfig.UserAccess[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/useraccess`,
  },
  Shift: {
    id: formConfig.Shift[appConfig.REACT_APP_DOMAIN],
  },
  Roles: {
    id: formConfig.Roles[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/roles`,
  },
  Forms: {
    id: formConfig.Forms[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/forms`,
  },
  DataSet: {
    id: formConfig.DataSet[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/dataset`,
  },
  SKU: {
    id: formConfig.SKU[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-09sku`,
  },
  RateConfig: {
    id: formConfig.RateConfig[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-17ratesconfig`,
  },
  Line: {
    id: formConfig.Line[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-09sku`,
  },
  Flavor: {
    id: formConfig.Flavor[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-10flavor`,
  },
  SWTransactions: {
    id: formConfig.SWTransactions[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-12transactionsolidwaste`,
  },
  SWItemDetails: {
    id: formConfig.SWItemDetails[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-13solidwasteitemdetails`,
  },
  AuditStandard: {
    id: formConfig.AuditStandard[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin18auditstandard`,
  },
  TrainingDetails: {
    id: formConfig.TrainingDetails[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin19trainingdetails`,
  },
  GpidDetails: {
    id: formConfig.GpidDetails[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin20gpiddetails`,
  },
  CornDetails: {
    id: formConfig.CornDetails[appConfig.REACT_APP_DOMAIN],
    url: `${PROJECT_URL}/admin-22Corn`,
  },
};

export const UserRole = {
  Authenticated: {
    id: formConfig.Authenticated[appConfig.REACT_APP_DOMAIN],
  },
};
export const OfflinePluginConfig = {
  noAutomaticDequeue: true,
  queryFiltersTypes: {
    "data.plantId": "number",
  },
  notToShowDeletedOfflineSubmissions: true,
};
