/* eslint array-callback-return: 0 */
/* eslint no-undef: 0 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import FormioOfflineProject from "formio-plugin-offline";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  reportCalculations,
  getTotalInEditMode,
  colorComponent,
  rateConfigComponent,
  formCompletionStatus,
  getCalculatedKg,
  isGpidSpecific,
  isGpidSpecificApprover,
} from "../../helper";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  selectError,
  Errors,
} from "react-formio";
import Form from "../../../../../containers/Form";
import { i18next } from "../../../../../i18n";
import moment from "moment-timezone";
import { push } from "connected-react-router";
import BottomControlButtons from "../../../../../containers/BottomControlButtons/BottomControlButtons";
import ImportantInstructions from "../../../../../containers/ImportantInstructions/ImportantInstructions";
import routeService from "../../../../../services/routeService";
import {
  PageTexts,
  UserMessages,
  Forms,
  Resources,
  AppConfig,
  OfflinePluginConfig,
} from "../../../../../config";
import Message from "../../../../../containers/Message";
import { eachComponent } from "formiojs/utils/formUtils";
import {
  mergeAndGetLanguageTranslations,
  signatureOffline,
} from "../../../../../utils";

const offlinePlugin = FormioOfflineProject.getInstance(
  AppConfig.projectUrl,
  AppConfig.projectUrl,
  OfflinePluginConfig
);

const Edit = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      tabsLength: 0,
      submission: {},
      draftSubmission: { isDraftSubmission: true, invalidData: [] },
      editingSubmittedRecord: true,
      confirmSubmit: false,
      isLoaded: false,
      isButtonDisabled: false,
      disablingDone: false,
      fieldUpdated: false,
      statusBar: {},
      formMessage: {
        text: "",
        type: "",
      },
    };
  }

  componentWillUnmount() {
    delete this.webform;
  }

  componentDidUpdate() {
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const {
      submission: { submission },
      submissions: { submissions },
      form: { form },
      formInformations,
    } = this.props;

    /*EP-01 form cascading on edit 
    seasoning and size will updated based on product selected*/
    const filterValues =
      JSON.parse(window.sessionStorage.getItem("filters")) || {};
    const plantId =
      filterValues.plant !== undefined && filterValues.plant !== ""
        ? filterValues.plant
        : auth.user.data.plantId;
    let selectedProduct;
    let seasoning = [];
    let sizes = [];
    let selectedPrdtProperty;
    if (
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].components &&
      form.components[parseInt(currentPage)].components.length !== 0
    ) {
      eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (submission.data) {
            if (
              component.properties &&
              component.properties.isTargetSelect &&
              component.properties.isTargetSelect != undefined
            ) {
              selectedProduct = submission.data[component.key];
              formInformations[component.properties.masterData]
                .filter(
                  (item) =>
                    item.data &&
                    item.data[component.properties.skuFlavorSource] ===
                      selectedProduct
                )
                .map((item) => {
                  if (
                    component.properties &&
                    component.properties.isTargetSelect &&
                    plantId === item.data["plantId"][0]
                  ) {
                    seasoning.push(
                      item.data[component.properties.isTargetSelect]
                    );
                  }
                });
            }
            if (
              component.properties &&
              component.properties.skuListTarget &&
              component.properties.isSize
            ) {
              formInformations[component.properties.masterData]
                .filter(
                  (item) =>
                    item.data &&
                    item.data[component.properties.skuListTarget] ===
                      selectedProduct
                )
                .map((item) => {
                  if (
                    component.properties.isSize &&
                    plantId === item.data["plantId"][0]
                  ) {
                    sizes.push(item.data[component.properties.isSize]);
                  }
                });
            }
            /*PK-13 submission data update
          if FinancialWeight or Casecountbags updated from SKU form  */
            if (component.properties && component.properties.SKUSelected) {
              let prepopulatedPrdt = submission.data[component.key];
              selectedPrdtProperty = formInformations[
                component.properties.masterData
              ].filter(
                (item) =>
                  item.data &&
                  item.data[component.properties.SKUSelected] ===
                    prepopulatedPrdt
              );
            }
            if (
              component.properties &&
              component.properties.skuFlavorTarget &&
              selectedPrdtProperty &&
              selectedPrdtProperty.length != 0
            ) {
              selectedPrdtProperty.map((item) => {
                if (
                  component.properties &&
                  component.properties.skuFlavorTarget &&
                  component.key === component.properties.skuFlavorTarget
                ) {
                  submission.data[component.key] =
                    item.data[component.properties.skuFlavorTarget];
                }
              });
            }
          }
        }
      );
      sizes = sizes.filter((element, index, self) => {
        return index == self.indexOf(element);
      });

      /* EP-01 cascaded data pushing back to form */
      eachComponent(
        form.components[parseInt(currentPage)].components,
        (component) => {
          if (
            component.properties &&
            component.type === "select" &&
            (component.properties.isSeasoning || component.properties.isSize)
          ) {
            component.data.values = [];
            if (
              component.properties &&
              component.properties.isSeasoning &&
              component.type === "select"
            ) {
              seasoning.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
              });
            }
            if (
              component.properties &&
              component.type === "select" &&
              component.properties.isSize
            ) {
              sizes.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
              });
            }
          }
        }
      );
    }
  }
  onChangeHandler = (change) => {
    const {
      location,
      filters,
      auth: { user },
      form: { form },
      languageParams: { language },
      formInformations,
    } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const date = moment(filters.date).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]");
    const decimalIssue = false;
    if (change.changed) {
      delete change.changed.flags;
      delete change.changed.instance;
    }

    if (this.webform) {
      this.webform.submission.metadata = {
        viewPage: currentPage,
        isOnline: navigator.onLine,
        userName: user.data.name,
      };
      if (
        form &&
        form.components &&
        form.components[parseInt(currentPage)] &&
        form.components[parseInt(currentPage)].properties &&
        form.components[parseInt(currentPage)].properties.display ===
          "commonForShift"
      ) {
        this.webform.submission.metadata.recordType =
          "commonForShiftConflictEdit";
      }
      if (!(form && form.type === "resource")) {
        this.webform.submission.data.date = date;
        this.webform.submission.data.shift = filters.shift;
        this.webform.submission.data.line = filters.line;
        this.webform.submission.data.plant = `${filters.plant}`;
        this.webform.submission.data.gpid = user.data.gpid;
      }

      /**SKU-Flavor table  look up  code starts
       * Add API key for the component  , based on which SKU/Flavor value is to be fetched from master data
       * Eg PK-33 Product Type filed is the SKU dropdown, so add APIKEY for the select component
       *  as "skuFlavorSource=productType" --productType is the key in SKU master table based on which values are fetched
       * Add APIKEY "masterData="SkuList/FlavorList" , depending on the table that form needs to refer.
       * The fields to be populated, based on the sku value have to be given the APIKey "skuFlavorTarget"
       * and the value to be the corresponding column from the masterTable
       * In PK-33 targetWeight is the field which need to be populated and the master data column for same is tgWeight
       * "skuFlavorTarget=tgWeight"
       * API key productChange is to identify change happen on product deropdown
       * API key isSeasoning & isSize is to identify seasoning and size dropdowns
       */

      if (
        change.changed &&
        change.changed.component &&
        change.changed.component.properties &&
        change.changed.component.properties.skuFlavorSource &&
        change.changed.component.properties.masterData
      ) {
        const filterValues =
          JSON.parse(window.sessionStorage.getItem("filters")) || {};
        const plantId =
          filterValues.plant !== undefined && filterValues.plant !== ""
            ? filterValues.plant
            : auth.user.data.plantId;
        let seasoning = [];
        let sizes = [];
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.properties.skuFlavorTarget &&
            component.properties.masterData &&
            component.properties.isSeasoning === undefined &&
            change.changed.component.properties.masterData ===
              component.properties.masterData &&
            (component.properties.skuFlavorOrigin === undefined ||
              component.properties.skuFlavorOrigin === "" ||
              (component.properties.skuFlavorOrigin &&
                change.changed.component.key ===
                  component.properties.skuFlavorOrigin))
          ) {
            this.webform.submission.data[component.key] =
              component.type === "number" ? 0 : "";
          }
        });

        eachComponent(this.webform.component.components, (component) => {
          const changeValue =
            change.data[change.changed.component.key] !== undefined
              ? change.data[change.changed.component.key]
              : change.changed.value;
          if (
            component.properties &&
            (component.properties.isSize ||
              component.properties.isSeasoning ||
              component.properties.isSizeCases) &&
            change.changed.component.properties.productChange
          ) {
            this.webform.submission.data[component.key] = "";
          }
          if (
            component.properties &&
            component.properties.skuFlavorSource &&
            formInformations[component.properties.masterData] !== undefined
          ) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  item.data["plantId"] &&
                  item.data["plantId"].includes(filters.plant) &&
                  (item.data[component.properties.skuFlavorSource] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) === item.data[component.properties.skuFlavorSource])) &&
                  component.key === change.changed.component.key
              )
              .map((item) => {
                if (
                  component.properties &&
                  component.properties.isTargetSelect &&
                  plantId === item.data["plantId"][0]
                ) {
                  seasoning.push(
                    item.data[component.properties.isTargetSelect]
                  );
                }
                eachComponent(
                  this.webform.component.components,
                  (component) => {
                    if (
                      component.properties &&
                      component.properties.skuFlavorTarget &&
                      component.properties.masterData &&
                      change.changed.component.properties.masterData ===
                        component.properties.masterData &&
                      (component.properties.skuFlavorOrigin === undefined ||
                        component.properties.skuFlavorOrigin === "" ||
                        (component.properties.skuFlavorOrigin &&
                          change.changed.component.key ===
                            component.properties.skuFlavorOrigin))
                    ) {
                      /**887686- Mourad requirement to read translated data for item name and transaction name from solid recources
                       * Created field in table with the fieldname-lang format  eg: itemName-ar transactionName-ar and mapped acrodingly
                       */
                      const translatedField =
                        component.properties.skuFlavorTarget + "-" + language;
                      if (component.type != "select") {
                        change.data[component.key] =
                          item.data[translatedField] !== undefined &&
                          item.data[translatedField] !== ""
                            ? item.data[translatedField]
                            : item.data[component.properties.skuFlavorTarget]
                            ? item.data[component.properties.skuFlavorTarget]
                            : component.type === "number"
                            ? 0
                            : "";
                      }
                    }
                  }
                );
              });
          }
          if (
            component.properties &&
            component.properties.skuListTarget &&
            component.properties.masterData &&
            component.properties.isSize &&
            change.changed.component.properties.productChange &&
            formInformations[component.properties.masterData] !== undefined
          ) {
            formInformations[component.properties.masterData]
              .filter(
                (item) =>
                  item.data &&
                  (item.data[component.properties.skuListTarget] ===
                    changeValue ||
                    (change.changed.component.properties.clauseTrim &&
                      changeValue.substring(
                        0,
                        parseInt(change.changed.component.properties.clauseTrim)
                      ) === item.data[component.properties.skuListTarget]))
              )
              .map((item) => {
                if (
                  component.properties.isSize &&
                  plantId === item.data["plantId"][0]
                ) {
                  sizes.push(item.data[component.properties.isSize]);
                }
              });
          }
        });
        sizes = sizes.filter((element, index, self) => {
          return index == self.indexOf(element);
        });
        eachComponent(this.webform.component.components, (component) => {
          if (
            component.properties &&
            component.type === "select" &&
            change.changed.component.properties.productChange &&
            (component.properties.isSeasoning || component.properties.isSize)
          ) {
            this.webform.rebuild();
            if (
              component.properties &&
              component.type === "select" &&
              component.properties.isSeasoning
            ) {
              let selectedComp = this.webform.getComponent(component.key);
              component.data.values = [];
              selectedComp.component.data.values = [];
              seasoning.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
                selectedComp.component.data.values.push({
                  label: data,
                  value: data,
                });
              });
            }
            if (
              component.properties &&
              component.type === "select" &&
              component.properties.isSize
            ) {
              let selectedComp = this.webform.getComponent(component.key);
              component.data.values = [];
              selectedComp.component.data.values = [];
              sizes.forEach(function (data) {
                component.data.values.push({ label: data, value: data });
                selectedComp.component.data.values.push({
                  label: data,
                  value: data,
                });
              });
            }
          }
        });
      }

      /**SKU-Flavor table  look up  code ends */
      const rateConfig = rateConfigComponent(
        this.webform,
        change,
        formInformations.rateConfigList
      );
      if (
        rateConfig != "" &&
        rateConfig !== undefined &&
        change.changed &&
        change.changed.component &&
        change.changed.component.properties.colorCodeSrc === undefined
      ) {
        // eslint-disable-next-line no-return-assign
        Object.entries(rateConfig).forEach(
          ([key, value]) => (this.webform.submission.data[key] = value)
        );
      }

      if (
        change &&
        change.changed &&
        change.changed.component &&
        change.changed.component.type === "number" &&
        change.changed.value &&
        this.state &&
        this.state.submission &&
        this.state.submission.data
      ) {
        let prevValue =
          this.state.submission.data[change.changed.component.key];
        if (Number.isInteger(prevValue) || prevValue === 0) {
          prevValue += ".";
          return prevValue;
        }
      }
    }

    if (change.changed) {
      let draftSubmission = this.state.draftSubmission;
      const editingSubmittedRecord = this.state.editingSubmittedRecord;

      if (
        this.isUniqueInWizardFormSubmissions() ||
        this.isUniqueInFormSubmissions()
      ) {
        const invalidValues = [];

        if (invalidValues.length === 0) {
          if (
            !this.webform.savingDraft ||
            this.state.draftSubmission.invalidData.length > 0
          ) {
            this.webform.draftEnabled = true;
            this.webform.savingDraft = false;

            if (!editingSubmittedRecord) {
              this.webform.saveDraft();
            }
          }
          draftSubmission = { isDraftSubmission: true, invalidData: [] };
        } else {
          this.webform.options.saveDraft = false;
          this.webform.draftEnabled = false;
          this.webform.savingDraft = true;
          draftSubmission = {
            isDraftSubmission: false,
            invalidData: invalidValues,
          };
        }
        this.setState({ draftSubmission: draftSubmission });
      } else {
        if (this.webform && !this.webform.options.saveDraft) {
          this.webform.options.saveDraft = true;
          this.webform.draftEnabled = true;
          this.webform.savingDraft = false;

          if (!editingSubmittedRecord) {
            this.webform.saveDraft();
          }
        }
      }

      if (this.webform && this.webform.component) {
        const statusBar = formCompletionStatus(
          this.webform.component,
          change,
          this.webform
        );
        if (statusBar.showProgress) {
          this.setState({ statusBar: statusBar });
        }
      }

      if (
        //   !isEqual(change.data, this.state.submission.data) &&
        (change.changed.component &&
          change.changed.component.key !== "gpid" &&
          change.changed.component.key !== "date" &&
          change.changed.component.key !== "shift" &&
          change.changed.component.key !== "line" &&
          change.changed.component.key !== "plant" &&
          ((change.changed.value !== "" &&
            change.changed.component.conditional.show === null) ||
            (change.changed.value === "" &&
              change.changed.component.properties &&
              change.changed.component.properties.masterData))) ||
        (change.changed.component &&
          change.changed.component.key === "date" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "shift" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "line" &&
          change.changed.value !== "") ||
        (change.changed.component &&
          change.changed.component.key === "gpid" &&
          change.changed.component.hidden !== true) ||
        (change.changed.component &&
          change.changed.component.key === "plant" &&
          change.changed.value !== "")
      ) {
        let state =
          this.state.submission.state === "submitted" ? "submitted" : "draft";
        if (
          (change.changed.value ||
            change.changed.value === "" ||
            change.changed.value === null) &&
          !change.changed.component.hidden &&
          change.changed.component.properties &&
          change.changed.component.properties.totalFor === undefined &&
          change.changed.component.properties.countCriteria === undefined
        ) {
          state = "draft";
          if (
            change.changed.component.type === "number" &&
            change.changed.value === null
          ) {
            change.data[change.changed.component.key] = undefined;
          }
        }

        this.setState((prevState) => {
          return {
            submission: {
              ...prevState.submission,
              ...change,
              data: {
                ...prevState.submission.data,
                ...change.data,
              },
            },
            editingSubmittedRecord: false,
          };
        });

        let viewOnlyTab = false;
        let informAboutDraft = false;
        if (
          this.webform &&
          this.webform.component.properties &&
          this.webform.component.properties.viewOnly === "true"
        ) {
          viewOnlyTab = true;
        }
        if (state === "draft" && !viewOnlyTab) {
          informAboutDraft = true;
        }

        this.setFormMessage(informAboutDraft, decimalIssue);

        this.setState({ fieldUpdated: true }); //added for displaying draft message once edited.
        //  window.scrollTo(0, 0);
      }
    }
  };

  onComponentChangeHandler = (newComponent) => {
    if (newComponent.component.type === "tabs") {
      this.setState((prevState) => {
        return {
          ...prevState,
          currentTab: newComponent.instance.currentTab,
          tabsLength: newComponent.instance.tabs.length,
        };
      });
    }
  };

  getRequiredFields() {
    let requiredKeysString = [];

    if (this.webform) {
      if (this.webform.component.properties) {
        requiredKeysString =
          this.webform.component.properties.requiredInSubmission;
      } else if (this.webform.form.properties) {
        requiredKeysString = this.webform.form.properties.requiredInSubmission;
      }
    }

    return requiredKeysString && requiredKeysString.length
      ? requiredKeysString.split(",")
      : [];
  }

  getInvalidInSubmissionValues(newSubmission) {
    const requiredValues = this.getRequiredFields();

    return requiredValues.filter((uniqueValue) => {
      return !newSubmission.data[uniqueValue];
    });
  }

  isUniqueInWizardFormSubmissions() {
    return (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.uniqueInSubmissions
    );
  }

  isUniqueInFormSubmissions() {
    return (
      this.webform &&
      this.webform.form.properties &&
      this.webform.form.properties.uniqueInSubmissions
    );
  }

  getUniqueInSubmissionsKeys() {
    let uniqueKeysString;

    if (this.isUniqueInWizardFormSubmissions()) {
      uniqueKeysString = this.webform.component.properties.uniqueInSubmissions;
    }
    if (this.isUniqueInFormSubmissions()) {
      uniqueKeysString = this.webform.form.properties.uniqueInSubmissions;
    }

    return uniqueKeysString.length ? uniqueKeysString.split(",") : [];
  }

  getInvalidUniqueInSubmissionsValues(newSubmission) {
    const {
      submissions: { submissions },
    } = this.props;
    const uniqueValues = this.getUniqueInSubmissionsKeys();

    return uniqueValues.filter((uniqueValue) =>
      submissions.some((sub) => {
        const isValuesEqual =
          sub.data[uniqueValue] === newSubmission.data[uniqueValue] &&
          sub._id !== newSubmission._id;

        return (
          sub.data[uniqueValue] &&
          isValuesEqual &&
          sub._id !== newSubmission._id &&
          sub.metadata &&
          newSubmission.metadata &&
          sub.metadata.viewPage === newSubmission.metadata.viewPage
        );
      })
    );
  }

  getFormDataToDisplay(otherformData, shiftToHrTab, currentPage, isFilter) {
    const {
      submission: { submission },
      submissions: { submissions },
      form: { form },
    } = this.props;
    const { submission: stateSubmission } = this.state;

    const submissionFromServer = submission;
    let mergedSubmission;

    /* Set data from `other forms` components to display in edit view of a record
       The FROM form component and TO form component should have property set as "formPick"
       TO Form - Property value should be the API  key of the FROM form component eg:formPick-paestatus
       FROM Form-Property value should be the API  key of the TO form component eg:formPick-pae*/

    if (submission.data) {
      let totalSubmission = {};

      if (shiftToHrTab) {
        submissions
          .filter(
            (item) =>
              item.metadata &&
              item.metadata.viewPage &&
              item.metadata.viewPage !== currentPage
          )
          .map((item) => {
            eachComponent(form.components, (component) => {
              if (component.properties && component.properties.displayFrom) {
                totalSubmission[component.key] =
                  item.data[component.properties.displayFrom];
              }
            });
          });
      }

      if (otherformData) {
        otherformData
          .filter((item) => item.data && item.data.shift)
          .map((item) => {
            eachComponent(form.components, (component) => {
              if (component.properties && component.properties.formPick) {
                totalSubmission[component.key] =
                  item.data[component.properties.formPick];
              }
            });
          });
      }

      if (isFilter && stateSubmission.data) {
        totalSubmission = getTotalInEditMode(
          form,
          stateSubmission,
          submissions,
          currentPage
        );
      }

      mergedSubmission = {
        ...stateSubmission,
        ...submissionFromServer,
        data: {
          ...submissionFromServer.data,
          ...stateSubmission.data,
          ...totalSubmission,
        },
      };
      // Check for default component value override
      eachComponent(form.components, (component) => {
        if (
          component.defaultValue === submission.data[component.key] &&
          submission.changed &&
          component.defaultValue !== submission.changed.value
        ) {
          mergedSubmission.data[component.key] =
            submissionFromServer.data[component.key];
        }
      });

      // Check for empty value manual override
      if (
        submission.changed &&
        (submission.changed.value === "" ||
          submission.changed.value === undefined ||
          submission.changed.value === null)
      ) {
        mergedSubmission.data[submission.changed.component.key] =
          submission.changed.value;
      }
    }

    return mergedSubmission;
  }
  setFormMessage(informAboutDraft, decimalAlert) {
    const { draftSubmission, confirmSubmit } = this.state;
    const formMessage = {
      text: "",
      type: "",
    };
    if (
      this.state.fieldUpdated &&
      informAboutDraft &&
      !(
        this.state.formMessage.text &&
        this.state.formMessage.text.includes("required.")
      )
    ) {
      formMessage.text = UserMessages.DRAFT_SUBMISSION_PRESENT;
      formMessage.type = "warning";
    } else if (!draftSubmission.isDraftSubmission) {
      formMessage.text = `The draft will not be saved. Record for selected ${draftSubmission.invalidData.join(
        ", "
      )} already exists.`;
      formMessage.type = "warning";
    } else if (confirmSubmit) {
      formMessage.text = UserMessages.DATA_SAVED;
      formMessage.type = "info";
    }
    if (decimalAlert) {
      formMessage.text =
        "Decimal point is removed on backspace from the numeric field. Please re-enter decimal if required";
      formMessage.type = "warning";
    }

    if (formMessage.text && !isEqual(formMessage, this.state.formMessage)) {
      this.setState({ formMessage });
    }
  }

  onSubmitHandler = () => {
    const {
      onSubmit,
      filters,
      auth: { user },
      form: { form },
      submissions: { submissions },
      submission: { submission: propsSubmission },
      location,
    } = this.props;
    const { submission } = this.state;
    const date = moment(filters.date).format("YYYY-MM-DD[T]HH:mm:ss[.000Z]");
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    let submissionObject = {};
    if (form && form.type === "resource") {
      submissionObject = {
        ...submission,
        data: {
          ...submission.data,
        },
        state: "submitted",
      };
    } else {
      submissionObject = {
        ...propsSubmission,
        ...submission,
        data: {
          ...submission.data,
          line: filters.line,
          plant: `${filters.plant}`,
          shift: filters.shift,
          date,
          gpid: user.data.gpid,
        },
        state: "submitted",
      };
    }
    if (submissionObject.metadata === undefined) {
      submissionObject.metadata = {
        viewPage: currentPage,
        isOnline: navigator.onLine,
      };
    }
    // Check for record to have required fields filled
    const invalidValues = this.getInvalidInSubmissionValues(submissionObject);

    if (invalidValues.length !== 0) {
      const formMessage = {
        text: "",
        type: "",
      };
      if (invalidValues.length === 1) {
        formMessage.text = `Field '${invalidValues.join(", ")}' is required.`;
      } else {
        formMessage.text = `Fields ${invalidValues.join(", ")} are required.`;
      }
      formMessage.type = "danger";

      if (!isEqual(formMessage, this.state.formMessage)) {
        window.scrollTo(0, 0);
        this.setState({ formMessage });
      }

      return;
    }
    if (
      this.isUniqueInWizardFormSubmissions() ||
      this.isUniqueInFormSubmissions()
    ) {
      const invalidValuesUniqueInSubmissions =
        this.getInvalidUniqueInSubmissionsValues(submissionObject);

      if (invalidValuesUniqueInSubmissions.length === 0) {
        // Prevent app from creating draft once user press submit
        this.webform.options.saveDraft = false;
        this.webform.draftEnabled = false;

        this.setState({ editingSubmittedRecord: true });
        onSubmit(
          submissionObject,
          form.components[currentPage],
          submissions,
          form.components[1],
          this.webform
        );
        this.setState({ confirmSubmit: true });
      } else {
        this.setState({
          formMessage: {
            text: `Record for selected ${invalidValuesUniqueInSubmissions.join(
              ", "
            )} already exists.`,
            type: "danger",
          },
        });
      }
    } else {
      // Prevent app from creating draft once user press submit
      this.webform.options.saveDraft = false;
      this.webform.draftEnabled = false;

      this.setState({ editingSubmittedRecord: true });
      onSubmit(
        submissionObject,
        form.components[currentPage],
        submissions,
        form.components[1],
        this.webform
      );
      this.setState(
        { confirmSubmit: true },
        () => {
          this.setFormMessage(); //added for submit message
        },
        window.scrollTo(0, 0)
      );
    }
  };

  render() {
    const {
      hideComponents,
      onSubmit,
      options,
      errors,
      match: {
        params: { formId },
      },
      location,
      languageParams: { language },
      form: { form, isActive: isFormActive },
      submission: { isActive: isSubActive, url, submission },
      auth,
      submissions: { submissions },
      otherformData,
      filters,
      userForms,
      approverFlowCheck,
      signatures: { submissions: signatures },
    } = this.props;
    const {
      currentTab,
      tabsLength,
      formMessage,
      isLoaded,
      submission: stateSubmission,
    } = this.state;

    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    const buttonSettings = {
      showPrevious: false,
      showNext: false,
      showSubmit: false,
      showCancel: false,
    };

    /** API - dataFlow==='shiftToHourTab'
     * This is for forms which need data submitted at a shift level tab to be populated to each hourly tab added/edited
     */

    if (form && form.type === "resource") {
      eachComponent(
        form.components,
        (component) => {
          if (
            component.properties &&
            component.properties.disableComponent === "true"
          ) {
            component.disabled = true;
          }
        },
        true
      );
    }

    let shiftToHrTab = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.dataFlow === "shiftToHourTab"
    ) {
      shiftToHrTab = true;
    }

    let isFilter = false;
    if (
      this.webform &&
      this.webform.component.properties &&
      this.webform.component.properties.filterSubmission
    ) {
      isFilter = true;
    }
    let enableDeleteButton = true;
    let shiftLevelSubmissions = [];
    if (
      submissions &&
      submissions.length > 0 &&
      form &&
      form.components &&
      form.components[parseInt(currentPage)] &&
      form.components[parseInt(currentPage)].properties &&
      form.components[parseInt(currentPage)].properties.display ===
        "commonForShift"
    ) {
      shiftLevelSubmissions = submissions
        .filter(
          (item) =>
            item.metadata &&
            item.metadata.viewPage === currentPage &&
            item.data &&
            item.data.shift &&
            filters.shift === item.data.shift
        )
        .map((item) => {
          return item;
        });
      if (shiftLevelSubmissions.length === 1) {
        enableDeleteButton = false;
      }
    }

    const formDataToDisplay =
      (shiftToHrTab && submissions) ||
      ((otherformData || isFilter) && submission && submission.data)
        ? this.getFormDataToDisplay(
            otherformData,
            shiftToHrTab,
            currentPage,
            isFilter
          )
        : stateSubmission !== undefined && stateSubmission.data
        ? stateSubmission
        : submission;

    if (
      (otherformData || shiftToHrTab || isFilter) &&
      this.webform &&
      formDataToDisplay !== undefined &&
      !isLoaded
    ) {
      this.setState((prevState) => {
        return {
          isLoaded: true,
          submission: {
            ...prevState.submission,
            data: {
              ...prevState.submission.data,
              ...formDataToDisplay.data,
            },
          },
        };
      });
    }

    colorComponent(this.webform, formDataToDisplay);
    let status = {};
    if (this.webform && this.webform.component) {
      status = formCompletionStatus(
        this.webform.component,
        formDataToDisplay,
        this.webform
      );
    }
    const primaryButtonText =
      currentTab !== tabsLength - 1
        ? PageTexts.SAVE
        : auth.is.approver
        ? "Verified"
        : PageTexts.SUBMIT_FOR_APPROVAL;
    const primaryButtonAction =
      currentTab !== tabsLength - 1
        ? this.onSubmitHandler
        : auth.is.approver
        ? () => {}
        : () => this.onSubmitForApprovalHandler;

    if (isFormActive || isSubActive) {
      return (
        <div className="pep-c-loader">
          <div className="pep-c-iload" />
        </div>
      );
    }

    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);
    const gpidSpecificApproverView = isGpidSpecificApprover(
      form,
      approverFlowCheck
    );
    const specificGpid = gpidSpecificView
      ? auth.user.data.gpid
      : gpidSpecificApproverView
      ? filters.trainerForm
      : undefined;

    let isBlocked = false;
    if (signatures) {
      isBlocked = signatures.some(
        (signature) =>
          signature.data.formId === form._id &&
          signature.data.formStatus &&
          signature.data.formStatus !== "" &&
          (!gpidSpecificView ||
            (gpidSpecificView &&
              signature.data.gpid === auth.user.data.gpid)) &&
          (!gpidSpecificApproverView ||
            (gpidSpecificApproverView &&
              specificGpid !== undefined &&
              signature.data.gpid === filters.trainerForm))
      );
    }

    if (offlinePlugin.submissionQueue.length) {
      isBlocked = signatureOffline(form._id, this.props.filters, specificGpid);
    }
    if (userForms.departmentApprover && Forms.HrForms.id === form._id) {
      isBlocked = true;
    }
    if (
      approverFlowCheck &&
      approverFlowCheck.approverView &&
      isBlocked &&
      userForms &&
      userForms.approverForms &&
      userForms.approverForms.includes(formId) &&
      (form.properties === undefined ||
        (form.properties && form.properties.gpidSpecificForm === undefined))
    ) {
      isBlocked = false;
      if (!userForms.superApprover && signatures) {
        isBlocked = signatures.some(
          (signature) =>
            signature.data.formId === form._id &&
            signature.data.formStatus &&
            signature.data.formStatus === "Approved" &&
            (!gpidSpecificApproverView ||
              (gpidSpecificApproverView &&
                specificGpid !== undefined &&
                signature.data.gpid === filters.trainerForm))
        );
      }
    }
    if (
      form &&
      ((form.type === "resource" &&
        (form._id === Forms.Line.id || form._id === Forms.Report.id)) ||
        (userForms.readOnlyForms &&
          userForms.readOnlyForms.length !== 0 &&
          userForms.readOnlyForms.includes(form._id)))
    ) {
      isBlocked = true;
    }
    this.setFormMessage();
    return (
      <div>
        {status["showProgress"] && !isBlocked && (
          <div className={"sticky"}>
            {" "}
            <ProgressBar>
              <ProgressBar
                animated
                variant="success"
                now={status["complete-Percent"]}
                label={`${status["completed"]}`}
                key={1}
              />
              <ProgressBar
                animated
                variant="danger"
                now={status["empty-Percent"]}
                label={`${status["empty"]}`}
                key={2}
              />
            </ProgressBar>
          </div>
        )}
        <Errors errors={errors} />

        {formMessage.text && (
          <Message type={formMessage.type} text={formMessage.text} />
        )}
        <div className={"pep-edit"}>
          <div className={` ${isBlocked ? "read-only  cursor-disable" : ""}`}>
            <Form
              form={form}
              submission={formDataToDisplay}
              url={url}
              hideComponents={hideComponents}
              onChange={this.onChangeHandler}
              onSubmit={onSubmit}
              onComponentChange={this.onComponentChangeHandler}
              options={{
                ...{
                  templates: {
                    wizardHeader: {
                      form: " ",
                    },
                    wizardNav: {
                      form: " ",
                    },
                  },
                  template: "bootstrap",
                  iconset: "fa",
                  saveDraft: false,
                  saveDraftThrottle: 10000,
                  skipDraftRestore: true,
                  buttonSettings,
                  language: `${language}`,
                },
                ...options,
              }}
              ref={(instance) => {
                if (instance && !this.webform) {
                  instance.createPromise.then(() => {
                    this.webform = instance.formio;

                    if (this.webform._form.display === "wizard") {
                      this.webform.setPage(currentPage);
                    }
                    this.webform.restoreDraft(auth.user._id);
                  });
                }
              }}
            />
          </div>
          <BottomControlButtons
            secondaryButtonText={i18next.t(PageTexts.BACK)}
            primaryButtonText={i18next.t(primaryButtonText)}
            secondaryButtonPath={
              parseInt(currentPage) === 0
                ? routeService.getPagePath.submission(language, formId)
                : routeService.getPagePath.submissionPage(
                    language,
                    formId,
                    currentPage
                  )
            }
            primaryButtonPath={routeService.getPagePath.submission(
              language,
              formId
            )}
            isDeletion={
              form.type !== "resource" && enableDeleteButton && !isBlocked
            }
            primaryButtonAction={primaryButtonAction}
            isPrimaryButtonDisabled={
              (this.webform &&
                this.webform.component.properties &&
                this.webform.component.properties.display ===
                  "commonForShift") ||
              isBlocked ||
              status["complete-Percent"] !== 100
            }
            isDeletionButtonPath={routeService.getPagePath.deleteSubmission(
              language,
              formId,
              submission._id,
              currentPage
            )}
          />
          {form.properties && form.properties.information && (
            <ImportantInstructions text={form.properties.information} />
          )}
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    form: selectRoot("form", state),
    submission: selectRoot("submission", state),
    auth: selectRoot("auth", state),
    submissions: selectRoot("submissions", state),
    options: {
      noAlerts: false,
    },
    errors: [selectError("submission", state), selectError("form", state)],
    languageParams: selectRoot("languageParams", state),
    filters: selectRoot("filters", state),
    userForms: selectRoot("userForms", state),
    formInformations: selectRoot("formInformations", state),
    approverFlowCheck: selectRoot("approverFlowCheck", state),
    signatures: selectRoot("signatures", state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  const currentPage = routeService.getCurrentPageNumberFromLocation(location);
  const language = routeService.getLanguageFromRoute();
  let title = "";

  return {
    onSubmit: (submission, form, submissions, shiftlevelPage, webform) => {
      const userName = submission.metadata.userName;
      if (form) {
        title = form.title;
      }

      if (
        webform &&
        webform.form &&
        webform.components &&
        webform.component &&
        webform.component.properties &&
        webform.component.properties.codeCalculations
      ) {
        // eslint-disable-next-line no-param-reassign
        submission = getCalculatedKg(webform, submission);
      }
      submission.metadata = {
        viewPage: currentPage,
        recordType: "hourLevelEdited",
        isOnline: navigator.onLine,
        tabName: title !== undefined ? title : "",
        userName: userName,
      };
      if (
        ownProps.match.params.formId &&
        Forms.FoodSafety.id.includes(ownProps.match.params.formId)
      ) {
        submission.data.date =
          submission.data.findingDate !== "" &&
          submission.data.findingDate !== undefined
            ? submission.data.findingDate + "T00:00:00.000Z"
            : submission.data.date;
      }
      let reportSubmission = [];
      dispatch(
        saveSubmission(
          "submission",
          submission,
          ownProps.match.params.formId,
          (err, submission) => {
            if (submission !== undefined) {
              reportSubmission = {
                state: "submitted",
                data: {
                  date: submission.data.date,
                  shift: submission.data.c,
                  line: submission.data.line,
                  plant: submission.data.plant,
                  formId: ownProps.match.params.formId,
                  submissionObject: JSON.stringify(submission.data),
                  submissionMode: submission.metadata.recordType,
                  page: submission.metadata.viewPage,
                  isOnline: navigator.onLine.toString(),
                  submissionStatus: "Success",
                  errorMessage: "",
                  submissionId: submission._id ? submission._id : 0,
                  deviceBrowser: navigator.userAgent ? navigator.userAgent : "",
                  gpid: submission.data.gpid ? submission.data.gpid : 0,
                },
              };
            }

            if (err && submission !== undefined) {
              reportSubmission.data.errorMessage = JSON.stringify(err);
              reportSubmission.state = "Not submitted";
              reportSubmission.data.submissionStatus = "Failed";
              dispatch(
                saveSubmission("submission", reportSubmission, Forms.Report.id)
              );
            }

            if (!err) {
              dispatch(resetSubmissions("submission"));

              if (ownProps.match.params.formId === Resources.Language.id) {
                const langArr = mergeAndGetLanguageTranslations(
                  null,
                  submission
                );
                window.sessionStorage.setItem(
                  "langArray",
                  JSON.stringify(langArr)
                );
              }

              /**  // KPI Reporting requirements  Code starts*/
              let shiftSubmission;
              if (form && form.properties && form.properties.kpiReport) {
                const tabName =
                  shiftlevelPage && shiftlevelPage.title
                    ? shiftlevelPage.title
                    : "";

                shiftSubmission = reportCalculations(
                  form,
                  currentPage,
                  submissions,
                  submission,
                  "edit",
                  tabName
                );
              }
              if (shiftSubmission !== undefined) {
                dispatch(
                  saveSubmission(
                    "submission",
                    shiftSubmission,
                    ownProps.match.params.formId,
                    (err) => {
                      if (!err) {
                        dispatch(resetSubmissions("submission"));
                      }
                    }
                  )
                );
              }
              /*/ KPI Reporting requirements  Code ends */
              if (parseInt(currentPage) !== 0) {
                dispatch(
                  push(
                    routeService.getPagePath.submissionPage(
                      language,
                      ownProps.match.params.formId,
                      currentPage
                    )
                  )
                );
              } else {
                dispatch(
                  push(
                    routeService.getPagePath.submission(
                      language,
                      ownProps.match.params.formId
                    )
                  )
                );
              }
            }
          }
        )
      );
    },
  };
};
Edit.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  filters: PropTypes.object,
  match: PropTypes.object,
  otherformData: PropTypes.object,
  hideComponents: PropTypes.object,
  errors: PropTypes.any,
  options: PropTypes.object,
  languageParams: PropTypes.object,
  submission: PropTypes.object,
  submissions: PropTypes.object,
  onSubmit: PropTypes.func,
  approverFlowCheck: PropTypes.object.isRequired,
  form: PropTypes.object,
  userForms: PropTypes.object,
  formInformations: PropTypes.shape({
    rateConfigList: PropTypes.array,
  }),
  signatures: PropTypes.shape({
    submissions: PropTypes.array.isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
