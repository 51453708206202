export const appConfig = {
  SKIP_PREFLIGHT_CHECK: true,
  DISABLE_ESLINT_PLUGIN: true,

  REACT_APP_DOMAIN: "qa",
  REACT_APP_API_URL:
    "https://apim-na.dev.mypepsico.com/amesa/paperless/v1/server/pepsico",
  REACT_APP_DEVELOPMENT: "https://dev-pepsico.form.io",
  REACT_APP_DEMO:
    "https://pep-eip-amesa-dev-paperless.global.gw01.aks01.eus.nonprod.azure.intra.pepsico.com/formioapi/demo-pepsico",
  REACT_APP_PRODUCTION: "https://production-pepsico.form.io",
  REACT_APP_QA:
    "https://apim-na.dev.mypepsico.com/amesa/paperless/v1/server/pepsico",

  REACT_APP_OKTA_OAUTH2_ISSUER: "https://secure.ite.pepsico.com/oauth2/default",
  REACT_APP_OKTA_OAUTH2_CLIENT_ID: "0oa1zg1iaxfOU3sxn0h8",
};
