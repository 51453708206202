/* eslint array-callback-return: 0 */
/* eslint no-useless-computed-key: 0 */
import {
  totalOfSubmissions,
  countOfSubmissions,
} from "../FormLogic/totalHelper";
import { eachComponent } from "formiojs/utils/formUtils";
import { normalizeDataSetCategory } from "../../../utils";
/**Filtering out other shift  submissions for daylevel forms   starts */
export const getShiftLevelRecords = (form, submissions, currentShift) => {
  if (
    form &&
    form.properties &&
    form.properties.dispLevel &&
    form.properties.dispLevel === "shiftLevel"
  ) {
    // eslint-disable-next-line no-param-reassign
    submissions = submissions
      .filter((item) => item.data && item.data.shift === currentShift)
      .map((item) => {
        return item;
      });
  }
  return submissions;
};
/**Filtering out other shift  submissions for daylevel forms   ends */
/**Check Shiftlevel record sanity to avoid duplicates   starts */
export const reportCalculations = (
  form,
  currentPage,
  submissions,
  submission,
  mode,
  tabName
) => {
  // eslint-disable-next-line no-param-reassign
  submissions = getShiftLevelRecords(form, submissions, submission.data.shift);
  const results = submissions
    .filter(
      (item) =>
        item.metadata &&
        parseInt(item.metadata.viewPage) === parseInt(currentPage) &&
        (mode === "insert" ||
          ((mode === "edit" || mode === "delete") &&
            item._id !== submission._id))
    )
    .map((item) => {
      return item.data;
    });
  const shiftRecord = submissions
    .filter(
      (item) =>
        item.metadata &&
        parseInt(item.metadata.viewPage) === 1 &&
        item._id !== submission._id
    )
    .map((item) => {
      return item;
    });

  submission._id = "";
  submission.metadata.viewPage = "1";
  submission.metadata.isOnline = navigator.onLine;
  submission.metadata.recordType = "commonForShiftKPIAdded";
  submission.metadata.tabName = tabName;

  //if delete dont push this to results
  if (mode !== "delete") {
    results.push(submission.data);
  }
  if (shiftRecord.length !== 0) {
    submission._id = shiftRecord[0]._id;
    submission.data = shiftRecord[0].data;
    submission.metadata.recordType = "commonForShiftKPIUpdated";
  }
  if (shiftRecord.length === 0 && results.length === 0 && mode === "delete") {
    return;
  }
  if (results.length === 0 && mode === "delete") {
    submission.lastRecord = true;
    return submission;
  }
  // eslint-disable-next-line no-unused-vars
  const result = results.reduce((accum, item) => {
    eachComponent(form.components, (component) => {
      if (component.properties && component.properties.reportField) {
        const consolidatedValue =
          item[component.key] !== undefined &&
          item[component.key] !== "NaN" &&
          item[component.key] !== null &&
          item[component.key] !== ""
            ? Number.parseFloat(item[component.key])
            : 0;
        if (consolidatedValue !== 0) {
          if (accum.hasOwnProperty(component.properties.reportField)) {
            accum[component.properties.reportField] += consolidatedValue;
          } else {
            accum[component.properties.reportField] = consolidatedValue;
          }
          submission.data[component.properties.reportField] =
            accum[component.properties.reportField];
        }
      }
    });
    return accum;
  }, {});
  /**Code changed for PK-12 KPI report calculation  starts */
  if (form && form.properties && form.properties.kpiPercentCalculation) {
    if (
      submission.data["theoreticalSeasoningUseTotal"] > 0 &&
      submission.data["productionTotal"] > 0
    ) {
      submission.data["seasoningPercentage"] =
        (submission.data["theoreticalSeasoningUseTotal"] /
          submission.data["productionTotal"]) *
        100;
    }
  }
  if (form && form.properties && form.properties.kpiThroughPutCalculation) {
    if (
      submission.data.line.includes("TC") &&
      submission.data["seasoningPercentage"] > 0
    ) {
      if (submission.data && submission.data.plant === "7051") {
        submission.data["throughput"] =
          1665.62 / (1 - submission.data["seasoningPercentage"] / 100 - 0.048);
        submission.data["thruputConstant1"] = 1635;
        submission.data["thruputConstant2"] = 0.048;
      } else if (submission.data && submission.data.plant === "5320") {
        submission.data["throughput"] =
          1240 / (1 - submission.data["seasoningPercentage"] / 100 - 0.048);
      }
    }
    if (
      submission.data.line.includes("RBS") &&
      submission.data["seasoningPercentage"] > 0
    ) {
      submission.data["throughput"] =
        518.45 / (1 - submission.data["seasoningPercentage"] / 100 - 0.095);
      submission.data["thruputConstant1"] = 518.45;
      submission.data["thruputConstant2"] = 0.095;
    }
  }
  /**Code changed for PK-12 KPI report calculation  ends */
  return submission;
};

export const formPrePopulate = (
  form,
  submissions,
  isTotalLoaded,
  gpid,
  gpidSpecificView
) => {
  const accum = {};
  const preRecords = submissions
    .filter(
      (item) =>
        item.state !== "draft" &&
        (!gpidSpecificView || (gpidSpecificView && item.data.gpid === gpid))
    )
    .map((item) => {
      return item;
    });
  preRecords.sort(function (a, b) {
    return a.modified.localeCompare(b.modified);
  });
  const previousReading =
    preRecords.length > 0 ? preRecords[preRecords.length - 1] : null;
  !isTotalLoaded &&
    previousReading &&
    previousReading !== null &&
    eachComponent(form.components, (component) => {
      if (component.properties && component.properties.prepopulateField) {
        accum[component.key] = previousReading.data[component.key];
      }
    });
  return accum;
};
export const formLevelValidations = (
  form,
  submissions,
  currentPage,
  isTotalLoaded,
  otherformData,
  shiftToHourFetch,
  isFilter,
  submission,
  shiftSel
) => {
  let value;

  if (
    form.components &&
    form.components[currentPage] !== undefined &&
    submissions.length !== 0
  ) {
    // eslint-disable-next-line no-param-reassign
    submissions = getShiftLevelRecords(
      form.components[currentPage],
      submissions,
      shiftSel
    );
  }
  /* Populate data from shift level record to hour level records on load
      Component API key to be set as "displayFrom" and the value to be set as the 
      component api from which data is to be fetched from the shift tab */
  if (shiftToHourFetch && submissions && submissions.length !== 0) {
    const accum = {};
    submissions
      .filter(
        (item) =>
          item.metadata &&
          item.metadata.viewPage &&
          item.metadata.viewPage !== currentPage
      )
      .map((item) => {
        eachComponent(form.components, (component) => {
          if (
            component.properties &&
            component.properties.displayFrom &&
            component.defaultValue !==
              item.data[component.properties.displayFrom]
          ) {
            component.defaultValue =
              item.data[component.properties.displayFrom];
            accum[component.key] = item.data[component.properties.displayFrom];
          }
        });
      });
    return accum;
  }
  // Populate data from other forms in a newly created record
  // The FROM form component and TO form component should have property set as "formPick"
  // TO Form - Property value should be the API  key of the FROM form component eg:formPick-paestatus
  // FROM Form-Property value should be the API  key of the TO form component eg:formPick-pae
  if (otherformData !== null) {
    const accum = {};
    otherformData
      .filter((item) => item.data && item.data.shift)
      .map((item) => {
        eachComponent(form.components, (component) => {
          if (component.properties && component.properties.formPick) {
            component.defaultValue = item.data[component.properties.formPick];
            accum[component.key] = item.data[component.properties.formPick];
          }
        });
      });
    return accum;
  }

  if (
    form.display === "wizard" &&
    !isTotalLoaded &&
    submissions.length &&
    form.components[Number.parseFloat(currentPage)].properties
  ) {
    // Initialize total count for forms with total calculation

    if (
      form.components[Number.parseFloat(currentPage)].properties
        .countCalculation
    ) {
      value = countOfSubmissions(
        form.components[Number.parseFloat(currentPage)],
        submissions,
        submission
      );
    } else if (
      form.components[Number.parseFloat(currentPage)].properties.totalPage
    ) {
      value = totalOfSubmissions(
        submissions,
        currentPage,
        isFilter,
        submission
      );
    }
  }

  return value;
};

export const setShiftLevelRecord = (
  form,
  submissions,
  submission,
  currentPage
) => {
  if (
    form.form &&
    form.form.components[currentPage] &&
    form.form.components[currentPage].properties &&
    form.form.components[currentPage].properties.display &&
    form.form.components[currentPage].properties.display === "commonForShift"
  ) {
    submission.metadata.recordType = "commonForShiftNew";
    if (submissions.length !== 0) {
      // eslint-disable-next-line no-param-reassign
      submissions = getShiftLevelRecords(
        form.form.components[currentPage],
        submissions,
        submission.data.shift
      );
      if (submissions.length !== 0) {
        const shiftRecord = submissions
          .filter(
            (item) =>
              item.metadata &&
              parseInt(item.metadata.viewPage) === parseInt(currentPage)
          )
          .map((item) => {
            return item;
          });
        if (shiftRecord.length !== 0) {
          submission.metadata.recordType = "commonForShiftUpdated";
          if (submission._id !== shiftRecord[0]._id) {
            submission._id = shiftRecord[0]._id;
          }
        }
      }
    }
  }
  return submission;
};
/**Check Shiftlevel record sanity to avoid duplicates   ends */

export const getTotalInEditMode = (
  form,
  submission,
  submissions,
  currentPage
) => {
  const accum = {};
  const availableSubmission = submissions
    .filter(
      (item) =>
        item.metadata &&
        item.metadata.viewPage &&
        item.metadata.viewPage !== currentPage &&
        item.data.pC12FlavorType === submission.data.pC12FlavorType1 &&
        item.metadata &&
        parseInt(item.metadata.viewPage) !== 1
    )
    .map((item) => {
      return item.data;
    });

  eachComponent(form.components, (component) => {
    if (component.properties && component.properties.totalFor) {
      if (availableSubmission.length === 0) {
        accum[component.key] = 0;
      } else {
        let total = 0;
        const totalForProperties = component.properties.totalFor.split(",");

        if (totalForProperties.length > 0) {
          totalForProperties.filter((value) => {
            availableSubmission.filter((key) => {
              for (const propName in key) {
                if (propName === value) {
                  total += key[propName];
                }
              }
            });
          });
        }
        accum[component.key] = total;
      }
    }
  });
  return accum;
};

/**Color Rate configurations  starts */

export const getRate = (min1, min2, max1, max2, fieldApi, color) => {
  if (!isNaN(min1) && !isNaN(min2) && !isNaN(max1) && !isNaN(max2)) {
    if (
      color === "red" &&
      (fieldApi <= min1 ||
        fieldApi >= max1 ||
        fieldApi <= min2 ||
        fieldApi >= max2)
    ) {
      return true;
    }
    if (
      color !== "red" &&
      ((fieldApi >= min1 && fieldApi <= max1) ||
        (fieldApi >= min2 && fieldApi <= max2))
    ) {
      return true;
    }
  }
  if ((!isNaN(min1) || !isNaN(min2)) && isNaN(max1) && isNaN(max2)) {
    if (
      (!isNaN(min1) && fieldApi >= min1) ||
      (!isNaN(min2) && fieldApi >= min2)
    ) {
      return true;
    }
  }
  if ((!isNaN(max1) || !isNaN(max2)) && isNaN(min1) && isNaN(min2)) {
    if (
      (!isNaN(max1) && fieldApi <= max1) ||
      (!isNaN(max2) && fieldApi <= max2)
    ) {
      return true;
    }
  }
  if ((!isNaN(max1) || !isNaN(max2)) && (!isNaN(min1) || !isNaN(min2))) {
    if (
      color === "red" &&
      !isNaN(min1) &&
      !isNaN(max1) &&
      (fieldApi <= min1 || fieldApi >= max1)
    ) {
      return true;
    }
    if (
      color === "red" &&
      !isNaN(min2) &&
      !isNaN(max2) &&
      (fieldApi <= min2 || fieldApi >= max2)
    ) {
      return true;
    }
    if (
      color !== "red" &&
      !isNaN(min1) &&
      !isNaN(max1) &&
      fieldApi >= min1 &&
      fieldApi <= max1
    ) {
      return true;
    }
    if (
      color !== "red" &&
      !isNaN(min2) &&
      !isNaN(max2) &&
      fieldApi >= min2 &&
      fieldApi <= max2
    ) {
      return true;
    }
  }
  return false;
};

export const rateConfigComponent = (form, submission, rateConfigList) => {
  const color = {};
  if (
    form &&
    form.component.properties &&
    form.component.properties.rateCode &&
    rateConfigList.length !== 0
  ) {
    eachComponent(form.component.components, (component) => {
      if (component.properties && component.properties.colorRateConfig) {
        const rateList = rateConfigList
          .filter(
            (item) =>
              item.data &&
              item.data.fieldCode &&
              item.data.fieldCode === component.properties.colorRateConfig
          )
          .map((item) => {
            return item.data;
          });
        if (rateList.length !== 0) {
          let rateCard = {};
          if (rateList.length === 1) {
            rateCard = rateList[0];
          }
          if (rateList.length > 1) {
            rateList.forEach(function (item) {
              if (
                submission.data[item.dropdownFieldApi] &&
                submission.data[item.dropdownFieldApi] === item.dropdownValue
              ) {
                rateCard = item;
              }
            });
          }
          if (
            Object.keys(rateCard).length !== 0 &&
            submission.data[rateCard.fieldApi] !== "" &&
            submission.data[rateCard.fieldApi] !== undefined
          ) {
            const redMin1 =
              rateCard.redMin1 !== 0 && rateCard.redMin1 !== undefined
                ? rateCard.redMin1
                : "NA";
            const yellowMin1 =
              rateCard.yellowMin1 !== 0 && rateCard.yellowMin1 !== undefined
                ? rateCard.yellowMin1
                : "NA";
            const greenMin1 =
              rateCard.greenMin1 !== 0 && rateCard.greenMin1 !== undefined
                ? rateCard.greenMin1
                : "NA";
            const redMin2 =
              rateCard.redMin2 !== 0 && rateCard.redMin2 !== undefined
                ? rateCard.redMin2
                : "NA";
            const yellowMin2 =
              rateCard.yellowMin2 !== 0 && rateCard.yellowMin2 !== undefined
                ? rateCard.yellowMin2
                : "NA";
            const greenMin2 =
              rateCard.greenMin2 !== 0 && rateCard.greenMin2 !== undefined
                ? rateCard.greenMin2
                : "NA";
            const redMax1 =
              rateCard.redMax1 !== 0 && rateCard.redMax1 !== undefined
                ? rateCard.redMax1
                : "NA";
            const yellowMax1 =
              rateCard.yellowMax1 !== 0 && rateCard.yellowMax1 !== undefined
                ? rateCard.yellowMax1
                : "NA";
            const greenMax1 =
              rateCard.greenMax1 !== 0 && rateCard.greenMax1 !== undefined
                ? rateCard.greenMax1
                : "NA";
            const redMax2 =
              rateCard.redMax2 !== 0 && rateCard.redMax2 !== undefined
                ? rateCard.redMax2
                : "NA";
            const yellowMax2 =
              rateCard.yellowMax2 !== 0 && rateCard.yellowMax2 !== undefined
                ? rateCard.yellowMax2
                : "NA";
            const greenMax2 =
              rateCard.greenMax2 !== 0 && rateCard.greenMax2 !== undefined
                ? rateCard.greenMax2
                : "NA";

            const red = getRate(
              redMin1,
              redMin2,
              redMax1,
              redMax2,
              submission.data[rateCard.fieldApi],
              "red"
            );
            const yellow = getRate(
              yellowMin1,
              yellowMin2,
              yellowMax1,
              yellowMax2,
              submission.data[rateCard.fieldApi],
              "yellow"
            );
            const green = getRate(
              greenMin1,
              greenMin2,
              greenMax1,
              greenMax2,
              submission.data[rateCard.fieldApi],
              "green"
            );
            color[component.properties.colorRateTarget] = red
              ? "Red"
              : yellow
              ? "Yellow"
              : "Green";
          } else {
            color[component.properties.colorRateTarget] = "";
          }
        }
      }
    });
    return color;
  }
};

/**Color Rate configurations  ends */
/*Dynamic coloring of fileds- Code starts
    Page Level API Key 'colorCode=true'
    field Level - 'colorCodeSrc=true'*/

export const colorComponent = (form, submission) => {
  if (
    form &&
    form.component.properties &&
    form.component.properties.colorCode
  ) {
    eachComponent(form.component.components, (component) => {
      if (
        component.properties &&
        component.properties.colorCodeSrc === "true"
      ) {
        const comp = form.getComponent(component.key);
        if (comp && comp.element && comp.element.classList) {
          comp.element.classList.remove(
            "color-css-Red",
            "color-css-Yellow",
            "color-css-Green"
          );
          if (
            submission &&
            submission.data &&
            submission.data[component.key] &&
            submission.data[component.key] !== ""
          ) {
            comp.element.classList.add(
              "color-css-" + submission.data[component.key]
            );
          }
        }
      }
    });
  }
};

/*Dynamic coloring of fileds- Code ends*/

/*Shift level field display of tabs- Code starts
    Page Level API Key 'shiftBasedFieldDisplay=true'
    field Level - 'displayShift=Shift 1/Shift 2..'*/

export const showShiftBasedFields = (form, shift, user) => {
  const label8hrs = form.getComponent("shift-8");
  const label12hrs = form.getComponent("shift-12");
  if (label8hrs && label12hrs) {
    label8hrs.component.content =
      shift === "shift1"
        ? "<b>A</b>"
        : shift === "shift2"
        ? "<b>B</b>"
        : shift === "shift3"
        ? "<b>C</b>"
        : label8hrs.component.content;
    label12hrs.component.content =
      shift === "shift1"
        ? "<b>A12</b>"
        : shift === "shift2"
        ? "<b>B12</b>"
        : shift === "shift3"
        ? "<b>C12</b>"
        : label12hrs.component.content;
  }

  eachComponent(form.component.components, (component) => {
    const comp = form.getComponent(component.key);

    if (component.properties && component.properties.displayShift) {
      const displayShift = normalizeDataSetCategory(
        component.properties.displayShift
      );
      if (displayShift !== shift) {
        comp.component.hidden = true;
      }
    }
    if (
      component.properties &&
      component.properties.departmentName &&
      user.dropDownName &&
      user.dropDownName.includes(component.properties.departmentName)
    ) {
      comp._disabled = false;
      comp.component.disabled = false;
    }
  });
};

/*Shift level field display of tabs- Code ends*/
/**Form completion check  starts */

export const getFieldCount = (
  component,
  comp,
  submission,
  fieldCount,
  form
) => {
  if (component.type === "selectboxes") {
    const value = submission.data[component.key];

    let selectValue = "";
    for (var key in value) {
      if (value[key] === true) {
        selectValue += key;
        fieldCount.fldsCompleted[component.key] =
          submission.data[component.key];
      }
    }
    if (selectValue === "") {
      fieldCount.fldsEmpty[component.key] = submission.data[component.key];
      if (comp.element && comp.element.classList) {
        comp.element.classList.add("color-css-mandatory");
      }
    }
  } else if (
    (component.type !== "number" &&
      submission.data[component.key] !== undefined &&
      submission.data[component.key] !== "") ||
    (component.type === "number" &&
      submission.data[component.key] !== undefined &&
      submission.data[component.key] !== "" &&
      !isNaN(submission.data[component.key]))
  ) {
    if (comp && comp.error && comp.error.message && comp.error.message !== "") {
      fieldCount.fldsEmpty[component.key] = submission.data[component.key];
      if (comp.element && comp.element.classList) {
        comp.element.classList.add("color-css-mandatory");
      }

      eachComponent(form.components, (component1) => {
        if (
          component1.key === component.key &&
          !component1.customClass.includes("color-css-mandatory")
        ) {
          component1.customClass =
            component1.customClass + " color-css-mandatory";
        }
      });
    } else {
      fieldCount.fldsCompleted[component.key] = submission.data[component.key];
    }
  } else {
    fieldCount.fldsEmpty[component.key] = submission.data[component.key];
    if (comp.element && comp.element.classList) {
      comp.element.classList.add("color-css-mandatory");
    }

    eachComponent(form.components, (component1) => {
      if (
        component1.key === component.key &&
        !component1.customClass.includes("color-css-mandatory")
      ) {
        component1.customClass =
          component1.customClass + " color-css-mandatory";
      }
    });
  }
  return fieldCount;
};
export const formCompletionStatus = (form, submission, webform) => {
  const status = {};
  let fieldCount = {
    fldsCompleted: {},
    fldsEmpty: {},
  };
  status["showProgress"] = false;
  const requiredKeysString =
    form && form.properties && form.properties.requiredInSubmission
      ? form.properties.requiredInSubmission
      : "";
  const requiredValues =
    requiredKeysString && requiredKeysString !== "" && requiredKeysString.length
      ? requiredKeysString.split(",")
      : [];

  eachComponent(form.components, (component) => {
    const comp = webform.getComponent(component.key);
    if (comp && comp.element && comp.element.classList) {
      comp.element.classList.remove("color-css-mandatory");
    }
    if (
      comp &&
      comp.component &&
      comp.component.properties &&
      comp.component.properties.customMandatoryValue &&
      comp.component.properties.requiredInSubmission
    ) {
      if (
        submission.data[comp.component.key] ==
        comp.component.properties.customMandatoryValue
      ) {
        const requiredString =
          comp.component.properties.requiredInSubmission.split(",");
        requiredString.map((val) => requiredValues.push(val));
      }
    }
    if (
      requiredValues.includes(component.key) ||
      (component.validate && component.validate.required)
    ) {
      if (
        component.properties &&
        component.properties.conditionalLogic &&
        component.properties.conditionalValue
      ) {
        if (
          submission.data[component.properties.conditionalLogic] ===
          component.properties.conditionalValue
        ) {
          fieldCount = getFieldCount(
            component,
            comp,
            submission,
            fieldCount,
            form
          );
        } else if (component.properties.multipleConditions) {
          let conditions = [];
          conditions = component.properties.conditionalLogic
            ? component.properties.conditionalLogic.split(",")
            : [];
          for (let i = 0; i < conditions.length; i++) {
            if (conditions[i].indexOf(".") !== -1) {
              if (
                String(
                  submission.data[conditions[i].split(".")[0]][
                    conditions[i].split(".")[1]
                  ]
                ) === component.properties.conditionalValue
              ) {
                fieldCount = getFieldCount(
                  component,
                  comp,
                  submission,
                  fieldCount,
                  form
                );
              }
            }
          }
        } else if (component.properties.conditionalLogic.indexOf(".") !== -1) {
          if (
            String(
              submission.data[
                component.properties.conditionalLogic.split(".")[0]
              ][component.properties.conditionalLogic.split(".")[1]]
            ) === component.properties.conditionalValue
          ) {
            fieldCount = getFieldCount(
              component,
              comp,
              submission,
              fieldCount,
              form
            );
          }
        }
      } else {
        fieldCount = getFieldCount(
          component,
          comp,
          submission,
          fieldCount,
          form
        );
      }
    }
  });
  const total =
    Object.keys(fieldCount.fldsCompleted).length +
    Object.keys(fieldCount.fldsEmpty).length;
  if (total !== 0) {
    status["showProgress"] = true;
  }
  const completed = Object.keys(fieldCount.fldsCompleted).length + "/" + total;
  status["complete-Percent"] =
    total !== 0
      ? (Object.keys(fieldCount.fldsCompleted).length / total) * 100
      : 100;

  status["completed"] =
    status["complete-Percent"] === 100 ? "Form Complete" : completed;
  status["empty"] = Object.keys(fieldCount.fldsEmpty).length + "/" + total;
  status["empty-Percent"] =
    (Object.keys(fieldCount.fldsEmpty).length / total) * 100;

  return status;
};

/**Form completion check  ends */

export const getCalculatedKg = (webform, submission) => {
  eachComponent(webform.component.components, (component) => {
    if (component.properties && component.properties.calculateKg) {
      if (
        submission.data["actualCases"] &&
        submission.data["caseCountBags"] &&
        submission.data["financialWeight"]
      ) {
        submission.data[component.key] =
          (submission.data["caseCountBags"] *
            submission.data["financialWeight"] *
            submission.data["actualCases"]) /
          1000;
      } else {
        submission.data[component.key] = 0;
      }
    }
  });
  return submission;
};

export const isGpidSpecific = (form, approverFlowCheck) => {
  let gpidSpecificView = false;
  if (
    form &&
    form.properties &&
    form.properties.gpidSpecificForm &&
    approverFlowCheck &&
    !approverFlowCheck.approverView
  ) {
    gpidSpecificView = true;
  }
  return gpidSpecificView;
};
export const isGpidSpecificApprover = (form, approverFlowCheck) => {
  let gpidSpecificView = false;
  if (
    form &&
    form.properties &&
    form.properties.gpidSpecificForm &&
    approverFlowCheck &&
    approverFlowCheck.approverView
  ) {
    gpidSpecificView = true;
  }
  return gpidSpecificView;
};
