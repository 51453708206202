/* eslint no-useless-escape: 0 */
/* eslint no-useless-computed-key: 0 */
import moment from "moment-timezone";
import { Forms, AppConfig, OfflinePluginConfig } from "../config";
import FormioOfflineProject from "formio-plugin-offline";
import { i18next, translationConfig } from "../i18n";

const offlinePlugin = FormioOfflineProject.getInstance(
  AppConfig.projectUrl,
  AppConfig.projectUrl,
  OfflinePluginConfig
);

export const createDateRange = (date) => {
  const dateFrom = new Date(date);
  const range = {};
  const dateFromObject = {
    year: dateFrom.getFullYear(),
    month: dateFrom.getMonth(),
    day: dateFrom.getDate(),
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  };
  range.from = moment
    .tz(dateFromObject, "Etc/UTC")
    .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

  const dateToObject = {
    ...dateFromObject,
    hour: 23,
    minute: 59,
    second: 59,
  };
  range.to = moment
    .tz(dateToObject, "Etc/UTC")
    .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

  return range;
};

export const getFormName = ({
  formReference = "",
  formNameDescription = "",
}) => {
  return `${formReference.toLowerCase()}${formNameDescription
    .toLowerCase()
    .replace(/\ /g, "")}`;
};

export const normalizeDataSetCategory = (name = "") => {
  return `${(name[0] || "").toLowerCase()}${name.replace(/\ /g, "").substr(1)}`;
};

export const getQueryObject = (filters, additionalFields) => {
  const range = createDateRange(filters.date);

  return {
    ["data.date__gte"]: range.from,
    ["data.date__lte"]: range.to,
    ["data.line"]: filters.line,
    ["data.plant"]: filters.plant,
    ["data.shift"]: filters.shift,
    ...additionalFields,
  };
};

/**Check if a signature exists in offline queue-If so diable the add/edit/delete/end submit features {LN} starts*/
export const signatureOffline = (formId, filters, gpid) => {
  return offlinePlugin.submissionQueue.some((offlineSubmission) => {
    return (
      offlineSubmission.request.data &&
      offlineSubmission.request.data.data.formId === formId &&
      (offlineSubmission.request.form === Forms.Signature.id ||
        offlineSubmission.request.data.data.userSignature ||
        offlineSubmission.request.data.data.userSignature === "") &&
      offlineSubmission.request.data.data.line === filters.line &&
      parseInt(offlineSubmission.request.data.data.plant) === filters.plant &&
      offlineSubmission.request.data.data.shift === filters.shift &&
      offlineSubmission.request.data.data.date.includes(
        filters.date.split("T")[0]
      ) &&
      (gpid === undefined || offlineSubmission.request.data.data.gpid === gpid)
    );
  });
};

/**Check if a signature exists in offline queue-If so diable the add/edit/delete/end submit features {LN} ends*/

/**Default Query generator based on the form pages /resources  code starts*/
export const getSubmissionDefaultQuery = (form, filters, userForms, formId) => {
  // eslint-disable-next-line no-unused-vars

  const range = createDateRange(filters.date);
  const query = getQueryObject(filters, { limit: 999999 });
  let defaultQuery = [];
  if (form && form.type === "resource") {
    defaultQuery = {
      limit: 999999,
    };
  } else if (Forms.PreviousFetch.id.includes(formId)) {
    defaultQuery = {
      ["data.date__gte"]: range.from,
      ["data.date__lte"]: range.to,
      ["data.line"]: filters.line,
      ["data.shift"]: "general",
      ["data.plant"]: filters.plant,
      limit: 999999,
    };
  } else if (Forms.FoodSafety.id.includes(formId)) {
    let toDate = filters.toDateFilter.split("T")[0];
    toDate += "T23:59:59";
    let frmDate = filters.frmDateFilter.split("T")[0];
    frmDate += "T00:00:00";
    defaultQuery = {
      ["data.date__gte"]: frmDate,
      ["data.date__lte"]: toDate,
      ["data.line"]: filters.line,
      ["data.shift"]: filters.shift,
      ["data.plant"]: filters.plant,
      limit: 999999,
    };
  } else if (Forms.CSV.id === formId) {
    let toDate = filters.toDate.split("T")[0];
    toDate += "T23:59:59";
    let frmDate = filters.fromDate.split("T")[0];
    frmDate += "T00:00:00";
    defaultQuery = {
      ["data.date__gte"]: frmDate,
      ["data.date__lte"]: toDate,
      ["data.plant"]: filters.plant,
      ["data.shift__in"]: filters.formShift,
      limit: 999999,
    };
  } else if (
    Forms.Signature.id === formId &&
    filters.approvalStatus !== "" &&
    filters.formShift !== "" &&
    userForms !== null &&
    userForms.approverForms !== null &&
    userForms.approverFormNames !== null
  ) {
    const approverForms = userForms.approverForms;
    let formName = [];
    userForms.approverFormNames.forEach(function (item) {
      formName.push(item.split("@@@")[1]);
    });
    if (filters.formName !== "All" && filters.formName !== "") {
      formName = filters.formName;
    }

    let toDate = filters.toDate.split("T")[0];
    toDate += "T23:59:59";
    let frmDate = filters.fromDate.split("T")[0];
    frmDate += "T00:00:00";
    defaultQuery = {
      ["data.date__gte"]: frmDate,
      ["data.date__lte"]: toDate,
      ["data.formId__in"]: approverForms,
      ["data.plant"]: filters.plant,
      ["data.formStatus__in"]: filters.approvalStatus,
      ["data.shift__in"]: filters.formShift,
      ["data.line__in"]: filters.apprLine,
      ["data.formName__in"]: formName,
      limit: 999999,
    };
  } else if (
    form &&
    form.properties &&
    form.properties.formType &&
    form.properties.formType === "dayLevel"
  ) {
    defaultQuery = {
      ["data.date__gte"]: range.from,
      ["data.date__lte"]: range.to,
      ["data.line"]: filters.line,
      ["data.plant"]: filters.plant,
      limit: 999999,
    };
  } else {
    defaultQuery = query;
  }

  return defaultQuery;
};
/**Default Query generator based on the form pages /resources  code ends*/
/**Fetch translations fromlanguage recource and populate session  code starts*/

export const mergeAndGetLanguageTranslations = (translations, submission) => {
  const langObj = {};
  const languages = ["ar", "cn", "en"];

  if (submission && submission.data) {
    langObj = JSON.parse(window.sessionStorage.getItem("langArray"));
    languages.forEach((lang) => {
      langObj[lang][submission.data.description] = submission.data[lang]
        ? submission.data[lang]
        : submission.data.description;
    });
  } else {
    languages.forEach((lang) => {
      langObj[lang] = {};
      translations.forEach(function (elem) {
        langObj[lang][elem.data.description] = elem.data[lang]
          ? elem.data[lang]
          : elem.data.description;
      });

      const langConfig = translationConfig.formsConfig[lang];
      langObj[lang] = Object.assign({}, langConfig, langObj[lang]);
    });
  }

  languages.forEach((lang) => {
    i18next.addResourceBundle(lang, "translation", langObj[lang], true);
  });

  return langObj;
};

/**Fetch translations fromlanguage recource and populate session  code ends*/
