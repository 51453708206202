/* eslint no-undef: 0 */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "connected-react-router";
import { selectRoot, logout, resetForm, Formio } from "react-formio";
import NavLink from "../NavLink";
import { i18next } from "../../i18n";
import { AuthConfig } from "../../config";
import routeService from "../../services/routeService";
import LanguageProvider from "../LanguageProvider";
import { useOktaAuth } from "@okta/okta-react";

export function Header({ hideActions }) {
  const dispatch = useDispatch();

  const { oktaAuth } = useOktaAuth();

  const auth = useSelector((state) => selectRoot("auth", state));
  const { language } = useSelector((state) =>
    selectRoot("languageParams", state)
  );

  const performLogout = (language, formio) => {
    window.sessionStorage.clear();
    dispatch(logout());
    // dispatch(push(`/${language}${AuthConfig.anonState}`));
    dispatch(resetForm("headerFilterForm"));
    formio.setToken(null);
    formio.setUser(null);

    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + "/logout",
    });
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <Link
          className="navbar-brand"
          to={routeService.getPagePath.formsList(language)}
        >
          <img
            className="logo bg-white"
            alt="PepsiCo"
            src="/pepsico_logo_1.png"
            height="45px"
          />
        </Link>

        {auth.authenticated ? <LanguageProvider /> : null}
        <ul className="nav navbar-nav ml-auto">
          {auth.authenticated ? (
            <li className="nav-item profile">
              <span className="profile_name">
                {i18next.t(auth.user.data.name)}
                <span className="profile_icon fa fa-user-circle-o" />
              </span>
              <span
                className="nav-link"
                role="navigation link"
                onClick={() => performLogout(language, Formio)}
              >
                <span className="fa fa-sign-out" />
                &nbsp;
                {i18next.t("Logout")}
              </span>
            </li>
          ) : hideActions ? null : (
            <NavLink
              to={routeService.getPagePath.auth(language)}
              role="navigation link"
              className="nav-link"
            >
              {i18next.t("Login")}
            </NavLink>
          )}
        </ul>
      </div>
    </nav>
  );
}
