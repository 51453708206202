export const formConfig =  {
FoodSafety: {
        demo: '60097298bea81341a4edaba2',
        dev: '60127643452a837b9b10cb88',
        production: '601bf2d7b31d6bdd50af2cb4',
        qa: '601bf24363d3de372d367004'
},
GpidSpecificForms:{

    demo: '6086dac9e954c7f295036977',
    dev: '',
    production: '609a08f932614002026df29a',
    qa: '609398236264706d2dd426ab'
},
    PreviousFetch: {
        demo: '5f55cd0a5fb94703cecd739d,5f52044b2e014017f59fa347,5f11866c9f54a27302a88092,60d2f9a30eb119f6160e5f34,60d567d4458e65a3534d86f4,60d28384009e140e8ed74e0f,617978f98c75157af9f5f0d3,6181eac451c0586b7d0bec96,6180b532aca7b92adc89ffe9,618918fb9f928076f32344ca,6189194e25bc81004c75e06c,6189198d2c9ef8589124985c,61891c90609b225764a009ba,635f50821039dfcdcefa3704,6360a461472634f580acf02f,6360a4c7d18c77621f6f7806,6362918f466034a0537a7cfa,636a03c10af2dd1ad04a88ca,636c9a8ffc2cb9ba5db18fbb,63722ee285113c79ca334a69,636ca1fff18315600637241b,63732667c9eb994caccafe5a',
        dev: '5f3cbe4e5c370c5b0d8b97d2,5f6c30cf820943e2efce40cb,5f6c30cf82094352f0ce40cf',
        production: '5f1fe9a39d4c6262788653b5,5fa1762ba02e662d362bc95a,5fa1762ba02e66e1312bc95b,60dd2763b2632b689274afdd,60dd2763b2632b56eb74afdf,60dd2763b2632bb7e874afde,6182231e3d628e3edbd212e6,6182231e3d628ee490d212df,6182231e3d628e1926d212ed,618922779f9280368c23f6a3,618922779f92802e5b23f695,618922779f92807b5023f69c,6385a7962970e4f8c12079ad,6385a7962970e45f2f2079a6,6385a7962970e462e820799f,6385a7962970e4ceb6207998,6385a7962970e4dfbc207991,6385a7962970e4795120798a,66386b337b1391c6ad7e1be3,66386b347b1391c6ad7e1beb,66386b357b1391c6ad7e1bf3,66386c684ed7da02fd9d4eb5,66386c6b4ed7da02fd9d4ebd,66386d32ff2c5789706556e9,66386d36ff2c578970655722,66386d39ff2c578970655736,66386d31ff2c5789706556de,66386b367b1391c6ad7e1bfd,66386c6c4ed7da02fd9d4ec6,66386c6e4ed7da02fd9d4ece,6639eda4fed443628d4c4e68',
        qa: '5f1bae2deddc37275fb5bb9d,5f637140e79669b703c6cef5,5f637140e79669e55ac6cef4,60d554dc2aef0b3d11c751fc,60d95dab91ec876cbcbbf11a,60dd25b9b2632b885474afa9,61822289f7153a7786974805,61822289f7153ae42b9747fe,61822289f7153a95fb97480c,61892204a7b7da2e38ad6c53,61892204a7b7daa0d7ad6c45,61892204a7b7da4f6ead6c4c,637329e7c9eb9961f7cb248b,637329e7c9eb9927e8cb2484,637329e7c9eb9958b2cb247d,637329e7c9eb99dfadcb2476,637329e7c9eb99cdaccb246c,637329e7c9eb9943a9cb245b,65c08b3fb97bcf614e37b3cc,65c0ba98b97bcf614e37cbdb,65d6f247169a9924985ef5df,65cc66440fa0678efff176e3,65dd981f84a12dd4c75cebb3,65dc27f684a12dd4c75ca2e8,65d5ed3d169a9924985ea4c6,65d70410169a9924985f0157,65e5c92384a12dd4c75f3a6b,65e693f2169a992498630bb9,65e1aee7169a9924986223db,65e56855169a99249862c8ef,66025da24463514e5598a631'
},

HrForms: {
    demo: '609b67ad94aa3015b5100dcd',
    dev: '',
    production: '60af31d3de32b44fb392e6fa',
    qa: '60af2f76df4c196a9fd82c7d'
},
        Cleanliness: {
            demo: '5e7cf6dbfd93e618bcb5f497',
            dev: '5e7cf6d0c816ffac6cd476b1',
            production: '5f1fe9a49d4c621cee8653d9',
            qa: '5e621ae2824355d01f569f23'
},

      Signature: {
        demo: '5e7cf6dbfd93e6593cb5f493',
        dev: '5e7cf6d0c816ff5951d476cf',
        production: '5f1fe9a39d4c6295ed8653af',
        qa: '5e5f8d35b13c5fa8a25ea2e7'
    },
    CSV: {
        demo: '5fc496a597527ce5cf4c3b5f',
        dev: '5fcf1f8c6388970d6966c536',
        production: '5fcf1e6823940fb8b667ec14',
        qa: '5fcf1c7eb96bf47a4d651fa2'
    },
    
     Report: {
            demo: '5fed930f3d0aa4ed5c07e7e4',
            dev: '5fed9da03d0aa4068207ed25',
            production: '5feda641658c8373f343a8aa',
            qa: '5feda3ec8da2c12e50c76d56'
        },
        Language: {
            demo: '5fef12b9140bb38422e78c3b',
            dev: '5ff313b8dd440713e77c3cc2',
            production: '5ff31461e0f3a947d68d1f2e',
            qa: '5ff3134c80e4c8c24aa91bae'
        },
     HeaderFilter: {
            demo: '5e7cf6dbfd93e61830b5f48e',
            dev: '5e7cf6d0c816ff0903d476ca',
            production: '5f1fe9a49d4c62ecd88653d5',
            qa: '5e5522c7c1338c1b7390f023'
        },
     FryerControl: {
            demo: '5e7cf6dbfd93e65222b5f492',
            dev: '5e7cf6d0c816ff37b5d476ce',
            production: '5f1fe9a39d4c6207518653b2',
            qa: '5e5ec8621e8a76b3a279d745'
        },
    ConsumptionReport: {
            demo: '5e9be9226511cee3e60be1ba',
            dev: '5ed0e50dd214e029e12803ab',
            production: '5f1fe9a49d4c62769d8653c9',
            qa: '5ec3cfbcfb87e4244b295e91'
        },
     Followuponachievingplan: {
            demo: '5eb0f7e92855d921c90bef19',
            dev: '5e7cf6d0c816ff7277d476d8',
            production: '5f1fe9a59d4c62c4b48653de',
            qa: '5ec3cfbcfb87e4e2d4295e94'
        },
    PAE: {
            demo: '5e7cf6dbfd93e6084fb5f494',
            dev: '5e7cf6d0c816ff80fbd476d0',
            production: '5f1fe9a49d4c6211f68653d7',
            qa: '5e5ff5c4f26ac83ca1c063b7'
        },
    CS59: {
            demo: '5e8c87b3c74b3c7989de79bc',
            dev: '5ed0e50dd214e0b21b2803af',
            production: '5f1fe9a49d4c62ab9a8653db',
            qa: '5e8c8e2c268b518812ea3442'
        },
    CS59Ind: {
            demo: '5e8c87b3c74b3c7989de79bc',
            dev: '5ed0e50dd214e0b21b2803af',
            production: '5f1fe9a49d4c62ab9a8653db',
            qa: '65d2ec8984a12dd4c758d4fd'
        },
    PC7: {
            demo: '5edcc226ad8812eaeaeb71e4',
            dev: '5edf3a81fcedb14b135f8c90',
            production: '5f1fe9a39d4c6278538653b6',
            qa: '5ee6ff023294d61f584cf18b'
        },
        PC12: {
            demo: '5e882fb0e551be3aa974140d',
            dev:'5ed0e50dd214e0d2862803ad',
            production:'5f1fe9a59d4c626bea8653df',
            qa:'5ec3cfbcfb87e45e33295e8f'
        },
    PC12Ind: {
            demo: '5e882fb0e551be3aa974140d',
            dev:'5ed0e50dd214e0d2862803ad',
            production:'5f1fe9a59d4c626bea8653df',
            qa:'65d300f984a12dd4c758ed9a'
        },
   Gpid: {
            demo: '5e7cf6dbfd93e676d7b5f482',
            dev: '5e7cf6d0c816ffc3add476be',
            production: '5f1fe9a39d4c62179c8653aa',
            qa: '5e70dc5b9520fb0908d480c8'
        },
  UserAccess: {
            demo: '5e7cf6dbfd93e62fe8b5f487',
            dev: '5e7cf6d0c816ff3854d476c3',
            production: '5f1fe9a39d4c623a4f8653a4',
            qa: '5e70e5966bf4f6806c5b6c1a'
        },
  Roles: {
            demo: '5e7cf6dbfd93e684ffb5f483',
            dev: '5e7cf6d0c816ff8681d476bf',
            production: '5f1fe9a39d4c6253068653a6',
            qa: '5e70dca19520fbb680d480dd'
        },
        Shift: {
            demo: '5fb63b64e140c8ed8265f8b6',
            dev: '5fd9aae677bead74a7f5b50a',
            production: '5fd9aba077beade89bf5b53a',
            qa: '5fd9aa8964ac885443349e5f'
        },
  Forms: {
            demo: '5e7cf6dbfd93e6983ab5f484',
            dev: '5e7cf6d0c816ffdb9bd476c0',
            production: '5f1fe9a39d4c62960a8653a5',
            qa: '5e70e45f392a751df3cfc2f3'
        },
Line: {
    demo: '5e7cf6dbfd93e60bb4b5f485',
    dev: '5e7cf6d0c816ffe872d476c1',
    production: '5f1fe9a39d4c621b708653a8',
    qa: '5e70e489d666f1c900a9be62'
},
 DataSet: {
            demo: '5e7cf6dbfd93e6bcbfb5f486',
            dev: '5f6c399d31bdc9c94d6b6b10',
            production: '5f1fe9a39d4c62d62e8653a7',
            qa: '5e70e4b6392a75b5c1cfc30d'
        },
 RateConfig:{
            demo: '60129dd03630900018a4158e',
            dev: '6013ffab2deb88add30ef52a',
            production: '60250a33244c79235c3c4304',
            qa: '6025095772115c44910d699c'
        },
  SKU: {
            demo: '5f8d3028542b33b840ef2ab9',
            dev: '5f99542861cde1007229ac11',
            production: '5f995304b248b9cc1bc559da',
            qa: '5f995278c376d865b108c607'
        },
 Flavor: {
            demo: '5f996d683dbbe15841308dcb',
            dev: '5f9b9f95df11c00920f166c5',
            production: '5f9b9e733f958672bf8529ed',
            qa: '5f9b9d63ef5f7d57e24e63ed'
        },
 SWTransactions: {
            demo: '5fb79eb67db6a01a51b9ff77',
            dev: '5fbf82ae9919dd23b3d7345d',
            production: '5fbf83c451bd4323f27be66e',
            qa: '5fbf828e51bd43751c7be2dd'
        },
        SWItemDetails: {
            demo: '5fb7a910e1ac27e2e4a4985a',
            dev: '5fbf82ae9919dd511ad7345e',
            production: '5fbf83c551bd43a7077be670',
            qa: '5fbf828e51bd437f957be2de'
        },

    Authenticated:{
            demo: '5e7cf6dbfd93e63558b5f477',
            dev: '5e7cf6d0c816fff951d476b3',
            production: '5f1fe9a39d4c6286488653a9',
            qa: '5e53addf1fd699b6e1dd631c'
        },
        Logout:{
            demo: 'https://secure.ite.mypepsico.com/associatesso/logout',
            dev: 'https://sso.ite.mypepsico.com/login/logout.jsp',
            production: 'https://secure.mypepsico.com/associatesso/logout',
            qa: 'https://sso.ite.mypepsico.com/login/logout.jsp'
        },

        AuditStandard:{
            demo: '60533eccb85134d002d10df3',
            qa: '6059e71d6c354774b956f03f',
            production:'6059eb457cb4150f7b2c5a4c',
            dev:'6059ec03345c431116d3edec'
       },
        PK29:{
            demo: '5ea8504a0aca293b85d89579',
            qa: '5ec3cfbcfb87e4b25b295e93',
            production: '5f1fe9a59d4c62f94d8653dd',
            dev:'5ed0e50dd214e020932803b1'
        },
        PK33:{
            demo: '5ea7cdc91eae9d6c9cb9aba6',
            qa: '5ec3cfbcfb87e4375a295e92',
            production: '5f1fe9a59d4c62e9c98653dc',
            dev:'5ed0e50dd214e051892803ac'
        },
        TrainingDetails:{
            demo: '60891cf5e4b667d6e5fa85f1',
            qa: '608aa3c4fa8cfb560e220937',
            production: '608aa6754750e50e1efde8e0'
        },
        GpidDetails:{
            demo:'6088f7a366dfb164d039fb6f',
            qa: '608aa4ab7b7526f27c3b528b',
            production: '608aa5f47b75264c303b5334'
        },
		InternalAuditReport:{
            demo: '600ebc326c552b249cb495c2',
            qa: '602b346bd45c910a9d890221',
            production: '602bbd0ad45c91f6a789abdb',
            dev:'60127643452a83383a10cb8a'
    },
    CornDetails: {
        demo: '61f6be797e598bd47bc78778',
        qa: '6203a29f33bddd3be44ccab8',
        production: '6203b886c907176d7d809eb0'
    }
} ;
