/* eslint no-use-before-define: 0 */
import * as actionTypes from '../Actions/ActionTypes';
import {
  filtersState,
  languageState,
  userFormsState,
  formInformationsState,
  offlineQueueState,
  previousReadingState,
  approverState,
 } from './InitialState';

export const filtersReducer = (state = filtersState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTERS_VALUE:
      return setFiltersValue(state, action);
    default:
      return state;
  }
};

export const languageReducer = (state = languageState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return setLanguage(state, action);
    default:
      return state;
  }
};
export const formInformationsReducer = (state = formInformationsState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_FORM_INFORMATIONS:
      return setFormInformationsLoading(state, action);
    case actionTypes.SET_FORM_INFORMATIONS:
      return setFormInformations(state, action);
    case actionTypes.FORM_INFORMATION_ERROR:
      return setFormInformationsError(state, action);
    default:
      return state;
  }
};

export const userFormsReducer = (state = userFormsState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_USER_FORMS:
      return setUserFormsLoading(state, action);
    case actionTypes.SET_USER_FORMS:
      return setUserForms(state, action);
    case actionTypes.USER_FORMS_ERROR:
      return setUserFormsError(state, action);
    default:
      return state;
  }
};

export const setPreviousReadingReducer = (state = previousReadingState, action) => {
  switch (action.type) {
    case actionTypes.PREVIOUS_READING_START:
      return setPreviousReadingStart(state, action);
    default:
      return state;
  }
};

export const offlineQueueReducer = (state = offlineQueueState, action) => {
  switch (action.type) {
    case actionTypes.SUBMISSIONS_DEQUEUING_START:
      return setOfflineQueueStarted(state, action);
    case actionTypes.SUBMISSIONS_DEQUEUING_FINISH:
      return setOfflineQueueFinished(state, action);
    default:
      return state;
  }
};

export const approverReducer = (state = approverState, action) => {
  switch (action.type) {
    case actionTypes.IS_APPROVER_FLOW:
      return approverFlowStarted(state, action);
    case actionTypes.IS_USER_FLOW:
      return approverFlowEnded(state, action);
    default:
      return state;
  }
};

const setFiltersValue = (state, action) => {
  return {
    ...state,
    ...action.filters,
  };
};

const setLanguage = (state, action) => {
  return {
    ...state,
    language: action.language,
    isLanguageChanged:
      typeof action.isLanguageChanged === 'boolean'
        ? action.isLanguageChanged
        : state.isLanguageChanged,
  };
};

const setUserFormsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.isLoading,
    error: userFormsState.error,
  };
};
const setFormInformationsLoading = (state, action) => {
  return {
    ...state,
    isLoading: action.isLoading,
    error: formInformationsState.error,
  };
};
const setFormInformations = (state, action) => {
  return {
    ...state,
    previousReading: action.previousReading || formInformationsState.previousReading,
    isLoading: formInformationsState.isLoading,
    rateConfigList: action.rateConfigList || formInformationsState.rateConfigList,
    flavorList: action.flavorList || formInformationsState.flavorList,
    skuList: action.skuList || formInformationsState.skuList,
    sWTransactionsList: action.sWTransactionsList || formInformationsState.sWTransactionsList,
    sWItemDetailsList: action.sWItemDetailsList || formInformationsState.sWItemDetailsList,
    dataSetList: action.dataSetList || formInformationsState.dataSetList,
    standardTitleList: action.standardTitleList || formInformationsState.standardTitleList,
    trainingDetailList: action.trainingDetailList || formInformationsState.trainingDetailList,
    gpidDetailList: action.gpidDetailList || formInformationsState.gpidDetailList,
    corntypeList: action.corntypeList || formInformationsState.corntypeList,
    error: formInformationsState.error,
  };
};
const setFormInformationsError = (state, action) => {
  return {
    ...state,
    skuList: formInformationsState.skuList,
    sWItemDetailsList: formInformationsState.sWItemDetailsList,
    sWTransactionsList: formInformationsState.sWTransactionsList,
    flavorList: formInformationsState.flavorList, 
    error: action.error,
    dataSetList: formInformationsState.dataSetList,
    previousReading: formInformationsState.previousReading,    
    rateConfigList:formInformationsState.rateConfigList,
    standardTitleList:formInformationsState.standardTitleList,
    trainingDetailList:formInformationsState.trainingDetailList,
    gpidDetailList: formInformationsState.gpidDetailList,
    corntypeList: formInformationsState.corntypeList,
  };
};

const setUserForms = (state, action) => {
  return {
    ...state,
    forms: action.forms || userFormsState.forms,
    categories: action.categories || userFormsState.categories,
    approverForms: action.approverForms || userFormsState.approverForms,
    readOnlyForms: action.readOnlyForms || userFormsState.readOnlyForms,
    approverFormNames: action.approverFormNames || userFormsState.approverFormNames,
    
    departmentApprover: action.departmentApprover || userFormsState.departmentApprover,
    superApprover: action.superApprover || userFormsState.superApprover,
    isLoading: userFormsState.isLoading,
    error: userFormsState.error,
  };
};

const setUserFormsError = (state, action) => {
  return {
    ...state,
    forms: userFormsState.forms,
    error: action.error,
    categories: userFormsState.categories,
    approverForms: userFormsState.approverForms,
    approverFormNames: userFormsState.approverFormNames,
    
    departmentApprover: userFormsState.departmentApprover ,
    superApprover: userFormsState.superApprover ,
    readOnlyForms:userFormsState.readOnlyForms,
  };
};
const setPreviousReadingStart = (state, action) => {
  return {
    ...state,
    previousReading: action.previousReading || previousReadingState.previousReading,
    error: previousReadingState.error,
  };
};

const setOfflineQueueFinished = (state, action) => {
  return {
    ...state,
    dequeuing: false,
  };
};

const setOfflineQueueStarted = (state, action) => {
  return {
    ...state,
    dequeuing: true,
  };
};

const approverFlowStarted = (state, action) => {
  return {
    ...state,
    approverView: true,
  };
};

const approverFlowEnded = (state, action) => {
  return {
    ...state,
    approverView: false,
  };
};
